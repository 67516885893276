import { Formik } from 'formik'
import { useEffect, useState } from 'react'
import Button from 'react-bootstrap/Button'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import * as Yup from 'yup'

import AxiosClass from '../../Api/Interceptor'
import PageApi from '../../Api/PageApi'
import { butterflyBlack, logoNoText } from '../../commonservice'
import { RouteConstants } from '../../Constants/routeConstants'
import { useAppSelector } from '../../Hooks'
import { updateUserDetail } from '../../Redux/slices/settingsSlice'
import { loading, login } from '../../Redux/slices/userSlice'
import { pageURLCheckValdation } from '../../Redux/thunks/pageDetailThunk'
import { updateUserInfo } from '../../Redux/thunks/settingsThunk'
import Loader from '../Loader/Loader'
import ProgBar from './ProgressBar'

const StepOne = (props: any) => {
  const userState = useAppSelector((state: any) => state?.user)
  const settingsState = useAppSelector((state: any) => state?.settings)

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [count, setCount] = useState(0)
  const d = new Date()
  const year = d.getFullYear()
  const schema = Yup.object().shape({
    first_name: Yup.string()
      .required('first name is required')
      .min(3, 'First name should be atleast 3 characters')
      .matches(/^[a-zA-Z'"]/, 'First letter must be alphabet'),
    last_name: Yup.string().matches(/^[a-zA-Z'"]/, 'First letter must be alphabet'),
    reason: Yup.string().required().max(250),
    unique_url: Yup.string(),
  })

  const capitalize = (s: string) => {
    return s[0]?.toUpperCase() + s?.slice(1)
  }

  const FirstStepSubmit: any = (data: any) => {
    let uniqueUrl = data.first_name + data.last_name
    uniqueUrl = uniqueUrl
      ?.toString()
      ?.toLowerCase()
      ?.replace(/[^a-zA-Z]/g, '')

    dispatch(loading(true))
    pageURLCheckValdation(uniqueUrl)
      .then((r: any) => {
        if (r.message === 'Already Exist') {
          const fnm = data.first_name
            ?.toString()
            ?.toLowerCase()
            ?.replace(/[^a-zA-Z]/g, '')
          const lnm = data.last_name
            ?.toString()
            ?.toLowerCase()
            ?.replace(/[^a-zA-Z]/g, '')
          const payload: any = { first_name: fnm, last_name: lnm }
          updatedURLSet(payload, data)
        } else {
          dispatch(loading(false))
          data.unique_url = uniqueUrl
          data.first_name = data?.first_name !== '' ? capitalize(data?.first_name) : ''
          data.last_name = data?.last_name !== '' ? capitalize(data?.last_name) : ''
          props.handleForm(data)
          props.propsData[0]()
        }
      })
      .catch((e: any) => {
        dispatch(loading(false))
      })
  }

  const updatedURLSet = (payload: any, data: any) => {
    new PageApi(AxiosClass)
      .createNewUniqueURL(payload)
      .then((r: any) => {
        if (r.message === 'Available URL') {
          data.unique_url = r.data?.toString()?.toLowerCase()
          data.first_name = data?.first_name !== '' ? capitalize(data?.first_name) : ''
          data.last_name = data?.last_name !== '' ? capitalize(data?.last_name) : ''
          props.handleForm(data)
          props.propsData[0]()
        }
        dispatch(loading(false))
      })
      .catch((e: any) => {
        dispatch(loading(false))
      })
  }

  const updateIsInitiated = () => {
    if (userState?.value?.data?.is_initiated === false) {
      const payload: any = { _id: userState?.value?.data._id, is_initiated: true }
      dispatch(updateUserInfo(payload))
    } else {
      navigate(RouteConstants.MYPAGES)
    }
  }

  useEffect(() => {
    if (settingsState?.updateUserDetailsResp?.success === true) {
      dispatch(loading(false))
      dispatch(login({ ...userState?.value, data: { ...userState?.value?.data, is_initiated: true } }))
      navigate(RouteConstants.MYPAGES)
      dispatch(updateUserDetail({ ...settingsState?.updateUserDetailsResp, success: null }))
    }
  }, [settingsState?.updateUserDetailsResp?.success])

  useEffect(() => {
    setCount(props?.propsData[3].reason?.length)
  }, [props?.propsData[3].reason])

  return (
    <>
      <div className="imgHolder d-none d-md-block">
        <div className="contentWrapper stepOneBg">
          <div className="sideBar">
            <div className="logo">
              <img
                onClick={() => {
                  navigate('/mypages')
                }}
                src={logoNoText}
                alt="Butterfly Logo"
              />
            </div>
            {window.location.pathname !== '/create' ? (
              <ul className="Back">
                <li
                  onClick={() => {
                    window.history.back()
                  }}
                >
                  <i className="fa fa-arrow-left"></i>
                </li>
              </ul>
            ) : (
              ''
            )}
          </div>
          <div className="overlay"></div>
          <div className="title">Every page is different. Every page matters. Tell us about yours.</div>
        </div>
      </div>
      <div className="formHolder">
        {props.propsData[4] && <Loader />}
        <div className="d-xs-block d-md-none m-logo">
          <img src={butterflyBlack} alt="Butterfly logo" />
          <div className="title">Create an Obituary in Minutes</div>
        </div>
        <ProgBar step={props.propsData[2]} />
        <div className="stepWrapper">
          <div className="steps stepOne">
            <h2>This is where it begins.</h2>
            <h3>Tell us about the person you&apos;re creating this page for</h3>
            <Formik
              validateOnMount={true}
              validateOnChange={true}
              validationSchema={schema}
              onSubmit={FirstStepSubmit}
              initialValues={{
                first_name: props.propsData[3].first_name,
                last_name: props.propsData[3].last_name,
                reason: props.propsData[3].reason,
                unique_url: props.propsData[3].unique_url,
              }}
            >
              {({ handleSubmit, handleChange, handleBlur, values, touched, isValid, errors }) => (
                <Form noValidate onSubmit={handleSubmit}>
                  <Row className="mt-4">
                    <Form.Group as={Col} lg={6} className="mb-4 mb-lg-0" controlId="formGridFname">
                      <Form.Label>
                        Their First Name<sup>*</sup>
                      </Form.Label>
                      <Form.Control
                        name="first_name"
                        value={values.first_name}
                        onChange={handleChange}
                        type="text"
                        required
                      />
                    </Form.Group>

                    <Form.Group as={Col} lg={6} controlId="formGrid">
                      <Form.Label>Their Last Name</Form.Label>
                      <Form.Control
                        name="last_name"
                        value={values.last_name}
                        onChange={handleChange}
                        type="text"
                        required
                      />
                    </Form.Group>
                  </Row>
                  <Row className=" mt-4 mb-5">
                    <Form.Group>
                      <Form.Label>
                        What’s the story behind your page?<sup>*</sup>
                      </Form.Label>
                      <Form.Control
                        as="textarea"
                        placeholder="Welcome your family and friends – and anybody else – by sharing what this page is about, and why it matters to you.  Everyone you let in will see this message."
                        name="reason"
                        required
                        id="page_reason"
                        value={values.reason}
                        onChange={(e: any) => {
                          setCount(e.target.value.replace(/(\r\n|\n|\r)/g, '').length)
                          handleChange(e)
                        }}
                        maxLength={250}
                        onInput={(e: any) => {
                          if (e?.target?.value?.length > 250) {
                            const pageReason: any = document.getElementById('page_reason')
                            pageReason.value = e?.target?.value.slice(0, 250)
                          }
                        }}
                      />
                      <span className="textcount">{count}/250</span>
                    </Form.Group>
                  </Row>

                  <div className="justify-content-between d-flex">
                    <Button type="button" onClick={updateIsInitiated} className="prev">
                      I&apos;ll create one later
                    </Button>
                    <Button type="submit" disabled={!isValid}>
                      Next
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
        {/* <div className="text-center d-block">
					<p className="CopyRight w-100 text-center mt-5">© 2023 Butterfly.co</p>
				</div> */}
        <div className="position-absolute">
          <p className="CopyRight text-center position-fixed">© {year} Butterfly.co</p>
        </div>
      </div>
    </>
  )
}

export default StepOne
