import './Auth.scss'

import { useEffect, useState } from 'react'
import { Tab } from 'react-bootstrap'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Tabs from 'react-bootstrap/Tabs'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import { useDispatch } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'

import { environment } from '../../AppConfig/environment'
import { butterflyBlack, butterflyWhite } from '../../commonservice'
import { RouteConstants } from '../../Constants/routeConstants'
import { useAppSelector } from '../../Hooks'
import { setAppError } from '../../Redux/slices/metaSlice'
import { logout, setAuthAlert as setAlert } from '../../Redux/slices/userSlice'
import Loader from '../Loader/Loader'
import AlertPopup from '../Modals/AlertPopup/AlertPopup'
import SignIn from './SignIn'
import SignUp from './SignUp'
import VerifyEmail from './VerifyEmail'

export const Auth = (props: any) => {
  const navigate = useNavigate()
  const location = useLocation()
  const appState = useAppSelector(state => state.metadata)
  const dispatch = useDispatch()
  const d = new Date()
  const year = d.getFullYear()
  const userState: any = useAppSelector(state => state.user)
  const [showVerifyEmail, setshowVerifyEmail] = useState(false)
  const showVerifymail = () => {
    setshowVerifyEmail(!showVerifyEmail)
  }
  const [pathName, setPathName] = useState('/signin')

  const handleTab = (eventKey: any, event: any) => {
    dispatch(
      setAlert({
        status: false,
        content: '',
      })
    )
    if (eventKey === 'signin') {
      navigate(RouteConstants.LOGIN)
    } else if (eventKey === 'register') {
      navigate(RouteConstants.REGISTER)
    }
  }

  useEffect(() => {
    document.title = `Butterfly | A Full Life Deserves a Full Tribute`
    setPathName(location.pathname)
    if (location.pathname === RouteConstants.REGISTER && localStorage.getItem('user_id') === null) {
      if (
        localStorage.getItem('ForInviteUser') === null &&
        localStorage.getItem('backToPublicPage') === null &&
        localStorage.getItem('notificationEventIdRedirect') === null
      ) {
        dispatch(logout())
      }
      setshowVerifyEmail(false)
    } else {
      loggedInUserRedirection()
    }
  }, [location])

  useEffect(() => {
    if (userState.verification) {
      showVerifymail()
      navigate(RouteConstants.VERIFYEMAIL)
    } else if (location.pathname === RouteConstants.VERIFYEMAIL) {
      setshowVerifyEmail(false)
      navigate(RouteConstants.LOGIN)
    }
  }, [userState?.verification])

  useEffect(() => {
    const UserStateId: any = localStorage.getItem('user_id')
    if (UserStateId !== null && UserStateId !== undefined) {
      loggedInUserRedirection()
    }
  }, [userState?.value])

  const loggedInUserRedirection = () => {
    if (userState?.value?.data) {
      if (localStorage.getItem('ForInviteUser') !== null) {
        const pageurl: any = localStorage?.getItem('ForInviteUser')
        navigate(RouteConstants.PAGEDETAIL?.replace(':pageslug', pageurl))
      } else if (localStorage.getItem('actionUrl') !== null) {
        let pData: any = localStorage.getItem('actionUrl')
        pData = JSON.parse(pData)
        navigate(RouteConstants.PAGEDETAIL?.replace(':pageslug', pData?.page + '?from=' + pData?.path))
      } else if (localStorage.getItem('backToPublicPage') !== null) {
        const backURL: any = localStorage.getItem('backToPublicPage')
        navigate(backURL)
      } else if (localStorage.getItem('notificationEventIdRedirect') !== null) {
        const backURL: any = localStorage.getItem('notificationEventIdRedirect')
        navigate(backURL)
      } else {
        if (userState?.value?.data?.is_initiated) {
          navigate(RouteConstants.MYPAGES)
        } else {
          navigate(RouteConstants.CREATEFIRSTPAGE)
        }
      }
    }
  }

  return (
    <>
      {appState.error?.status && (
        <AlertPopup
          buttonText={false}
          show={!!appState.error.status}
          content={appState.error.content}
          state={appState.error.state}
          onHide={() => {
            dispatch(setAppError(null))
          }}
        />
      )}

      <GoogleReCaptchaProvider
        reCaptchaKey={environment.GoogleReCaptcha}
        scriptProps={{
          async: false, // optional, default to false,
          defer: false, // optional, default to false
          appendTo: 'body', // optional, default to "head", can be "head" or "body",
          nonce: undefined, // optional, default undefined
        }}
      >
        <section id="AuthPage">
          <Container>
            <Row>
              <Col md={7} className="d-none d-md-block">
                <div className="imgHolder">
                  <div className="overlay"></div>
                  <div className="logo">
                    <img
                      src={butterflyWhite}
                      alt="Butterfly logo"
                      onClick={() => {
                        navigate('/')
                      }}
                    />
                  </div>
                  <h1 className="title">
                    Create an Obituary in Minutes<span></span>
                  </h1>
                </div>
              </Col>
              <Col md={5} className="lhs">
                {userState.loading && <Loader />}
                <div className="d-xs-block d-md-none m-logo">
                  <img
                    src={butterflyBlack}
                    alt="Butterfly logo"
                    onClick={() => {
                      navigate('/')
                    }}
                  />
                  <div className="title">Create an Obituary in Minutes</div>
                </div>
                {showVerifyEmail ? (
                  <VerifyEmail />
                ) : (
                  <div className="formHolder">
                    <Tabs
                      activeKey={pathName === '/signin' ? 'signin' : 'register'}
                      id="uncontrolled-tab-example"
                      onSelect={handleTab}
                    >
                      {/* Sign In Section */}
                      <Tab eventKey="signin" title="Sign In" className="signIn">
                        <SignIn />
                      </Tab>
                      {/* Register Section */}
                      <Tab eventKey="register" title="Register" className="register">
                        <SignUp
                          handleVerify={() => {
                            showVerifymail()
                          }}
                        />
                      </Tab>
                    </Tabs>
                    <div
                      id="recaptcha"
                      style={{
                        position: 'absolute',
                        bottom: '0',
                        left: '0',
                      }}
                    ></div>
                  </div>
                )}
                <div className="footer">
                  <p>© {year} Butterfly.co</p>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </GoogleReCaptchaProvider>
    </>
  )
}
