import './Header.scss'

import cn from 'classnames'
import { useEffect, useState } from 'react'
import { isMobile } from 'react-device-detect'
import { useDispatch } from 'react-redux'
import * as Realm from 'realm-web'

import { environment } from '../../AppConfig/environment'
import { butterflyBlack, logoNoText } from '../../commonservice'
import { RouteConstants } from '../../Constants/routeConstants'
import { useAppSelector } from '../../Hooks'
import { setPopup, setRealmUser, setSidePanel } from '../../Redux/slices/metaSlice'
import { getNotificationCountResponse } from '../../Redux/slices/NotificationSlice'
import { sendAudioMsgResponse, setActiveSideSlug } from '../../Redux/slices/pageDetailSlice'
import { getNotificationCount } from '../../Redux/thunks/NotificationThunk'
import AlertPopup from '../Modals/AlertPopup/AlertPopup'
import { NavComponent } from '../Nav'

const RealApp = new Realm.App({ id: `${environment.RealmAppId}` })

function Header() {
  const dispatch = useDispatch()

  const pageDetailState: any = useAppSelector((state: any) => state?.pagedetail)
  const audioMessageCount: any = useAppSelector((state: any) => state?.audiomessages?.totalCount)
  const userState: any = useAppSelector(state => state?.user)

  const isDetailPage: any = Object.values(RouteConstants).indexOf(window.location.pathname as RouteConstants)

  const notificationState = useAppSelector((state: any) => state?.notification)

  const [notificationCount, setNotoficationCount] = useState<any>('')

  const [, setUser] = useState<any>()

  useEffect(() => {
    dispatch(setActiveSideSlug(''))
    dispatch(setPopup(null))
    dispatch(
      setSidePanel({
        panelBody: null,
        status: false,
      })
    )
  }, [])
  const [isActive, setIsActive] = useState(false)

  const handleClick = () => {
    const activeItem: any = document.getElementById(`Layout`)
    if (!isActive) {
      if (activeItem) {
        if (activeItem.classList.contains('blocked-layout')) {
          activeItem.classList.remove('blocked-layout')
        } else {
          activeItem.classList.add('blocked-layout')
        }
      }
    } else {
      if (activeItem) {
        if (activeItem.classList.contains('blocked-layout')) {
          activeItem.classList.remove('blocked-layout')
        } else {
          activeItem.classList.add('blocked-layout')
        }
      }
    }
    setIsActive(current => !current)
  }

  const canAction = () => {
    if (
      pageDetailState?.data?.belongsTo?._id === userState?.value?.data?._id ||
      pageDetailState?.data?.subscribtion_status === 'APPROVED'
    ) {
      return true
    } else {
      return false
    }
  }

  const [screenSize, getDimension] = useState({
    dynamicHeight: window.innerHeight,
    dynamicOHeight: window.outerHeight,
  })
  const setDimension = () => {
    getDimension({
      ...screenSize,
      dynamicHeight: window.innerHeight,
    })
  }
  useEffect(() => {
    window.addEventListener('resize', setDimension)

    return () => {
      window.removeEventListener('resize', setDimension)
    }
  }, [screenSize])

  useEffect(() => {
    const streamlogin = async () => {
      console.log('userState?.value?.data?._id', userState?.value?.data?._id)
      // Authenticate anonymously
      const credentials = Realm.Credentials.emailPassword('shivanshu.l@position2.com', 'Admin@123')

      const user = await RealApp.logIn(credentials)
      setUser(user)
      dispatch(setRealmUser(user))

      const mongodb = RealApp.currentUser?.mongoClient('mongodb-atlas')
      if (!mongodb) return
      const collection = mongodb.db(`${environment.mongodbStream}`).collection('notificationbadges')

      for await (const change of collection.watch({
        belongsTo: userState?.value?.data?._id,
      })) {
        console.log('BADGE STREAM ', change)
        // @ts-expect-error fullDocument doesn't exist
        console.log('BELONGS TO BEFORE', change.fullDocument.belongsTo.toString())
        console.log('USER STATE ', userState?.value?.data?._id)
        if (
          change.operationType === 'update' &&
          change.fullDocument.belongsTo.toString() === userState?.value?.data?._id
        ) {
          console.log('BELONGS TO AFTER', change.fullDocument.belongsTo.toString())

          setNotoficationCount(change.fullDocument.count)
          dispatch(
            getNotificationCountResponse({
              ...notificationState?.getNotificationCountResp,
              success: null,
            })
          )
        }
      }
    }

    streamlogin()
  }, [userState?.value?.data?._id])

  useEffect(() => {
    // clearInterval(intervalData)
    // interval = setInterval(() => {
    const UserStateId: any = localStorage.getItem('user_id')
    if (UserStateId !== null && UserStateId !== undefined) {
      dispatch(getNotificationCount(userState?.value?.data?._id))
      // setIntervalData(interval)
    }
    // }, 35000)
  }, [userState?.value?.data?._id])

  useEffect(() => {
    if (notificationState?.getNotificationCountResp?.success === true) {
      setNotoficationCount(notificationState?.getNotificationCountResp?.data?.count)
      dispatch(
        getNotificationCountResponse({
          ...notificationState?.getNotificationCountResp,
          success: null,
        })
      )
    }
  }, [notificationState?.getNotificationCountResp])

  return (
    <div className="MobileNav">
      <header
        className={cn('', {
          'have-personal-message': pageDetailState?.data?.belongsTo?._id === userState?.value?.data?._id,
        })}
      >
        <div className="logo">
          <img src={logoNoText} alt="" />
        </div>
        <div className={isDetailPage === -1 && canAction() ? 'Cta' : 'Cta EndAlign'}>
          <span
            className={isDetailPage === -1 && canAction() ? 'Icon' : 'Icon d-none'}
            onClick={() => {
              setTimeout(() => {
                dispatch(setActiveSideSlug('message-board'))
                dispatch(
                  setSidePanel({
                    panelBody: `Message Board`,
                    status: true,
                  })
                )
              }, 500)
            }}
          >
            <i className="fa fa-menumessageboard"></i>
          </span>
          {pageDetailState?.data?.belongsTo?._id === userState?.value?.data?._id && (
            <span
              className={isDetailPage === -1 && canAction() ? 'Icon' : 'Icon d-none'}
              onClick={() => {
                setTimeout(() => {
                  dispatch(setActiveSideSlug('personal-message'))
                  dispatch(
                    setSidePanel({
                      panelBody: `Private Messages`,
                      status: true,
                    })
                  )
                }, 500)
              }}
            >
              <i className="fa fa-phone-message"></i>
            </span>
          )}
          <span
            className={isDetailPage === -1 && canAction() ? 'Icon' : 'Icon d-none'}
            onClick={() => {
              setTimeout(() => {
                dispatch(setActiveSideSlug('audio-message'))
                dispatch(
                  setSidePanel({
                    panelBody:
                      pageDetailState?.data?.belongsTo?._id === userState?.value?.data?._id
                        ? `Audio Messages <span>(` + audioMessageCount + `)</span>`
                        : `Audio Message`,
                    status: true,
                  })
                )
              }, 500)
            }}
          >
            <i className="fa fa-menuaudiomessages"></i>
          </span>
          <div className={isActive ? 'Active button' : 'button'} onClick={handleClick}>
            {isMobile && notificationCount !== '' && notificationCount > 0 ? (
              <span className="NotificationDot"></span>
            ) : (
              ''
            )}

            <span className="icon-bar"></span>
            <span className="icon-bar"></span>
            <span className="icon-bar"></span>
          </div>
        </div>
      </header>
      <div id="nav-container">
        <div className={isActive ? 'Active bg' : 'bg'}></div>

        <div
          id="nav-content"
          // style={ScrollSection}
          className={isActive ? (isDetailPage === -1 ? 'is-detail-page Active' : 'is-not-detail-page Active') : ''}
        >
          <div className="logoText">
            <span>
              <img src={butterflyBlack} alt="" />
            </span>
            <span onClick={handleClick}>
              <i className="fa fa-approvalrequired"></i>
            </span>
          </div>
          <div className="NavWrapper">
            <NavComponent handleClose={handleClick} />
          </div>
        </div>
      </div>
      {pageDetailState?.sendAudioMessageResp?.success === true ? (
        <AlertPopup
          buttonText={false}
          show={!!pageDetailState?.sendAudioMessageResp?.success}
          content={pageDetailState?.sendAudioMessageResp?.message}
          state={!pageDetailState?.sendAudioMessageResp?.success ? 'ERROR' : 'SUCCESS'}
          onHide={() => {
            dispatch(
              sendAudioMsgResponse({
                ...pageDetailState?.sendAudioMessageResp,
                success: null,
              })
            )
          }}
        />
      ) : (
        ''
      )}
    </div>
  )
}

export default Header
