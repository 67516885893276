import * as jwt from 'jose'
import { useEffect, useState } from 'react'
// import { Helmet } from 'react-helmet-async'
import { useDispatch } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'

import { trackEvent } from '../Analytics/GA'
import { environment } from '../AppConfig/environment'
import { parseURLParams } from '../commonservice'
import Loader from '../Components/Loader/Loader'
import AlertPopup from '../Components/Modals/AlertPopup/AlertPopup'
import PageNotFound from '../Components/PageNotFound'
import { RouteConstants } from '../Constants/routeConstants'
import ErrorBoundary from '../ErrorBoundary'
import { useAppSelector } from '../Hooks'
import { setAppError } from '../Redux/slices/metaSlice'
import { addFundraiserAction, loadPageDetail, type pageDetail } from '../Redux/slices/pageDetailSlice'
import { loadPostList } from '../Redux/slices/post'
import { loginThunk } from '../Redux/thunks'
import { pageDetailThunk } from '../Redux/thunks/pageDetailThunk'
import Layout from './LayoutHOC'
import { LeftSection } from './LayoutSections/LeftSection'
import { CenterSection } from './LayoutSections/MidSection'
import { RightSection } from './LayoutSections/RightSection'

export const LayoutWrapper = (props: any) => {
  const appState = useAppSelector(state => state.metadata)
  const dispatch = useDispatch()
  const location = useLocation()
  const navigate = useNavigate()
  const pageDetailState: pageDetail = useAppSelector((state: any) => state.pagedetail)
  const [pageLoader, setPageLoader] = useState(true)
  const [show404, setShow404] = useState(false)
  const [loginLoading, setLoggingIn] = useState(false)
  // const [urlEnding, setUrlEnding] = useState('')
  // const [pageSchema, setPageSchema] = useState({})
  const userState: any = useAppSelector(state => state.user)

  useEffect(() => {
    dispatch(loadPageDetail([]))
    dispatch(loadPostList([]))
    dispatch(addFundraiserAction([]))
    const href: any = window.location.pathname
    const urlSegments: any = href.match(/([^/]*)\/*$/)
    const urlParams: any = parseURLParams(window.location.href)
    if (urlSegments.length) {
      const pageUrl = urlSegments[1]
      let count = 0
      const routeList: any = RouteConstants
      for (const key in routeList) {
        if (routeList[key] === '/' + pageUrl) {
          // console.log("urlSegments", pageUrl);
          // console.log("routeList[key]", routeList[key]);
          count = 1
          document.title = `Butterfly | A Full Life Deserves a Full Tribute`
        }
      }
      if ((count === 0 && !urlParams?.token) || (count === 0 && urlParams?.token && loginLoading)) {
        dispatch(pageDetailThunk(pageUrl, userState?.value?.data?._id))
      } else if (!urlParams?.token) {
        setShow404(false)
        setPageLoader(false)
      }
    }
  }, [location])

  /*
    get url params for user login info and call login 
    */
  useEffect(() => {
    const urlParams: any = parseURLParams(window.location.href)
    if (urlParams?.token && !loginLoading) {
      loginFromToken(urlParams?.token[0])
    }
  }, [pageDetailState?.data])

  async function loginFromToken(token: string) {
    const secret = new TextEncoder().encode(
      // this key needs to be in Env
      environment.TextDecoder
    )
    const decodedToken = await jwt.jwtVerify(token, secret)
    const payload = decodedToken.payload
    if (payload?.email && payload?.password) {
      const loginPayload = {
        email: payload.email,
        password: payload.password,
        recaptcha_token: undefined,
        provider: 'manual',
      }
      trackEvent('Conversion Event', 'Legacy Page Accessed', `${payload?.email as string}`)
      localStorage.setItem('backToLegacyPage', `/${payload?.page_url as string}`)
      dispatch(loginThunk(loginPayload, navigate))
      setLoggingIn(true)
    }
  }

  useEffect(() => {
    if (pageDetailState?.data?.status === 200) {
      const urlParams: any = parseURLParams(window.location.href)
      if (
        !userState?.value?.data?._id &&
        pageDetailState?.data?.privacy_status !== 'PUBLIC' &&
        !urlParams?.adminId?.length
      ) {
        navigate(RouteConstants.REGISTER)
      } else {
        const href: any = window.location.pathname
        const urlSegments: any = href.match(/([^/]*)\/*$/)
        if (urlSegments.length) {
          const pageUrl = urlSegments[1]
          let count = 0
          const routeList: any = RouteConstants
          for (const key in routeList) {
            if (routeList[key] === '/' + pageUrl) {
              count = 1
            }
          }
          if (count === 0) {
            document.title = `${pageDetailState?.data?.first_name} ${pageDetailState?.data?.last_name} - Memorial and Obituary | Butterfly`
            // if (urlEnding === '') {
            //   setUrlEnding(pageDetailState?.data?.unique_url)
            //     setPageSchema({
            //       '@context': 'https://schema.org',
            //       '@type': 'Person',
            //       '@id': `${environment.frontEndPoint}${pageDetailState?.data?.unique_url}`,
            //       name: `${pageDetailState?.data?.first_name} ${pageDetailState?.data?.last_name}`,
            //       description: `The digital memorial and obituary for ${pageDetailState?.data?.first_name} ${pageDetailState?.data?.last_name} on Butterfly.`,
            //     })
            // }
          } else {
            document.title = `Butterfly | A Full Life Deserves a Full Tribute`
          }
        }
        setPageLoader(false)
      }
    } else if (pageDetailState?.data?.status === 404 || (pageDetailState?.data?.status === 400 && !loginLoading)) {
      setPageLoader(false)
      setShow404(true)
    }
  }, [pageDetailState?.data])

  // status

  return (
    <>
      {/* {urlEnding !== '' ? (
        <Helmet>
          <link rel="canonical" href={environment.frontEndPoint + urlEnding} />
          // { // <script type="application/ld+json">{JSON.stringify(pageSchema)}</script> }
        </Helmet>
      ) : (
        ''
      )} */}
      {appState.error?.status && (
        <AlertPopup
          buttonText={false}
          show={!!appState.error.status}
          content={appState.error.content}
          onHide={() => {
            dispatch(setAppError(null))
          }}
        />
      )}
      <ErrorBoundary type="page">
        {pageLoader ? (
          <Layout>
            <Loader />
          </Layout>
        ) : show404 ? (
          <Layout>
            <PageNotFound />
          </Layout>
        ) : (
          <Layout>
            {props.view?.leftView ? <LeftSection component={props.view} /> : <></>}
            {props.view?.midView ? <CenterSection component={props.view} /> : <></>}
            {props.view?.rightView ? <RightSection component={props.view} /> : <></>}
            {props.view?.allView ? <props.view.allView /> : ''}
          </Layout>
        )}
      </ErrorBoundary>
    </>
  )
}
