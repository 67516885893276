import 'intro.js/introjs.css'

import { Steps } from 'intro.js-react'
import $ from 'jquery'
import { useEffect, useState } from 'react'
import { isMobile } from 'react-device-detect'
import { useDispatch } from 'react-redux'

import { trackEvent } from '../../Analytics/GA'
import { useAppSelector } from '../../Hooks'
import { setPopup, setSidePanel } from '../../Redux/slices/metaSlice'
import {
  addPageAction,
  type pageDetail,
  type pageDetailData,
  setActiveSideSlug,
} from '../../Redux/slices/pageDetailSlice'
import { setShowStepStatus } from '../../Redux/slices/userSlice'
import { pinUnpinPost } from '../../Redux/thunks/postThunk'
import { updateUserInfo } from '../../Redux/thunks/settingsThunk'
import CreatePost from '../Modals/CreatePost/CreatePost'
import {
  UserGuidecreatePost,
  UserGuideEditPost,
  UserGuideEvents,
  UserGuidePinPost,
  UserGuidePublishPost,
  UserGuideQuestion,
  UserGuideSharePost,
  UserGuideSubscribe,
  UserGuideSubscribePending,
} from './UserGuide'

const UserGuideStepper = (props: any) => {
  const dispatch = useDispatch()
  const pageDetailState: pageDetail = useAppSelector((state: any) => state.pagedetail)
  const pageData: pageDetailData = pageDetailState.data
  const userState = useAppSelector(state => state.user)
  const pageAction = pageDetailState?.pageAction
  const postData = useAppSelector((state: any) => state?.post)

  const [stepsStatus, setStepStatus] = useState(false)
  const [showQuestionStep, setShowQuestionStep] = useState(true)
  const [showSubsciption, setShowSubsciption] = useState(false)
  const [showLegacySteps, setShowLegacySteps] = useState(false)

  const [currentStep, setCurrentStep] = useState(0)
  const [stepDone, setStepDone] = useState(0)
  const [legacystepDone, setLegacystepDone] = useState(0)
  const [currentLegacySteps, setCurrentLegacySteps] = useState(0)

  // edit post
  const [show, setShow] = useState(false)
  const [editPost, setEditPost] = useState<any>({})

  const steps = [
    {
      element: '.CreatePostBtn',
      title: 'Create a post',
      intro: <UserGuidecreatePost />,
      tooltipClass: 'UserGuideCreatePost myTooltipClass',
    },
    {
      element: '.DesktopQuestion',
      title: 'It all starts with Questions',
      intro: <UserGuideQuestion />,
      tooltipClass: 'UserGuideQuestion myTooltipClass',
      hidePrev: true,
      nextLabel: 'Got it!',
      position: 'top',
    },
    {
      element: '.fa-butterflypin',
      title: 'Is your post ready for pinning?',
      intro: <UserGuidePinPost />,
      tooltipClass: 'UserGuidePinPost myTooltipClass',
      nextLabel: 'Got it!',
    },
    {
      element: '.EventsModule',
      title: 'Your Events',
      intro: <UserGuideEvents />,
      tooltipClass: 'UserGuideEvents myTooltipClass',
      nextLabel: 'Got it!',
      position: 'bottom',
      hidePrev: true,
    },

    {
      element: '.publishButton',
      title: 'Publish your page',
      intro: <UserGuidePublishPost />,
      tooltipClass: 'UserGuidePublishPage myTooltipClass',
      nextLabel: 'Got it!',
    },
    {
      element: '.shareButton',
      intro: <UserGuideSharePost />,
      tooltipClass: 'UserGuideSharePage myTooltipClass',
      showButtons: false,
    },
  ]

  const legacySteps = [
    {
      element: '.quick-menu-dots',
      title: 'Edit your post',
      intro: <UserGuideEditPost />,
      tooltipClass: 'UserGuideEditPost myTooltipClass legacyUserGuide',
      nextLabel: 'Edit Post',
      position: 'bottom',
      hidePrev: true,
    },
    {
      element: '.fa-butterflypin',
      title: 'Pin your post',
      intro: <UserGuidePinPost />,
      tooltipClass: 'UserGuidePinPost myTooltipClass legacyUserGuide',
      nextLabel: 'Pin Post',
      position: 'bottom',
      hidePrev: true,
    },
  ]

  const legacyStepsTabSmallSize = [
    {
      element: '.quick-menu-dots',
      title: 'Edit your post',
      intro: <UserGuideEditPost />,
      tooltipClass: 'UserGuideEditPost myTooltipClass legacyUserGuide tabTooltipClass',
      nextLabel: 'Edit Post',
      position: 'bottom',
      hidePrev: true,
    },
    {
      element: '.fa-butterflypin',
      title: 'Pin your post',
      intro: <UserGuidePinPost />,
      tooltipClass: 'UserGuidePinPost myTooltipClass legacyUserGuide tabTooltipClass',
      nextLabel: 'Pin Post',
      position: 'bottom',
      hidePrev: true,
    },
  ]

  const stepsMobile = [
    {
      element: '.CreatePostFixed',
      title: 'Create a post',
      intro: <UserGuidecreatePost />,
      tooltipClass: 'UserGuideCreatePost myTooltipClass',
    },
    {
      element: '.MobileFeatureNav .MobileQuestion',
      title: 'It all starts with Questions',
      intro: <UserGuideQuestion />,
      tooltipClass: 'UserGuideQuestion myTooltipClass',
      hidePrev: true,
      nextLabel: 'Got it!',
      position: 'top',
    },
    {
      element: '.fa-butterflypin',
      title: 'Is your post ready for pinning?',
      intro: <UserGuidePinPost />,
      tooltipClass: 'UserGuidePinPost myTooltipClass',
      nextLabel: 'Got it!',
    },
    {
      element: '.MobileFeatureNav .EventsModule',
      title: 'Your Events',
      intro: <UserGuideEvents />,
      tooltipClass: 'UserGuideEvents myTooltipClass',
      nextLabel: 'Got it!',
      position: 'bottom',
      hidePrev: true,
    },
    {
      element: '.CenterSection .publishButton',
      title: 'Publish your page',
      intro: <UserGuidePublishPost />,
      tooltipClass: 'UserGuidePublishPage myTooltipClass',
      nextLabel: 'Got it!',
      position: 'top',
    },
    {
      element: '.CenterSection .shareButton',
      intro: <UserGuideSharePost />,
      tooltipClass: 'UserGuideSharePage myTooltipClass',
      showButtons: false,
      position: 'top',
    },
  ]

  const stepsTabSmallSize = [
    {
      element: '.CenterSection .CreatePostBtn',
      title: 'Create a post',
      intro: <UserGuidecreatePost />,
      tooltipClass: 'UserGuideCreatePost myTooltipClass tabTooltipClass',
    },

    {
      element: '.MobileFeatureNav .MobileQuestion',
      title: 'It all starts with Questions',
      intro: <UserGuideQuestion />,
      tooltipClass: 'UserGuideQuestion myTooltipClass tabTooltipClass',
      hidePrev: true,
      nextLabel: 'Got it!',
      position: 'bottom',
    },
    {
      element: '.fa-butterflypin',
      title: 'Is your post ready for pinning?',
      intro: <UserGuidePinPost />,
      tooltipClass: 'UserGuidePinPost myTooltipClass tabTooltipClass',
      nextLabel: 'Got it!',
      position: 'top',
    },
    {
      element: '.MobileFeatureNav .EventsModule',
      title: 'Your Events',
      intro: <UserGuideEvents />,
      tooltipClass: 'UserGuideEvents myTooltipClass tabTooltipClass',
      nextLabel: 'Got it!',
      position: 'bottom',
      hidePrev: true,
    },

    {
      element: '.CenterSection .publishButton',
      title: 'Publish your page',
      intro: <UserGuidePublishPost />,
      tooltipClass: 'UserGuidePublishPage myTooltipClass tabTooltipClass',
      nextLabel: 'Got it!',
      position: 'bottom',
    },
    {
      element: '.CenterSection .shareButton',
      intro: <UserGuideSharePost />,
      tooltipClass: 'UserGuideSharePage myTooltipClass tabTooltipClass',
      showButtons: false,
      position: 'bottom',
    },
  ]

  const stepsSubsciption = [
    {
      element: '.subscribe-badge',
      title: 'Join',
      intro: <UserGuideSubscribe />,
      tooltipClass: 'UserGuideSubscribe myTooltipClass',
    },
  ]

  const stepsSubsciptionMobile = [
    {
      element: '.CenterSection .subscribe-badge',
      title: 'Join',
      intro: <UserGuideSubscribe />,
      tooltipClass: 'UserGuideSubscribe myTooltipClass',
    },
  ]

  const stepsSubsciptionPending = [
    {
      element: '.subscribe-badge',
      title: 'Request Pending',
      intro: <UserGuideSubscribePending />,
      tooltipClass: 'UserGuideSubscribe myTooltipClass',
    },
  ]

  const stepsSubsciptionMobilePending = [
    {
      element: '.CenterSection .subscribe-badge',
      title: 'Request Pending',
      intro: <UserGuideSubscribePending />,
      tooltipClass: 'UserGuideSubscribe myTooltipClass',
    },
  ]

  useEffect(() => {
    props.setCantScroll(stepsStatus)
    if (stepsStatus) {
      $('body').addClass('userguideopened')
    } else {
      $('body').removeClass('userguideopened')
    }
  }, [stepsStatus])

  useEffect(() => {
    if (
      pageData?._id &&
      pageData?.belongsTo?._id === userState?.value?.data?._id &&
      !userState?.value?.data?.legacyStep &&
      (!pageData?.is_published || pageData.from_legacy)
    ) {
      if (pageAction === 'show-steps') {
        if (userState?.value?.data?.showStep && userState?.value?.data?.step === '1') {
          setCurrentStep(0)
          setStepStatus(true)
        }
        dispatch(addPageAction(''))
      } else if (pageAction === 'show-question-steps' && userState?.value?.data?.step === '3') {
        if (userState?.value?.data?.showStep) {
          setStepDone(1)
          setCurrentStep(1)
          setStepStatus(true)
          setShowQuestionStep(false)
        }
        dispatch(addPageAction(''))
      } else if (pageAction === 'show-pin-steps') {
        if (userState?.value?.data?.showStep) {
          setStepDone(2)
          setCurrentStep(2)
          setStepStatus(true)
        }
        dispatch(addPageAction(''))
      } else if (pageAction === 'show-event-steps' && userState?.value?.data?.step === '4') {
        if (userState?.value?.data?.showStep) {
          setStepDone(3)
          setCurrentStep(3)
          setStepStatus(true)
        }
        dispatch(addPageAction(''))
      } else if (pageAction === 'show-publish-steps' && userState?.value?.data?.step === '5') {
        if (userState?.value?.data?.showStep) {
          if (!pageData?.is_published) {
            setStepDone(4)
            setCurrentStep(4)
            setStepStatus(true)
          } else {
            setStepDone(5)
            setCurrentStep(5)
            setStepStatus(true)
          }
        }
        dispatch(addPageAction(''))
      }
    }

    if (
      pageData?._id &&
      pageData?.belongsTo?._id === userState?.value?.data?._id &&
      pageData?.is_published &&
      pageData?.from_legacy
    ) {
      if (pageAction === 'show-legacy-steps' && userState?.value?.data?.step === '1') {
        setLegacystepDone(0)
        setCurrentLegacySteps(0)
        setShowLegacySteps(true)
        dispatch(addPageAction(''))
      } else if (pageAction === 'show-legacy-pin-steps' && userState?.value?.data?.step === '2') {
        setLegacystepDone(1)
        setCurrentLegacySteps(1)
        setShowLegacySteps(true)
        dispatch(addPageAction(''))
      }
    }
    if (pageAction === 'hide-steps') {
      setStepStatus(false)
      setShowLegacySteps(false)
      dispatch(addPageAction(''))
    }
    if (
      pageData?._id &&
      pageData?.belongsTo?._id === userState?.value?.data?._id &&
      pageData?.is_published &&
      userState?.pagePublishFromEdit === false
    ) {
      if (pageAction === 'show-share-steps' && userState?.value?.data?.step === '6') {
        if (userState?.value?.data?.showStep) {
          setStepDone(5)
          setCurrentStep(5)
          setStepStatus(true)
        }
        dispatch(addPageAction(''))
      }
    }

    if (
      pageData?._id &&
      pageData?.belongsTo?._id !== userState?.value?.data?._id &&
      (pageData?.subscribtion_status === '' || pageData?.subscribtion_status === 'PENDING')
    ) {
      if (pageAction === 'show-subsciption-step') {
        setShowSubsciption(true)
        dispatch(addPageAction(''))
      }
    }
  }, [pageAction, pageData])

  const _editPost = (data: any) => {
    setEditPost(data)
    setShow(true)
  }

  const showPinPostGuide = () => {
    setShow(false)
    if (userState?.value?.data?.showStep && userState?.value?.data?.step === '2') {
      dispatch(addPageAction('show-legacy-pin-steps'))
    }
  }

  const onLegacyChange = (nextStepIndex: any) => {
    if (nextStepIndex === 1 && legacystepDone === 0) {
      setLegacystepDone(1)
      setShowLegacySteps(false)
      dispatch(
        setShowStepStatus({
          ...userState?.value,
          data: { ...userState?.value?.data, step: '2' },
        })
      )
      const payload = {
        _id: userState?.value?.data?._id,
        step: '2',
      }
      dispatch(updateUserInfo(payload, false))
      _editPost(postData?.postList?.data[0])
    }
  }

  const onChange = (nextStepIndex: any) => {
    if (nextStepIndex === 1 && stepDone === 0) {
      setStepDone(1)
      setStepStatus(false)
      dispatch(
        setShowStepStatus({
          ...userState?.value,
          data: { ...userState?.value?.data, step: '3' },
        })
      )
      const payload = {
        _id: userState?.value?.data?._id,
        step: '3',
      }
      dispatch(updateUserInfo(payload, false))
      dispatch(addPageAction('show-description-post'))
    } else if (nextStepIndex === 2 && stepDone === 1) {
      setStepDone(2)
      setStepStatus(false)
      if (isMobile) {
        dispatch(setActiveSideSlug('question-page'))
        dispatch(
          setSidePanel({
            panelBody: `Your Questions`,
            status: true,
          })
        )
      } else {
        dispatch(setPopup('question-popup'))
      }
      dispatch(
        setShowStepStatus({
          ...userState?.value,
          data: { ...userState?.value?.data, step: '4' },
        })
      )
      const payload = {
        _id: userState?.value?.data?._id,
        step: '4',
      }
      dispatch(updateUserInfo(payload, false))
    } else if (nextStepIndex === 3 && stepDone === 2) {
      setStepDone(3)
      setStepStatus(false)
      if (showQuestionStep) {
        dispatch(addPageAction('show-question-steps'))
      }
    } else if (nextStepIndex === 4 && stepDone === 3) {
      setStepDone(4)
      setStepStatus(false)
      dispatch(setActiveSideSlug('event-page'))
      dispatch(
        setSidePanel({
          panelBody: `Your events`,
          status: true,
        })
      )
      dispatch(
        setShowStepStatus({
          ...userState?.value,
          data: { ...userState?.value?.data, step: '5' },
        })
      )
      const payload = {
        _id: userState?.value?.data?._id,
        step: '5',
      }
      dispatch(updateUserInfo(payload, false))
    } else if (nextStepIndex === 5 && stepDone === 4) {
      setStepDone(4)
      setStepStatus(false)
      dispatch(
        setShowStepStatus({
          ...userState?.value,
          data: { ...userState?.value?.data, step: '6' },
        })
      )
      const payload = {
        _id: userState?.value?.data?._id,
        step: '6',
      }
      dispatch(updateUserInfo(payload, false))
    }
  }

  const onCompleteSubsciption = () => {
    setShowSubsciption(false)
  }

  const legacyStepsComplete = () => {
    trackEvent('Conversion Event', 'Legacy Page Onboarding Completion', `${userState?.value?.data?._id}`)
    dispatch(
      setShowStepStatus({
        ...userState?.value,
        data: { ...userState?.value?.data, step: '1', legacyStep: false },
      })
    )
    const payload = {
      _id: userState?.value?.data?._id,
      step: '1',
      legacyStep: false,
    }
    dispatch(updateUserInfo(payload, false))
    setShowLegacySteps(false)

    const payloadPin = { postId: postData?.postList?.data[0]?._id, status: true }
    dispatch(pinUnpinPost(payloadPin))
    // dispatch(addPageAction("show-steps"));
  }

  const skipLegacyStep = (stepIndex: number) => {
    if (stepIndex === 1) {
      dispatch(
        setShowStepStatus({
          ...userState?.value,
          data: { ...userState?.value?.data, step: '2' },
        })
      )
      const payload = {
        _id: userState?.value?.data?._id,
        step: '2',
      }
      dispatch(updateUserInfo(payload, false))
      setLegacystepDone(1)
      setShowLegacySteps(false)
      setTimeout(() => {
        dispatch(addPageAction('show-legacy-pin-steps'))
      }, 750)
    } else {
      dispatch(
        setShowStepStatus({
          ...userState?.value,
          data: { ...userState?.value?.data, step: '1', legacyStep: false },
        })
      )
      const payload = {
        _id: userState?.value?.data?._id,
        step: '1',
        legacyStep: false,
      }
      dispatch(updateUserInfo(payload, false))
      setShowLegacySteps(false)
      setTimeout(() => {
        dispatch(addPageAction('show-steps'))
      }, 750)
    }
  }

  const legacyStepsStart = (stepIndex: number) => {
    // console.log(stepIndex);
    setTimeout(() => {
      $('.introjs-tooltip .introjs-tooltipbuttons').prepend(
        `<a role="button" tabindex="0" class="introjs-button butterfly-custum-skip-now butterfly-custum-skip-click-${
          stepIndex + 1
        }" data-currentstep="${stepIndex + 1}">Skip</a>`
      )
    }, 200)

    $('body').on('click', `.butterfly-custum-skip-click-${stepIndex + 1}`, function () {
      // console.log('Call Skip click');
      skipLegacyStep(stepIndex + 1)
    })
  }

  const legacyStepsExit = (stepIndex: number) => {
    // console.log('Close Event');
    $('body').off('click', `.butterfly-custum-skip-click-${stepIndex + 1}`, function () {
      // console.log('off click');
    })
    // $('.butterfly-custum-skip-now').unbind('click');
    // $('.butterfly-custum-skip-now').detach();
  }

  const mainStepsStart = (stepIndex: number) => {
    if (stepIndex < 4) {
      // console.log(stepIndex);
      setTimeout(() => {
        $('.introjs-tooltip .introjs-tooltipbuttons').prepend(
          `<a role="button" tabindex="0" class="introjs-button butterfly-custum-skip-now butterfly-custum-skip-click-main-${
            stepIndex + 1
          }" data-currentstep="${stepIndex + 1}">Skip</a>`
        )
        if (stepIndex === 0) {
          $('.introjs-tooltip .introjs-tooltipbuttons .introjs-nextbutton').text('Create Post')
        } else if (stepIndex === 1) {
          $('.introjs-tooltip .introjs-tooltipbuttons .introjs-nextbutton').text('Answer Questions')
        } else if (stepIndex === 2) {
          $('.introjs-tooltip .introjs-tooltipbuttons .introjs-nextbutton').text('Pin Post')
        } else if (stepIndex === 3) {
          $('.introjs-tooltip .introjs-tooltipbuttons .introjs-nextbutton').text('+ Add Event')
        }
      }, 200)

      $('body').on('click', `.butterfly-custum-skip-click-main-${stepIndex + 1}`, function () {
        console.log('Call Skip click')
        skipMainStep(stepIndex + 1)
      })
    }
  }

  const mainStepsExit = (stepIndex: number) => {
    // console.log('Close Event');
    $('body').off('click', `.butterfly-custum-skip-click-main-${stepIndex + 1}`, function () {
      // console.log('off click');
    })
    // $('.butterfly-custum-skip-now').unbind('click');
    // $('.butterfly-custum-skip-now').detach();
  }

  const skipMainStep = (stepIndex: number) => {
    if (stepIndex === 1) {
      dispatch(
        setShowStepStatus({
          ...userState?.value,
          data: { ...userState?.value?.data, step: '3' },
        })
      )
      const payload = {
        _id: userState?.value?.data?._id,
        step: '3',
      }
      dispatch(updateUserInfo(payload, false))
      setStepDone(1)
      setStepStatus(false)
      setTimeout(() => {
        dispatch(addPageAction('show-question-steps'))
      }, 750)
    } else if (stepIndex === 2) {
      dispatch(
        setShowStepStatus({
          ...userState?.value,
          data: { ...userState?.value?.data, step: '4' },
        })
      )
      const payload = {
        _id: userState?.value?.data?._id,
        step: '4',
      }
      dispatch(updateUserInfo(payload, false))
      setStepDone(2)
      setStepStatus(false)
      setTimeout(() => {
        dispatch(addPageAction('show-event-steps'))
      }, 750)
    } else if (stepIndex === 3) {
      dispatch(
        setShowStepStatus({
          ...userState?.value,
          data: { ...userState?.value?.data, step: '3' },
        })
      )
      const payload = {
        _id: userState?.value?.data?._id,
        step: '3',
      }
      dispatch(updateUserInfo(payload, false))
      setStepDone(2)
      setStepStatus(false)
      setTimeout(() => {
        dispatch(addPageAction('show-question-steps'))
      }, 750)
    } else if (stepIndex === 4) {
      dispatch(
        setShowStepStatus({
          ...userState?.value,
          data: { ...userState?.value?.data, step: '5' },
        })
      )
      const payload = {
        _id: userState?.value?.data?._id,
        step: '5',
      }
      dispatch(updateUserInfo(payload, false))
      setStepDone(4)
      setStepStatus(false)
      setTimeout(() => {
        dispatch(addPageAction('show-publish-steps'))
      }, 750)
    }
  }

  const onComplete = () => {
    // should be comment(balaji)
    props.setCantScroll(false)
    trackEvent('Conversion Event', 'Page Onboarding Completion', `${userState?.value?.data?._id}`)
    dispatch(
      setShowStepStatus({
        ...userState?.value,
        data: { ...userState?.value?.data, showStep: false, step: '' },
      })
    )
    // should be comment(balaji)
    const payload = {
      _id: userState?.value?.data?._id,
      step: '',
      showStep: false,
      show_member_intro: false,
    }
    // should be comment(balaji)
    dispatch(updateUserInfo(payload, false))
    // should be comment(balaji)
  }

  const getSubscribeStatusStpes = () => {
    if (pageData?.subscribtion_status === 'PENDING') {
      return isMobile ? stepsSubsciptionMobilePending : stepsSubsciptionPending
    } else {
      return isMobile ? stepsSubsciptionMobile : stepsSubsciption
    }
  }

  const getMainStpes = () => {
    if (window.innerWidth > 767.98 && window.innerWidth <= 1199.98) {
      return stepsTabSmallSize
    } else {
      return isMobile ? stepsMobile : steps
    }
  }

  const getlegacyStepsStpes = () => {
    if (window.innerWidth > 767.98 && window.innerWidth <= 1199.98) {
      return legacyStepsTabSmallSize
    } else {
      return legacySteps
    }
  }

  return (
    <>
      {userState?.value?.data?.showStep && (
        <Steps
          enabled={stepsStatus}
          steps={getMainStpes()}
          initialStep={currentStep}
          onComplete={onComplete}
          onExit={mainStepsExit}
          onStart={mainStepsStart}
          options={{
            showButtons: true,
            exitOnEsc: false,
            exitOnOverlayClick: false,
            keyboardNavigation: false,
            showBullets: false,
            hidePrev: true,
            autoPosition: true,
            disableInteraction: true,
            nextLabel: 'Got it!',
          }}
          onChange={onChange}
        />
      )}

      <Steps
        enabled={showSubsciption}
        steps={getSubscribeStatusStpes()}
        initialStep={currentStep}
        onComplete={onCompleteSubsciption}
        onExit={() => {}}
        options={{
          showButtons: true,
          exitOnEsc: false,
          exitOnOverlayClick: false,
          keyboardNavigation: false,
          showBullets: false,
          hidePrev: true,
          autoPosition: true,
          disableInteraction: true,
          nextLabel: 'Got it!',
        }}
        onChange={() => {}}
      />
      <Steps
        enabled={showLegacySteps}
        steps={getlegacyStepsStpes()}
        initialStep={currentLegacySteps}
        onComplete={legacyStepsComplete}
        onExit={legacyStepsExit}
        onStart={legacyStepsStart}
        options={{
          showButtons: true,
          exitOnEsc: false,
          exitOnOverlayClick: false,
          keyboardNavigation: false,
          showBullets: false,
          hidePrev: true,
          autoPosition: true,
          disableInteraction: true,
          nextLabel: 'Edit Post',
          prevLabel: 'Skip',
          doneLabel: 'Pin Post',
        }}
        onChange={onLegacyChange}
      />

      {show ? (
        <CreatePost
          title="Edit Post"
          isMeidaPost={false}
          show={show}
          handleClose={showPinPostGuide}
          from={'description'}
          editPost={editPost}
        />
      ) : (
        ''
      )}
    </>
  )
}
export default UserGuideStepper
