import './Post.scss'

import cn from 'classnames'
import React, { useEffect, useRef, useState } from 'react'
import { Card } from 'react-bootstrap'
import { isMobile } from 'react-device-detect'
import OutsideClickHandler from 'react-outside-click-handler'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import Carousel from 'react-simply-carousel'
// import FbImageLibrary from "react-fb-image-grid";
import * as Realm from 'realm-web'

import { trackEvent } from '../../Analytics/GA'
import { environment } from '../../AppConfig/environment'
import { createdMonthDayYear, htmlContentWithLink, isset, parseURLParams } from '../../commonservice'
import { RouteConstants } from '../../Constants/routeConstants'
import { useAppSelector } from '../../Hooks'
import { addPageAction, addPageActionData } from '../../Redux/slices/pageDetailSlice'
import {
  allowPostPagination,
  commentOnOffPostResp,
  deletePostResponse,
  getPostComments,
  getPostDetailsResp,
  loadPostList,
  pinUnpinPostResp,
  updatePostResponse,
} from '../../Redux/slices/post'
import { loading, setPrevPageId } from '../../Redux/slices/userSlice'
import {
  commentOnOffPost,
  getPinnedPosts,
  getPosts,
  pinUnpinPost,
  postAddLikeThunk,
  postDetails,
  postDetailsLoad,
  removePostLikeThunk,
} from '../../Redux/thunks/postThunk'
import LottieControl from '../lottie'
import CreatePost from '../Modals/CreatePost/CreatePost'
import DeletePopup from '../Modals/DeletePopup'
import PostPopup from '../Modals/PostPopup/PostPopup'
import ReactionListPopup from '../Modals/ReactionListPopup/ReactionListPopup'
import ReportPopup from '../Modals/ReportPopup'
import AudioWaves from '../Shared/AudioWaves'

const RealApp = new Realm.App({ id: `${environment.RealmAppId}` })

const Post = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const topRef = useRef<null | HTMLDivElement>(null)
  const userData = useAppSelector((state: any) => state?.user)
  const pageData = useAppSelector((state: any) => state?.pagedetail)
  const postData = useAppSelector((state: any) => state?.post)
  const _allowpostPagination = useSelector((state: any) => state?.post?.allowpostpagination)
  const pageAction = pageData?.pageAction
  const pageActionData = pageData?.pageActionData
  const pageDetailState = pageData?.data

  const pagePostCountData: any = postData?.postList?.count

  const [, setUser] = useState<any>()
  const [modalShow, setModalShow] = useState(false)
  const [reactionModalShow, setReactionModalShow] = useState(false)
  const [activeReaction, setActiveReaction] = useState<any>([])
  const [posts, setPosts] = useState<any>([])
  const [activePost, setActivePost] = useState<any>({})
  const [currentPostId, setCurrentPostId] = useState<any>()
  const [activeSlide, setActiveSlide] = useState(0)
  const [pagePostCount, setPagePostCount] = useState(0)

  const handleClose = () => {
    setModalShow(false)
    setActivePost({})
  }
  const [deleteshow, setDeleteshow] = useState(false)
  const [reportshow, setReportshow] = useState(false)
  const [reportData, setReportData] = useState({})
  const [deleteType, setDeleteType] = useState('post')
  const [additionData, setAdditionData] = useState({})
  const handleDeletesPopupClose = () => {
    setDeleteshow(false)
  }
  const handleReportPopupClose = () => {
    setReportshow(false)
  }
  const [, setPlayvideo] = useState(0)
  const [getScrollCount, setScrollCount] = useState<number>(2)

  // edit post
  const [show, setShow] = useState(false)
  const [editPost, setEditPost] = useState<any>({})

  const isBlur = () => {
    return (
      pageDetailState.privacy_status === 'PRIVATE' &&
      pageDetailState?.belongsTo?._id !== userData?.value?.data?._id &&
      pageDetailState?.subscribtion_status !== 'APPROVED'
    )
  }

  useEffect(() => {
    setPagePostCount(pagePostCountData)
    localStorage.setItem('pagePostCount', pagePostCountData)
  }, [pagePostCountData])

  useEffect(() => {
    console.log(_allowpostPagination)
  }, [_allowpostPagination])

  useEffect(() => {
    if (pageData?.data?._id !== userData?.prevPageId) {
      if (localStorage.getItem('fromSearch')) {
        localStorage.setItem('getScrollCount', '1')
      }
      dispatch(loading(true))
      if (localStorage.getItem('paginationFlag') !== '2') {
        localStorage.setItem('paginationFlag', '1')
      } else {
        localStorage.removeItem('paginationFlag')
      }

      localStorage.setItem('pageId', pageData?.data?._id)

      if (localStorage.getItem('fromSearch') === '1') {
        dispatch(allowPostPagination(true))
        localStorage.setItem('allowPostPagination', '1')
      }

      if (
        pageData?.data._id &&
        !isBlur() &&
        _allowpostPagination !== false &&
        localStorage.getItem('paginationFlag') === '1'
      ) {
        if (localStorage.getItem('paginationFlag') === '1') {
          if (localStorage.getItem('paginationFlag') === '2') {
            localStorage.removeItem('paginationFlag')
          } else {
            localStorage.setItem('paginationFlag', '2')
          }
        }
        // Initial call on Page load
        console.log('Initial call')
        dispatch(getPinnedPosts(pageData?.data?._id))
        dispatch(allowPostPagination(false))
        /* if(localStorage.getItem('fromSearch') === '1') {
          setScrollCount(1);
          localStorage.removeItem('fromSearch');
        }else{
          setScrollCount(2);
        } */
        dispatch(getPosts(pageData?.data?._id))
        localStorage.setItem('getScrollCount', '2')
        setScrollCount(2)
      } else {
        if (pageData?.data._id && _allowpostPagination !== false && localStorage.getItem('fromSearch') === '1') {
          localStorage.removeItem('fromSearch')
          console.log('Initial call else')
          dispatch(allowPostPagination(false))
          setPosts([])
          dispatch(getPosts(pageData?.data?._id))
          localStorage.setItem('getScrollCount', '2')
          setScrollCount(2)
        }
      }
      dispatch(setPrevPageId(pageData?.data?._id))
    }
  }, [pageData?.data?._id])

  useEffect(() => {
    if (pageAction === 'reloadPage') {
      dispatch(loading(true))
      if (pageData?.data._id && !isBlur() && _allowpostPagination !== false) {
        // Call on Edit User Profile to change User Info like Profile pic and name.
        dispatch(allowPostPagination(false))
        setPosts([])
        dispatch(getPosts(pageData?.data?._id))
      }
      dispatch(addPageAction(''))
    }
  }, [pageAction])

  useEffect(() => {
    const updatePostData = postData?.postList?.data?.map((e: any, key: number) => {
      return {
        ...e,
        isLikeDisable: false,
        medias: e?.medias?.map((ele: any) => {
          ele = Object.assign({ mediaPlay: false }, ele)
          return ele
        }),
      }
    })
    setPosts(updatePostData)

    dispatch(loading(false))
  }, [postData?.postList?.data])

  useEffect(() => {
    if (postData?.commentOnOffPostResponse?.success) {
      const updatePostData = posts?.map((e: any, key: number) => {
        if (e._id === postData?.commentOnOffPostResponse?.postId) {
          return {
            ...e,
            commentsDisabled: !e.commentsDisabled,
          }
        } else {
          return e
        }
      })

      setPosts(updatePostData)

      dispatch(loading(false))
      dispatch(
        commentOnOffPostResp({
          ...postData?.commentOnOffPostResponse,
          success: null,
        })
      )
      setTimeout(() => {
        toggleClose()
      }, 500)
    }
  }, [postData?.commentOnOffPostResponse])

  useEffect(() => {
    if (postData?.pinUnPinPostResponse?.success) {
      const updatePostData = posts
        ?.sort((obj1: any, obj2: any) => obj2.createdAt - obj1.createdAt)
        ?.map((e: any, key: number) => {
          if (e._id === postData?.pinUnPinPostResponse?.postId) {
            return {
              ...e,
              isPinned: !e.isPinned,
            }
          } else {
            return e
          }
        })
      dispatch(loadPostList(updatePostData))
      setPosts(updatePostData)
      dispatch(loading(false))
      dispatch(pinUnpinPostResp({ ...postData?.pinUnPinPostResponse, success: null }))
      if (userData?.value?.data?.showStep && userData?.value?.data?.step === '1') {
        dispatch(addPageAction('show-steps'))
      }
    }
  }, [postData?.pinUnPinPostResponse])

  useEffect(() => {
    if (postData?.deletePostResponse?.success) {
      const updatedPost = posts?.filter((e: any) => e._id !== postData?.deletePostResponse?.postId)
      // setPosts(updatedPost);
      dispatch(loadPostList(updatedPost))
      dispatch(loading(false))
      // dispatch(pageDetailThunk(pageData?.data?.unique_url, userData?.value?.data?._id))
      dispatch(deletePostResponse({ ...postData?.deletePostResponse, success: null }))
      toggleClose()
    }
  }, [postData?.deletePostResponse])

  useEffect(() => {
    if (postData?.updatePostResponse?.success === true) {
      const info: any = []

      posts.forEach((e: any) => {
        if (e._id === postData?.updatePostResponse?.data?._id) {
          const medias: any = []
          postData?.updatePostResponse?.data?.medias?.forEach((e: any) => {
            medias.push({ ...e, type: e?.type })
          })
          info.push({
            ...e,
            description: postData?.updatePostResponse?.data?.description,
            medias,
          })
        } else {
          info.push(e)
        }
      })
      dispatch(loadPostList(info))
      dispatch(loading(false))
      dispatch(updatePostResponse({ ...postData?.updatePostResponse, success: null }))
    }
  }, [postData?.updatePostResponse])

  const _pinUnPinPost = (postId: any, status: boolean) => {
    dispatch(loading(true))
    const payload = { postId, status }
    dispatch(pinUnpinPost(payload))
  }

  const _commentOnOffPost = (postId: any, status: boolean) => {
    dispatch(loading(true))
    const payload = { postId, status }
    dispatch(commentOnOffPost(payload))
  }

  const toggleClose = () => {
    const postActiveMenu: any = document.getElementsByClassName('postActiveMenu')

    for (let i = 0; i < postActiveMenu?.length; i++) {
      postActiveMenu[i].classList.add('d-none')
    }

    const postActiveMenuPinned: any = document.getElementsByClassName('postActiveMenu-pined')
    for (let i = 0; i < postActiveMenuPinned?.length; i++) {
      postActiveMenuPinned[i].classList.add('d-none')
    }
  }

  const _editPost = (data: any) => {
    setEditPost(data)
    setShow(true)
    toggleClose()
  }

  const _deletePost = (id: any) => {
    setDeleteType('post')
    setAdditionData({})
    setDeleteshow(true)
    setCurrentPostId(id)
    setTimeout(() => {
      toggleClose()
    }, 500)
  }

  const openToggle = (index: number, className: string, postIdKey: string) => {
    const activeItem: any = document.getElementById(`${postIdKey}` + index)

    if (activeItem) {
      const activeMenu: any = document.getElementsByClassName(`${className}`)

      for (let i = 0; i < activeMenu?.length; i++) {
        if (i !== index) {
          activeMenu[i].classList.add('d-none')
        }
      }
      if (activeItem.classList.contains('d-none')) {
        activeItem.classList.remove('d-none')
      } else {
        activeItem.classList.add('d-none')
      }
    }
  }

  const getPostImageCollection = (postData: any) => {
    let imagesCollection = []
    imagesCollection = postData?.medias
      // ?.filter((e: any) => e?.type === "image")
      ?.map((ele: any, index: number) => ele.url)
    return imagesCollection
  }

  const checkLikedOrNot = (userId: string, postLikeObject: any) => {
    const likeUser = postLikeObject?.filter((e: any) => e?.createdBy?._id === userId)
    return likeUser.length
  }

  const addlikePost = (userId: string, postId: string, pageId: string) => {
    const payload = {
      post: postId,
      createdBy: userId,
    }
    const LikeAdd = {
      post: postId,
      createdBy: {
        _id: userId,
      },
      is_deleted: false,
    }

    const updatePostData = posts?.map((e: any, key: number) => {
      if (e._id === postId) {
        return {
          ...e,
          isLikeDisable: true,
          likes: [...e.likes, LikeAdd],
        }
      } else {
        return e
      }
    })
    setPosts(updatePostData)
    dispatch(postAddLikeThunk(payload, pageId, postId))
  }

  const removelikePost = (userId: string, postId: string, pageId: string, postLikeObject: any) => {
    const likeUser = postLikeObject?.find((e: any) => e?.createdBy?._id === userId)
    const likeId = likeUser?._id

    const updatePostData = posts?.map((e: any, key: number) => {
      if (e._id === postId) {
        const likesObj = [...e?.likes]
        const objWithIdIndex = likesObj.findIndex((obj: any) => obj._id === likeId)
        if (objWithIdIndex > -1) {
          likesObj?.splice(objWithIdIndex, 1)
        }
        return {
          ...e,
          isLikeDisable: true,
          likes: [...likesObj],
        }
      } else {
        return e
      }
    })
    setPosts(updatePostData)
    dispatch(removePostLikeThunk(postId, likeId, pageId, 'post', postId))
  }

  const responsive = [
    { minWidth: 1024.9, itemsToShow: 1.6 },
    { minWidth: 768, itemsToShow: 1.5 },
    { maxWidth: 767, itemsToShow: 1 },
  ]

  const stopAllVideo = () => {
    const allVideo: any = document.getElementsByClassName('video-player')

    for (let i = 0; i < allVideo?.length; i++) {
      allVideo[i].pause()
    }
  }

  const stopAllMedia = async (key: number, vindex: any, type: string) => {
    stopAllVideo()
    if (type === 'video') {
      setPlayvideo(key + vindex)
      const activeItemThumb: any = document.getElementById(`video-thumb-${key}-${vindex}`)
      if (activeItemThumb) {
        activeItemThumb.classList.add('d-none')
      }
      const activeItemPlayer: any = document.getElementById(`video-player-${key}-${vindex}`)
      if (activeItemPlayer) {
        await activeItemPlayer.play()
      }
    }
    setPosts((data: any) => {
      const info = data?.map((e: any, index: number) => {
        return {
          ...e,
          medias: e?.medias?.map((ele: any) => {
            if (key === e?._id && vindex === ele?._id) {
              ele = { ...ele, mediaPlay: true }
            } else {
              ele = { ...ele, mediaPlay: false }
            }
            return ele
          }),
        }
      })
      return info
    })
  }

  const canAction = () => {
    if (
      pageData?.data?.belongsTo?._id === userData?.value?.data?._id ||
      pageData?.data?.subscribtion_status === 'APPROVED'
    ) {
      return true
    } else {
      return false
    }
  }

  const loginRedirect = () => {
    navigate(RouteConstants.LOGIN)
  }

  useEffect(() => {
    if (pageAction === 'open-media-post-priview' && pageActionData !== '') {
      setModalShow(true)
      setActivePost(pageActionData)
      dispatch(addPageAction(''))
      dispatch(addPageActionData(''))
    } else if (pageAction === 'post-top-scroll') {
      if (posts?.filter((element: any, key: number) => !element.isPinned).length) {
        const barHeight = '80px'
        if (topRef?.current) {
          topRef.current.style.scrollMargin = barHeight
          topRef.current.scrollIntoView({ behavior: 'smooth' })
        }
        dispatch(addPageAction(''))
      } else {
        dispatch(addPageAction('page-top-scroll'))
      }
    }
  }, [pageAction, pageActionData])

  const postContentArea = (element: any, _classname: string) => {
    return (
      <div className={element?.medias?.length === 0 ? 'no-media-post wrapper ' + _classname : 'wrapper ' + _classname}>
        {element?.title && <p className="Question">{element?.title}</p>}
        {element?.createdBy?.name === 'Team Butterfly' &&
        !element?.isPinned &&
        element?.description.indexOf('- ') > 0 ? (
          element?.description !== '' ? (
            <>
              <p
                className="Description d-none"
                dangerouslySetInnerHTML={{
                  __html: element?.description.substring(0, element?.description.lastIndexOf('- ')),
                }}
              ></p>

              <p className="Description">
                {htmlContentWithLink(element?.description.substring(0, element?.description.lastIndexOf('- ')))}
              </p>
            </>
          ) : (
            ''
          )
        ) : element?.description !== '' ? (
          <>
            <p
              className="Description d-none"
              dangerouslySetInnerHTML={{
                __html: element?.description,
              }}
            ></p>

            <p className="Description">{htmlContentWithLink(element?.description)}</p>
          </>
        ) : (
          ''
        )}
      </div>
    )
  }

  useEffect(() => {
    const streamlogin = async () => {
      // Authenticate anonymously
      const user = await RealApp.logIn(Realm.Credentials.anonymous())
      setUser(user)

      const mongodb = RealApp.currentUser?.mongoClient('mongodb-atlas')
      if (!mongodb) return
      const collection = mongodb.db(`${environment.mongodbStream}`).collection('galleries')

      for await (const change of collection.watch({
        page: pageData?.data?._id,
      })) {
        /*  console.log(
			"BELONGS TO BEFORE",
			change.fullDocument.belongsTo.toString()
		); */
        if (change.operationType === 'update' && change.fullDocument?.page?.toString() === pageData?.data?._id) {
          // console.log(change.fullDocument);
          // console.log("postId", change.fullDocument?.post?.toString());
          // console.log("_id", change.fullDocument?._id?.toString());
          // console.log("thumbnail", change.fullDocument?.thumbnail);
          // const postId = change.fullDocument.post.toString();
          // const mediaId = change.fullDocument._id.toString();
          if (
            change.fullDocument?.post?.toString() &&
            change.fullDocument?._id?.toString() &&
            change.fullDocument?.thumbnail
          ) {
            dispatch(postDetailsLoad(change.fullDocument?.post?.toString()))
          }

          /* const updatePostList = postData?.postList?.data?.map((postInfo: any) => {
            if (postInfo?._id === postId) {
              return {
                ...postInfo,
                medias: postInfo?.medias.map((mediasInfo: any) => {
                  if (mediasInfo?._id === mediaId) {
                    return {
                      ...mediasInfo,
                      thumbnail: thumbnailUrl
                    };
                  } else {
                    return mediasInfo;
                  }
                }),
              };
            } else {
              return postInfo;
            }
          }); */
          // console.log('updated Media', updatePostList);
          // console.log('updated Media', postData?.postList?.data);

          // dispatch(loadPostList(updatePostList));

          // Need to improve logic for Thumbnil Need PostId and MediaId
          // dispatch(getPosts(pageData?.data?._id));
        }
      }
    }

    streamlogin()
  }, [pageData?.data?._id, postData?.postList?.data])

  const postComponent = (element: any, key: number, type: string) => {
    const limiPost =
      type === '-pined' && posts?.filter((element: any, key: number) => element.isPinned).length > 1 ? 4 : 5

    return (
      <Card className="PostCard" key={`post${type}-item-${key}`}>
        <div className="Header">
          <div className="Profile">
            <div className="owner">
              <div className="ownerPic">
                {isset(element?.createdBy?.image) ? (
                  <img src={element?.createdBy?.image} alt="" />
                ) : (
                  <div className="ownerAlpha">
                    <span>{element?.createdBy?.name?.charAt(0)}</span>
                  </div>
                )}
              </div>
              {element?.createdBy?.name === 'Team Butterfly' &&
              element?.description?.indexOf('- ') > 0 &&
              !element?.isPinned ? (
                <div className="ownerName">
                  {element?.description?.substring(
                    element?.description?.lastIndexOf('- ') + 2,
                    element?.description?.length
                  )}
                </div>
              ) : (
                <div className="ownerName">{element?.createdBy?.name}</div>
              )}
            </div>
          </div>

          <div className="actions">
            <span className="pin">
              {pageData?.data?.belongsTo?._id === userData?.value?.data?._id && (
                <i
                  className={element?.isPinned ? 'fa fa-butterflypinned' : 'fa fa-butterflypin'}
                  onClick={() => {
                    if (pageData?.data?.belongsTo?._id === userData?.value?.data?._id) {
                      _pinUnPinPost(element?._id, !element?.isPinned)
                    }
                  }}
                ></i>
              )}
              {canAction() && (
                <span
                  className="quick-menu-dots dots"
                  onClick={() => {
                    openToggle(key, `postActiveMenu${type}`, `post${type}-quickmenu`)
                  }}
                >
                  <i className="fa fa-meatballs"></i>
                </span>
              )}
            </span>
            <OutsideClickHandler
              className="dropdown"
              onOutsideClick={() => {
                const activeItem: any = document.getElementById(`post${type}-quickmenu` + key)
                if (activeItem) {
                  const activeMenu: any = document.getElementsByClassName(`postActiveMenu${type}`)
                  for (let i = 0; i < activeMenu?.length; i++) {
                    if (i !== key) {
                      activeMenu[i].classList.add('d-none')
                    }
                  }
                }
              }}
            >
              <div className={`ActionMenu postActiveMenu${type} d-none`} id={`post${type}-quickmenu${key}`}>
                {element?.createdBy?._id === userData?.value?.data?._id ||
                pageData?.data?.belongsTo?._id === userData?.value?.data?._id ? (
                  <ul>
                    {element?.createdBy?._id === userData?.value?.data?._id && (
                      <li
                        onClick={() => {
                          _editPost(element)
                          trackEvent('Page Interaction', 'Post Edit', `${userData?.value?.data?._id}`)
                        }}
                      >
                        <i className="fa fa-edit"></i> Edit
                      </li>
                    )}
                    <li
                      onClick={() => {
                        _deletePost(element?._id)
                        trackEvent('Page Interaction', 'Post Delete', `${userData?.value?.data?._id}`)
                      }}
                    >
                      <i className="fa fa-butterflydelete"></i> Delete
                    </li>
                    {pageData?.data?.belongsTo?._id === userData?.value?.data?._id && (
                      <li
                        onClick={() => {
                          _commentOnOffPost(element?._id, !element?.commentsDisabled)
                          trackEvent('Page Interaction', 'Post Comment Toggle', `${userData?.value?.data?._id}`)
                        }}
                      >
                        <i className="fa fa-turnoff"></i>
                        {element?.commentsDisabled === false ? 'Turn off comments' : 'Turn on comments'}
                      </li>
                    )}
                  </ul>
                ) : canAction() ? (
                  <ul>
                    <li
                      onClick={() => {
                        setReportData({
                          page: element?.page,
                          post: element?._id, // null
                          media: null,
                          reporter: userData?.value?.data?._id,
                          report: '',
                          type: 'POST', // MEDIA , POST
                        })
                        setReportshow(true)
                      }}
                    >
                      <i className="fa fa-report"></i> Report
                    </li>
                  </ul>
                ) : (
                  <></>
                )}
              </div>
            </OutsideClickHandler>
          </div>
        </div>
        <div className={element?.title === '' && element?.description === '' ? 'withoutdesc Body' : 'Body'}>
          {element?.title !== '' && element?.description !== '' && element?.medias?.length ? (
            <React.Fragment>{postContentArea(element, 'Qam')}</React.Fragment>
          ) : (
            ''
          )}

          {element?.title !== '' && element?.description === '' && element?.medias?.length ? (
            <React.Fragment>{postContentArea(element, 'Qm')}</React.Fragment>
          ) : (
            ''
          )}

          {element?.title !== '' && element?.description !== '' && element?.medias?.length === 0 ? (
            <React.Fragment>{postContentArea(element, 'Qa')}</React.Fragment>
          ) : (
            ''
          )}

          {element?.title === '' && element?.description !== '' && element?.medias?.length ? (
            <React.Fragment>{postContentArea(element, 'DM')}</React.Fragment>
          ) : (
            ''
          )}

          {element?.title === '' && element?.description === '' && element?.medias?.length ? (
            <React.Fragment>{postContentArea(element, 'M')}</React.Fragment>
          ) : (
            ''
          )}

          {element?.title === '' && element?.description !== '' && element?.medias?.length === 0 ? (
            <React.Fragment>{postContentArea(element, 'D')}</React.Fragment>
          ) : (
            ''
          )}

          {
            // element?.medias?.filter((e: any) => e?.type === "image")?.length ? (
            element?.medias?.length > 1 || element?.medias?.filter((e: any) => e?.type === 'image')?.length ? (
              <div className="GridWrapper">
                <div
                  className={
                    // element?.medias?.filter((e: any) => e?.type === "image")?.length
                    element?.medias?.length ? 'ImageGrid' : 'ImageGrid d-none'
                  }
                >
                  {getPostImageCollection(element).length ? <></> : <></>}
                  <div
                    className={cn('grid-container', {
                      'single-image': getPostImageCollection(element).length === 1,
                      'multiple-images': getPostImageCollection(element).length > 1 && type === '-pined',
                      'two-image-grid-post-list': getPostImageCollection(element).length === 2,
                      'three-image-grid-post-list': getPostImageCollection(element).length === 3,
                      'four-image-grid-post-list': getPostImageCollection(element).length === 4,
                      'five-image-grid-post-list': getPostImageCollection(element).length === 5,
                      'more-image-grid-post-list': getPostImageCollection(element).length > 5,
                    })}
                  >
                    {element?.medias
                      // ?.filter((e: any) => e?.type === "image")
                      ?.map((ele: any, index: number) => {
                        if (index < limiPost) {
                          return (
                            <div
                              key={index}
                              className={cn(`eachThumbnail eachThumbnail-post-${index + 1}`, {
                                two: getPostImageCollection(element).length === 2,
                                three: getPostImageCollection(element).length === 3,
                                four: getPostImageCollection(element).length === 4,
                                five: getPostImageCollection(element).length === 5,
                                'video-type-post': ele.type === 'video',
                                'audio-type-post': ele.type === 'audio',
                                'no-video-thumb': ele.type === 'video' && !ele.thumbnail,
                              })}
                              id={'page-feed-media-eachThumbnail-' + index}
                              style={{
                                backgroundImage: `url(${
                                  ele.type === 'video' && ele.thumbnail ? ele.thumbnail : ele.url
                                })`,
                              }}
                              onClick={() => {
                                setModalShow(true)
                                setActivePost({
                                  ...element,
                                  activeIndex: index,
                                })
                              }}
                            >
                              {ele.type === 'video' && (
                                <>
                                  <div className="overlay"></div>
                                  <i className="fa fa-play"></i>
                                  {!ele.thumbnail && (
                                    <div className="spinner-border text-light" role="status">
                                      <span className="visually-hidden">Loading...</span>
                                    </div>
                                  )}
                                </>
                              )}
                              {ele.type === 'audio' && <i className="fa fa-butterflyheadphone"></i>}

                              {index === limiPost - 1 && getPostImageCollection(element).length > limiPost ? (
                                <div className="more-images">
                                  <span>+ {getPostImageCollection(element).length - limiPost}</span>
                                </div>
                              ) : (
                                ''
                              )}
                            </div>
                          )
                        }
                        return undefined
                      })}
                  </div>
                </div>
              </div>
            ) : (
              <></>
            )
          }
          {element?.medias?.filter((e: any) => e?.type === 'audio')?.length === 1 && (
            <div className="audio-post">
              {element?.medias
                ?.filter((e: any) => e?.type === 'audio')
                ?.map((ele: any, aindex: number) => {
                  return (
                    <AudioWaves
                      stopAllMedia={async () => {
                        await stopAllMedia(element?._id, ele?._id, 'audio')
                      }}
                      mediaPlay={ele.mediaPlay}
                      key={aindex}
                      audioURL={ele.url}
                    />
                  )
                })}
            </div>
          )}
          {element?.medias?.filter((e: any) => e?.type === 'video')?.length === 1 && (
            <div className="video-post">
              {element?.medias
                ?.filter((e: any) => e?.type === 'video')
                ?.map((ele: any, vindex: number) => {
                  return (
                    <>
                      <video
                        key={vindex}
                        src={ele.url}
                        controls={true}
                        // loop
                        // muted
                        // autoPlay={key + vindex === playideo}
                        id={`video-player-${element?._id}-${ele?._id}`}
                        className="video-player"
                      ></video>
                      <div
                        onClick={() => {
                          stopAllMedia(element?._id, ele?._id, 'video')
                        }}
                        className={cn('video-thumb', {
                          'no-video-thumb': !ele.thumbnail,
                        })}
                        id={`video-thumb-${element?._id}-${ele?._id}`}
                        style={{
                          backgroundImage: `url(${ele.type === 'video' && ele.thumbnail ? ele.thumbnail : ''})`,
                        }}
                      >
                        <>
                          <div className="overlay"></div>
                          <span
                            onClick={() => {
                              stopAllMedia(element?._id, ele?._id, 'video')
                            }}
                          >
                            <i className="fa fa-play"></i>
                          </span>
                          {!ele.thumbnail && (
                            <div className="spinner-border text-light" role="status">
                              <span className="visually-hidden">Loading...</span>
                            </div>
                          )}
                        </>
                      </div>
                    </>
                  )
                })}
            </div>
          )}
        </div>
        <div className="Footer">
          <div className="FooterText">
            <p>{createdMonthDayYear(element?.createdAt)}</p>
          </div>
          <div className="FooterButton">
            <span
              onClick={() => {
                if (!userData?.value?.data?._id) {
                  loginRedirect()
                } else if (!canAction()) {
                  dispatch(addPageAction('show-subsciption-step'))
                } else {
                  dispatch(getPostComments([]))
                  setModalShow(true)
                  setActivePost({
                    ...element,
                    activeIndex: null,
                  })
                }
              }}
            >
              {element?.counts?.comments ? element?.counts?.comments : ''}
              <i className={element?.counts?.comments > 0 ? 'fa fa-comment' : 'fa fa-nocomment'}></i>
            </span>
            <span>
              {element?.likes?.length ? element?.likes?.length : ''}
              {checkLikedOrNot(userData?.value?.data?._id, element?.likes) ? (
                <i
                  onClick={() => {
                    if (!userData?.value?.data?._id) {
                      loginRedirect()
                    } else if (!canAction()) {
                      dispatch(addPageAction('show-subsciption-step'))
                    } else {
                      removelikePost(userData?.value?.data?._id, element?._id, element?.page, element?.likes)
                    }
                  }}
                  className={cn('fa fa-butterflylike', {
                    disabled: element?.isLikeDisable || !canAction(),
                  })}
                ></i>
              ) : (
                <i
                  onClick={() => {
                    if (!userData?.value?.data?._id) {
                      loginRedirect()
                    } else if (!canAction()) {
                      dispatch(addPageAction('show-subsciption-step'))
                    } else {
                      addlikePost(userData?.value?.data?._id, element?._id, element?.page)
                    }
                  }}
                  className={cn('fa fa-heartoutline', {
                    disabled: element?.isLikeDisable,
                  })}
                ></i>
              )}
            </span>
          </div>
        </div>
      </Card>
    )
  }

  const [screenSize, getDimension] = useState({
    dynamicWidth: window.innerWidth,
  })

  const setDimension = () => {
    getDimension({
      dynamicWidth: window.innerWidth,
    })
  }
  useEffect(() => {
    window.addEventListener('resize', setDimension)
    if (isMobile) {
      localStorage.setItem('getScrollCount', '2')
      window.addEventListener('scroll', handleScrollMobile)
    }
    const urlParams: any = parseURLParams(window.location.href)
    if (urlParams?.adminId?.length && urlParams?.postId?.length) {
      dispatch(postDetails(urlParams?.postId[0]))
    }
    return () => {
      if (pageData?.data._id && posts.length > 0) {
        dispatch(allowPostPagination(true))
        // setScrollCount(0);
      }

      localStorage.removeItem('pagePostCount')
      localStorage.removeItem('getScrollCount')
      localStorage.removeItem('pageId')
      window.removeEventListener('resize', setDimension)
      if (isMobile) {
        window.removeEventListener('scroll', handleScrollMobile)
      }
    }
  }, [])

  useEffect(() => {
    if (postData?.postDetailsResp?.success === true) {
      setActivePost({
        ...postData?.postDetailsResp?.data[0],
        activeIndex: null,
      })
      setModalShow(true)
      dispatch(getPostDetailsResp({ ...postData?.postDetailsResp, success: null }))
    }
  }, [postData?.postDetailsResp?.success])

  const handleScroll = (e: any) => {
    if (pageData?.data?._id) {
      const bottom = e.target.scrollHeight - parseInt(e.target.scrollTop)
      if (
        Math.abs(bottom - e?.target?.clientHeight) === 0 &&
        pagePostCount &&
        pagePostCount > 0 &&
        _allowpostPagination !== false
      ) {
        if (getScrollCount <= Math.ceil(pagePostCount / 15) && pagePostCount > posts?.length) {
          console.log('scroll event')
          dispatch(allowPostPagination(false))
          dispatch(getPosts(pageData?.data?._id, getScrollCount))
          setScrollCount(getScrollCount + 1)
        }
      }
    }
  }

  const handleScrollMobile = (e: any) => {
    if (pageData?.data?._id) {
      const windowBottomHeight = document.documentElement.offsetHeight
      const pagePostCountLocal = parseInt(localStorage.getItem('pagePostCount') as string)
      const getScrollCountLocal = parseInt(localStorage.getItem('getScrollCount') as string)
      if (
        e.srcElement.body.scrollHeight - (window.scrollY + windowBottomHeight) < 80 &&
        pagePostCountLocal &&
        pagePostCountLocal > 0 &&
        localStorage.getItem('allowPostPagination') === '1' &&
        // localStorage.getItem("mobilepostpagination") !== "1" &&
        getScrollCountLocal > 1
      ) {
        if (getScrollCountLocal <= pagePostCountLocal / 15) {
          // localStorage.setItem("mobilepostpagination", 1);
          console.log('mobile scroll event', _allowpostPagination)

          dispatch(allowPostPagination(false))
          localStorage.setItem('allowPostPagination', '0')
          dispatch(getPosts(localStorage.getItem('pageId'), getScrollCountLocal))
          localStorage.setItem('getScrollCount', (getScrollCountLocal + 1).toString())
        }
      }
    }
  }

  return (
    <>
      <div
        className="PostCardWrapper"
        onScroll={(e: any) => {
          // if (!isMobile && !isTablet) {
          if (screenSize?.dynamicWidth > 1199.98) {
            handleScroll(e)
          }
        }}
      >
        {posts?.filter((element: any, key: number) => element.isPinned).length ? (
          <>
            <div
              className={cn('', {
                PinCardWrapper: posts?.filter((element: any, key: number) => element.isPinned).length > 1,
                'CardWrapper Pin': posts?.filter((element: any, key: number) => element.isPinned).length === 1,
              })}
            >
              <h4>Pinned </h4>
              {posts?.filter((element: any, key: number) => element.isPinned).length > 1 ? (
                <Carousel
                  centerMode
                  containerProps={{
                    style: {
                      width: '100%',
                      justifyContent: 'space-between',
                      userSelect: 'text',
                    },
                    className: 'CarouselWrapper',
                  }}
                  infinite={false}
                  activeSlideIndex={activeSlide}
                  activeSlideProps={{
                    style: {
                      // background: "#" + Math.floor(Math.random()*16777215).toString(16)
                    },
                  }}
                  onRequestChange={setActiveSlide}
                  forwardBtnProps={{
                    children: <i className="fa fa-rightarrow"></i>,
                    className: 'control next',
                    style: {
                      alignSelf: 'center',
                      position: 'absolute',
                      zIndex: '9',
                      top: '50%',
                      right: '0',
                      transform: 'translateY(-50%)',
                    },
                  }}
                  backwardBtnProps={{
                    children: <i className="fa fa-leftarrow"></i>,
                    className: 'control prev',
                    style: {
                      alignSelf: 'center',
                      position: 'absolute',
                      zIndex: '9',
                      top: '50%',
                      transform: 'translateY(-50%)',
                    },
                  }}
                  dotsNav={{
                    show: false,
                  }}
                  speed={100}
                  responsiveProps={responsive}
                  preventScrollOnSwipe={true}
                >
                  {posts
                    ?.filter((element: any, key: number) => element.isPinned)
                    ?.sort((obj1: any, obj2: any) => obj2.createdAt - obj1.createdAt)
                    ?.map((element: any, key: number) => {
                      return (
                        <div
                          key={key}
                          className={'postCard'}
                          // style={{ width: "350px", minHeight: "300px" }}
                          style={{
                            width: `${screenSize.dynamicWidth - 30}px`,
                          }}
                        >
                          <React.Fragment>{postComponent(element, key, '-pined')}</React.Fragment>
                        </div>
                      )
                    })}
                </Carousel>
              ) : (
                <>
                  {posts
                    ?.filter((element: any, key: number) => element.isPinned)
                    ?.sort((obj1: any, obj2: any) => obj2.createdAt - obj1.createdAt)
                    ?.map((element: any, key: number) => {
                      return <React.Fragment key={key}>{postComponent(element, key, '-pined')}</React.Fragment>
                    })}
                </>
              )}
            </div>
          </>
        ) : (
          <></>
        )}
        <div className="top-ref" ref={topRef}></div>
        <div className="CardWrapper">
          {posts?.length ? (
            posts
              ?.filter((element: any, key: number) => !element.isPinned)
              ?.sort((obj1: any, obj2: any) => obj2.createdAt - obj1.createdAt)
              ?.map((element: any, key: number) => {
                return <React.Fragment key={key}>{postComponent(element, key, '')}</React.Fragment>
              })
          ) : isBlur() ? (
            <div className="EmptyPosts">
              <i className="fa fa-butterflyeyehidden"></i>
              <span>Sorry, but this page is private.</span>To view it you need to join and get approved.
            </div>
          ) : (
            <>
              {
                // pageData.data.length === 0 || pageData?.data?.counts?.feed ? (
                pageData.data.length === 0 || pageData?.data?.counts?.feed ? (
                  <div className="post-inline-loader">
                    <LottieControl />
                  </div>
                ) : (
                  <div className="EmptyPosts">
                    <span>This page is lonely!</span>It’s fast and easy to create a post. Once you do, members can read
                    everything you write, and can participate in the conversation.
                  </div>
                )
              }
            </>
          )}
          {userData.loading &&
          (getScrollCount > 2 ||
            parseInt(localStorage.getItem('getScrollCount') as string) > 2 ||
            _allowpostPagination === false) ? (
            <div className="post-pagination-loader">
              <LottieControl />
            </div>
          ) : (
            <></>
          )}
          {show ? (
            <CreatePost
              title="Edit Post"
              isMeidaPost={false}
              show={show}
              handleClose={() => {
                setShow(false)
              }}
              from={'description'}
              editPost={editPost}
            />
          ) : (
            ''
          )}

          {modalShow ? (
            <PostPopup
              show={modalShow}
              handleClose={() => {
                handleClose()
              }}
              activePost={activePost}
              deleteshow={setDeleteshow}
              deleteType={setDeleteType}
              currentPostId={setCurrentPostId}
              additionData={setAdditionData}
              reactionModalShow={setReactionModalShow}
              setActiveReaction={setActiveReaction}
            />
          ) : (
            ''
          )}

          {deleteshow ? (
            <DeletePopup
              deleteshow={deleteshow}
              pageid={currentPostId}
              handleclose={() => {
                handleDeletesPopupClose()
              }}
              deleteType={deleteType}
              mediadata={additionData}
              albumdata={{}}
            />
          ) : (
            <></>
          )}

          {reportshow ? (
            <ReportPopup
              reportshow={reportshow}
              reportdata={reportData}
              handleclose={() => {
                handleReportPopupClose()
              }}
            />
          ) : (
            <></>
          )}
          {reactionModalShow && (
            <ReactionListPopup
              activePost={activePost}
              show={reactionModalShow}
              handleClose={() => {
                setReactionModalShow(false)
              }}
              activeReaction={activeReaction}
              type={'comment-reply'}
            />
          )}
        </div>
      </div>
      <div className="EmptyDiv"></div>
    </>
  )
}

export default Post
