import { Form, Formik } from 'formik'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import * as Yup from 'yup'

import { useAppSelector } from '../../Hooks'
import { changePasswordResponse, updateUserDetail } from '../../Redux/slices/settingsSlice'
import { loading, login } from '../../Redux/slices/userSlice'
import { uploadMedia } from '../../Redux/thunks/pageDetailThunk'
import { changePassword, updateUserInfo } from '../../Redux/thunks/settingsThunk'
import { Regex } from '../../Regex'
import Loader from '../Loader/Loader'
import AlertPopup from '../Modals/AlertPopup/AlertPopup'
import RoundedCropper from '../Modals/CropperPopup/RoundedCropper'

const OwnerDetail = () => {
  const dispatch = useDispatch()
  const userState = useAppSelector((state: any) => state?.user)
  const settingsState = useAppSelector((state: any) => state?.settings)
  const [userDetails, setUserDetails] = useState<any>({})
  const [Img, setImg] = useState({ file: '', url: '', selected_img: '' })
  const [showCropper, setShowCropper] = useState(false)
  const [imgValidation, setImgValidation] = useState('')
  const [PasswordShown, setPasswordShown] = useState(false)
  const [ConfPasswordShown, setConfPasswordShown] = useState(false)

  const updateProfile = () => {
    // update user data.
    /* File upload */
    if (Img.file !== '') {
      const formData: any = new FormData()
      formData.append('media', Img?.file)
      uploadMedia(formData, userDetails?._id).then(response => {
        setUserDetails({ ...userDetails, image: response?.data?.data[0]?.url })
        /* User update API call */
        const payload: any = userDetails
        payload.image = response?.data?.data[0]?.url
        delete payload.email
        dispatch(updateUserInfo(payload))
      })
    } else {
      /* User update API call */
      const payload: any = userDetails
      payload.image = Img?.url
      delete payload.email
      dispatch(updateUserInfo(payload))
    }
  }

  const selectImg = () => {
    const input = document.getElementById('page-image')
    if (input) {
      input?.click()
    }
  }

  const typeValidation = (event: any) => {
    setImg((data: any) => {
      return {
        ...data,
        file: '',
      }
    })
    const selectedFile = event.target.files[0]
    const imageFile = selectedFile
    if (!imageFile.name.match(/\.(jpg|jpeg|png|webp)$/)) {
      setImgValidation('Only jpg | jpeg | png | webpimages allowed.')
      return false
    }
    setImgValidation('')
    if (event.target.files?.length) {
      setImg({
        ...Img,
        selected_img: URL.createObjectURL(event?.target?.files[0]),
      })

      const reader: any = new FileReader()
      const file: any = selectedFile
      reader.readAsDataURL(file)
      setImg((data: any) => {
        return { ...data, file }
      })
      reader.onload = () => {
        setImg((data: any) => {
          return { ...data, selected_img: reader?.result }
        })
        setShowCropper(true)
      }
    }
  }

  const CroppedImage = (e: any) => {
    if (e && e !== 'cancel') {
      setImg((data: any) => {
        return { ...data, url: URL.createObjectURL(e), file: e }
      })
    }
    setShowCropper(false)
  }

  const changePasswordSubmit = (data: any) => {
    // delete data.repassword;
    dispatch(loading(true))
    dispatch(changePassword(data))
  }

  const PWSchema = Yup.object().shape({
    currentPassword: Yup.string().required(''),
    password: Yup.string()
      .required('')
      .min(8, ' ')
      .matches(/^(?=.{8,16})(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[~`!@#$%^&*()--+={}[\]|\\:;"'<>,.?/_₹])/, ' '),
    repassword: Yup.string()
      .required('')
      .min(8, 'Passwords do not match')
      .oneOf([Yup.ref('password')], 'Passwords do not match'),
  })

  useEffect(() => {
    if (settingsState?.updateUserDetailsResp?.success === true) {
      dispatch(loading(false))
      dispatch(
        login({
          ...userState?.value,
          data: { ...userState?.value?.data, name: userDetails?.name, image: userDetails?.image },
        })
      )
      dispatch(updateUserDetail({ ...settingsState?.updateUserDetailsResp, success: null }))
    }
  }, [settingsState?.updateUserDetailsResp?.success])

  useEffect(() => {
    setUserDetails({
      _id: userState?.value?.data?._id,
      image: userState?.value?.data?.image,
      name: userState?.value?.data?.name,
      email: userState?.value?.data?.email,
    })
    setImg({ url: userState?.value?.data?.image, file: '', selected_img: '' })
  }, [userState?.value?.data])

  useEffect(() => {
    if (settingsState?.changePWResp?.success === true) {
      const resetbtn = document.getElementById('resetPWFrm')
      if (resetbtn) {
        resetbtn?.click()
      }
      writeChangePWSuccessMsg(settingsState?.changePWResp?.message)
      dispatch(changePasswordResponse({ ...settingsState?.changePWResp, success: null }))
      setTimeout(() => {
        writeChangePWSuccessMsg('')
      }, 10000)
    }
    if (settingsState?.changePWResp?.success !== null) {
      dispatch(loading(false))
    }
  }, [settingsState?.changePWResp?.success])

  const writeChangePWSuccessMsg = (message: string) => {
    const msg = document.getElementById('change-pw-message')
    if (msg) {
      msg.innerHTML = message !== '' ? "<i class='fa fa-circle-check' aria-label='true'></i> " + message : message
    }
  }

  return (
    <div className="OwnerDetails">
      {userState.loading && <Loader />}
      <div className="ImgCroper">
        <div className="ImgWrapper">
          {Img?.url === '' || Img?.url === null ? <span>{userDetails?.name?.charAt(0)}</span> : <img src={Img?.url} />}
        </div>
        <span className="cropImg" onClick={selectImg}>
          <i className="fa fa-camera" aria-hidden="true"></i>
        </span>
        <input type="file" name="image" className="d-none" id="page-image" onChange={typeValidation} />
        <p className="text text-danger errorMessage">{imgValidation}</p>
      </div>
      <div className="mt-4 row">
        <div className="mb-4 mb-lg-0">
          <label className="form-label" htmlFor="formGridFname">
            Name<sup>*</sup>
          </label>
          <input
            name="name"
            type="text"
            id="formGridFname"
            className="form-control"
            value={userDetails?.name}
            onChange={(e: any) => {
              setUserDetails({ ...userDetails, name: e?.target?.value })
            }}
          />
        </div>
      </div>
      <div className=" mt-4 mb-4 row">
        <div>
          <label className="form-label" htmlFor="formGridFname">
            Email
          </label>
          <input
            name="email"
            type="text"
            id="formGridFname"
            className="form-control"
            value={userDetails?.email}
            disabled
          />
        </div>
      </div>

      <div className="mobile-alignment">
        {userState?.value?.data ? (
          <button
            type="button"
            disabled={Img.file === '' && userDetails?.name === userState?.value?.data?.name}
            className="btn btn-primary"
            onClick={updateProfile}
          >
            Save
          </button>
        ) : (
          ''
        )}
      </div>
      <div className="passowrd mt-5">
        <h6>Password</h6>
        <Formik
          validateOnMount={true}
          validateOnChange={true}
          validationSchema={PWSchema}
          onSubmit={changePasswordSubmit}
          initialValues={{
            currentPassword: '',
            password: '',
            repassword: '',
            user: userState?.value?.data?._id,
          }}
        >
          {({ handleSubmit, handleChange, handleBlur, values, touched, isValid, errors, resetForm }) => (
            <Form noValidate onSubmit={handleSubmit}>
              <div className="form-group">
                <span
                  className="d-none"
                  id="resetPWFrm"
                  onClick={() => {
                    resetForm()
                  }}
                ></span>
                <label htmlFor="CurrentPassword" className="form-label">
                  Current password
                </label>
                <input
                  type="password"
                  className="form-control"
                  id="CurrentPassword"
                  name="currentPassword"
                  value={values?.currentPassword}
                  onChange={(e: any) => {
                    handleChange(e)
                  }}
                />
                <p className="text text-danger">{errors?.currentPassword}</p>
              </div>
              <div className="form-group passwordToggle">
                <label htmlFor="Password" className="form-label">
                  New password
                </label>
                <input
                  type={PasswordShown ? 'text' : 'password'}
                  className="form-control"
                  id="Password"
                  minLength={8}
                  maxLength={32}
                  name="password"
                  value={values?.password}
                  onChange={handleChange}
                />
                <i
                  className={PasswordShown ? 'fa fa-private icon' : 'fa fa-eye-btm icon'}
                  aria-hidden="true"
                  onClick={() => {
                    setPasswordShown(!PasswordShown)
                  }}
                ></i>
                {errors?.password !== '' ? <p className="text text-danger">{errors?.password}</p> : ''}
                {!errors?.password &&
                values?.password === values?.currentPassword &&
                values.currentPassword !== '' &&
                values.password !== '' ? (
                  <p className="text text-danger">New password must be different from the current password.</p>
                ) : (
                  ''
                )}
              </div>
              <div className="form-group passwordToggleConfirm">
                <label htmlFor="ConfirmPassword" className="form-label">
                  Re-enter new password
                </label>
                <input
                  type={ConfPasswordShown ? 'text' : 'password'}
                  className="form-control"
                  id="ConfirmPassword"
                  name="repassword"
                  value={values?.repassword}
                  onChange={handleChange}
                />
                <i
                  className={ConfPasswordShown ? 'fa fa-private icon' : 'fa fa-eye-btm icon'}
                  aria-hidden="true"
                  onClick={() => {
                    setConfPasswordShown(!ConfPasswordShown)
                  }}
                ></i>
                <p className="text text-danger">{errors?.repassword}</p>
              </div>

              <p
                className={
                  values?.password.length >= 8 && values?.password.match(Regex.password)
                    ? 'form-group note d-none'
                    : 'form-group note'
                }
              >
                Password must be
                <span
                  className={
                    values?.password !== ''
                      ? values?.password.length < 8
                        ? 'text text-danger'
                        : 'text text-success'
                      : 'text text-default'
                  }
                >
                  {' '}
                  at least 8 characters long{' '}
                </span>
                and contain a
                <span
                  className={
                    values?.password !== ''
                      ? values?.password.match(Regex.oneLowercaseChar)
                        ? 'text text-success'
                        : 'text text-danger'
                      : ''
                  }
                >
                  {' '}
                  lowercase letter
                </span>
                , an
                <span
                  className={
                    values?.password !== ''
                      ? values?.password.match(Regex.oneUpperCaseChar)
                        ? 'text text-success'
                        : 'text text-danger'
                      : ''
                  }
                >
                  {' '}
                  uppercase letter
                </span>
                ,
                <span
                  className={
                    values?.password !== ''
                      ? values?.password.match(Regex.oneNumberChar)
                        ? 'text text-success'
                        : 'text text-danger'
                      : ''
                  }
                >
                  {' '}
                  a number
                </span>
                , and
                <span
                  className={
                    values?.password !== ''
                      ? values?.password.match(Regex.oneSpecialChar)
                        ? 'text text-success'
                        : 'text text-danger'
                      : ''
                  }
                >
                  {' '}
                  a special character
                </span>
                .
              </p>

              <button
                type="submit"
                id="password-submit"
                disabled={!isValid || values?.password === values?.currentPassword}
                value="Save Changes"
                className="btn btn-lg btn-block changePassword"
              >
                Change password
              </button>

              <div className="mt-1 text-success" id="change-pw-message"></div>
            </Form>
          )}
        </Formik>
      </div>
      {showCropper ? (
        <RoundedCropper
          show={showCropper}
          selectedImg={Img.selected_img}
          setCroppedImage={CroppedImage}
          croppershap={'round'}
        ></RoundedCropper>
      ) : (
        ''
      )}

      <AlertPopup
        buttonText={false}
        show={settingsState?.changePWResp?.success === false}
        content={settingsState?.changePWResp?.message}
        state={'ERROR'}
        onHide={() => {
          dispatch(changePasswordResponse({ ...settingsState?.changePWResp, success: null }))
        }}
      />
    </div>
  )
}
export default OwnerDetail
