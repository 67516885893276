import './Mypages.scss'

import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import Select from 'react-select'

import { parseURLParams } from '../../commonservice'
import { RouteConstants } from '../../Constants/routeConstants'
import { useAppSelector } from '../../Hooks'
import { setSidePanel } from '../../Redux/slices/metaSlice'
import { addPageAction, loadPageDetail, setActiveSideSlug } from '../../Redux/slices/pageDetailSlice'
import { loadPostList } from '../../Redux/slices/post'
import { getMyPageListThunk } from '../../Redux/thunks/pageDetailThunk'
import Loader from '../Loader/Loader'

const MyPages = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const Options = [
    {
      value: 'name',
      label: 'Name',
      className: 'dropdown-class',
    },
    {
      value: 'date_created',
      label: 'Date Created',
      className: 'dropdown-class',
    },
    {
      value: 'number_of_members',
      label: 'Number of Members',
      className: 'dropdown-class',
    },
  ]
  const d = new Date()
  const year = d.getFullYear()
  const userState: any = useAppSelector(state => state.user)

  const pageData = useAppSelector((state: any) => state?.pagedetail)
  const pageAction = pageData?.pageAction

  const myPageListData = useAppSelector((state: any) => state.pagedetail?.myPageList)
  const [myPageList, setMyPageList] = useState<any[] | null>(null)

  useEffect(() => {
    dispatch(getMyPageListThunk(userState?.value?.data?._id))
    setTimeout(() => {
      const urlParams: any = parseURLParams(window.location.href)
      if (urlParams?.from?.length && urlParams?.from[0] && urlParams?.from[0] === 'notification_panel') {
        dispatch(setActiveSideSlug('notification-page'))
        dispatch(
          setSidePanel({
            panelBody: `Notifications`,
            status: true,
          })
        )
      }
    }, 1000)
  }, [])

  useEffect(() => {
    if (pageAction === 'reloadPage') {
      dispatch(getMyPageListThunk(userState?.value?.data?._id))
      dispatch(addPageAction(''))
    }
  }, [pageAction])

  useEffect(() => {
    setMyPageList(myPageListData)
  }, [myPageListData])

  const shoring = (value: any) => {
    switch (value.value) {
      case 'date_created': {
        const sortedmyPageListCreated = [...(myPageList ?? [])]?.sort(
          (objA: any, objB: any) => Number(new Date(objB.createdAt)) - Number(new Date(objA.createdAt))
        )
        setMyPageList(sortedmyPageListCreated)
        break
      }
      case 'name': {
        const field = 'first_name'
        const sortedmyPageListName = [...(myPageList ?? [])]?.sort((a, b) =>
          (a[field] || '').toString().localeCompare((b[field] || '').toString())
        )
        setMyPageList(sortedmyPageListName)
        break
      }
      case 'number_of_members': {
        const sortedmyPageListMembers = [...(myPageList ?? [])]?.sort(
          (objA: any, objB: any) => Number(objB.members?.length) - Number(objA.members?.length)
        )
        setMyPageList(sortedmyPageListMembers)
        break
      }
      default:
    }
  }

  return (
    <>
      {userState.loading && <Loader />}
      <div className="MyPages">
        <div className="TopBar">
          <h2>
            My Pages <span>{myPageList !== null ? myPageList?.length : ''}</span>
          </h2>
          {!userState.loading && myPageList !== null && myPageList?.length > 1 ? (
            <div className="TopBar__right">
              <span>
                Sort by :{' '}
                <Select
                  className="butterfly-dropdown-select"
                  classNamePrefix="butterfly"
                  placeholder="Select to sort"
                  options={Options}
                  onChange={value => {
                    shoring(value)
                  }}
                  isSearchable={false}
                  defaultValue={{
                    value: 'date_created',
                    label: 'Date Created',
                  }}
                />
              </span>
            </div>
          ) : (
            ''
          )}
        </div>
        <div className="wrapper">
          {myPageList?.map((pagelist: any, index: number) => {
            return (
              <div
                className="card"
                key={index}
                onClick={() => {
                  dispatch(loadPageDetail([]))
                  dispatch(loadPostList([]))
                  navigate(`/${pagelist?.unique_url}`)
                }}
              >
                <div className="ImgHolder">
                  <div className="ImgWrapper">
                    {pagelist.image_cropped ? <img className="card-img-top" src={pagelist?.image_cropped} /> : <></>}
                    {pagelist.is_published ? <div></div> : <div className="overlay"></div>}

                    <div className="BottomSection">
                      <p> {`${pagelist?.first_name} ${pagelist?.last_name ?? ''}`} </p>
                      <div className="BottomIcons">
                        {pagelist?.privacy_status === 'PUBLIC' ? (
                          <span className="privacy">
                            <i className="fa fa-butterflyeye" aria-hidden="true"></i>
                            Public
                          </span>
                        ) : pagelist?.privacy_status === 'PRIVATE' ? (
                          <span className="privacy">
                            <i className="fa fa-butterflylock2" aria-hidden="true"></i>
                            Private
                          </span>
                        ) : pagelist?.privacy_status === 'HIDDEN' ? (
                          <span className="privacy">
                            <i className="fa fa-butterflyeyehidden" aria-hidden="true"></i>
                            Hidden
                          </span>
                        ) : (
                          <></>
                        )}

                        {pagelist?.members?.length + 1 === 1 ? (
                          <span className="members">
                            <i className="fa fa-membersicon"></i> {pagelist?.members?.length + 1} Member
                          </span>
                        ) : (
                          <span className="members">
                            <i className="fa fa-membersicon"></i> {pagelist?.members?.length + 1} Members
                          </span>
                        )}

                        {pagelist?.is_published && (
                          <span className="subscribe">
                            <i className="fa fa-butterflysubscribed" aria-hidden="true"></i>
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )
          })}
        </div>
        {!userState.loading && (
          <div className="Footer">
            <button
              onClick={() => {
                navigate(`${RouteConstants.CREATEPAGE}`)
              }}
            >
              <i className="fa fa-createpage"></i>
              Create New Page
            </button>
            <p>
              <a
                onClick={() => {
                  navigate('/terms-condition')
                }}
              >
                Terms of Service
              </a>{' '}
              &nbsp;&nbsp;{' '}
              <a
                onClick={() => {
                  navigate('/privacy-policy')
                }}
              >
                Privacy Policy
              </a>{' '}
              <br />© {year} &nbsp; Butterfly.co
            </p>
            <div className="Emptydiv"></div>
          </div>
        )}
      </div>
    </>
  )
}

export default MyPages
