import './Auth.scss'

import { useState } from 'react'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import Form from 'react-bootstrap/Form'
import InputGroup from 'react-bootstrap/InputGroup'
import Row from 'react-bootstrap/Row'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import AxiosClass from '../../Api/Interceptor'
import UserApi from '../../Api/UserApi'
import { butterflyBlack, butterflyWhite } from '../../commonservice'
import { useAppSelector } from '../../Hooks'
import { ErrorStates, setAppError } from '../../Redux/slices/metaSlice'
import { Regex } from '../../Regex'
import Loader from '../Loader/Loader'
import AlertPopup from '../Modals/AlertPopup/AlertPopup'

function ForgotPassword() {
  const userState: any = useAppSelector(state => state.user)
  const appState = useAppSelector(state => state.metadata)
  const d = new Date()
  const year = d.getFullYear()
  const [email, setEmail] = useState('')
  const [error, setError] = useState<any>({
    emailError: {
      state: false,
    },
  })
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const hanldeForgotPass = () => {
    new UserApi(AxiosClass)
      .forgotpass({
        email,
      })
      .then((r: any) => {
        if (r.success) {
          dispatch(
            setAppError({
              name: 'AuthError',
              status: true,
              state: ErrorStates.success,
              content: 'Password reset link sent successfully to ' + email,
            })
          )
        } else {
          throw r
        }
      })
      .catch((e: any) => {
        dispatch(
          setAppError({
            name: 'AuthError',
            status: true,
            state: ErrorStates.error,
            content: 'Error in sending password reset link - ' + e.message,
          })
        )
      })
  }
  return (
    <>
      {appState.error?.status && (
        <AlertPopup
          buttonText={false}
          show={!!appState.error.status}
          content={appState.error.content}
          state={appState.error.state}
          onHide={() => {
            dispatch(setAppError(null))
          }}
        />
      )}
      <section id="AuthPage">
        <Container>
          <Row>
            <Col md={7} className="d-none d-md-block">
              <div className="imgHolder">
                <div className="overlay"></div>
                <div className="logo">
                  <img src={butterflyWhite} alt="Butterfly logo" />
                </div>
                <h1 className="title">A Full Life Deserves a Full Tribute</h1>
              </div>
            </Col>
            <Col md={5} className="lhs">
              {userState.loading && <Loader />}
              <div className="d-xs-block d-md-none m-logo">
                <img src={butterflyBlack} alt="Butterfly logo" />
                <div className="title">A Full Life Deserves a Full Tribute</div>
              </div>
              <div className="formHolder forgotPassword">
                <div className="tab-content">
                  <h2>Forgot Password?</h2>
                  <Form
                    onSubmit={e => {
                      e.preventDefault()
                      return false
                    }}
                  >
                    <div className="inputWithIcon">
                      <InputGroup hasValidation>
                        <Form.Control
                          type="email"
                          placeholder="Email"
                          required
                          isInvalid={error.emailError.state}
                          value={email}
                          disabled={userState.loading}
                          onChange={e => {
                            setEmail(e.currentTarget.value)
                            setError({
                              ...error,
                              emailError: {
                                state: false,
                              },
                            })
                          }}
                          onBlur={e => {
                            if (!e.currentTarget.value) {
                              setError((prevState: any) => {
                                return {
                                  ...prevState,
                                  emailError: {
                                    message: 'Email is required',
                                    state: true,
                                  },
                                }
                              })
                            } else if (!e.currentTarget.value.match(Regex.email)) {
                              setError((prevState: any) => {
                                return {
                                  ...prevState,
                                  emailError: {
                                    message: 'Email is not valid',
                                    state: true,
                                  },
                                }
                              })
                            } else {
                              setError({
                                ...error,
                                emailError: {
                                  state: false,
                                },
                              })
                            }
                          }}
                        />
                        <i className="fa fa-butterflyemail"></i>
                        {error.emailError.state && (
                          <Form.Control.Feedback type="invalid">{error.emailError.message}</Form.Control.Feedback>
                        )}
                      </InputGroup>

                      <i className="fa fa-butterflyemail"></i>
                    </div>
                    <button
                      disabled={!email ? true : true && error.emailError.state}
                      onClick={() => {
                        hanldeForgotPass()
                      }}
                    >
                      Send reset link
                    </button>
                    <span
                      style={{ marginTop: '1em' }}
                      className="Bs"
                      onClick={() => {
                        navigate('/signin')
                      }}
                    >
                      Back to Sign in
                    </span>
                  </Form>
                </div>
              </div>
              <div className="footer">
                <p>© {year} Butterfly.co</p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  )
}

export default ForgotPassword
