import 'bootstrap/dist/css/bootstrap.min.css'
import 'slick-carousel/slick/slick-theme.css'
import 'slick-carousel/slick/slick.css'
import './Home.scss'

// import { useEffect, useState } from 'react'
// import Form from 'react-bootstrap/Form'
// import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import Slider from 'react-slick'

import { trackEvent } from '../../Analytics/GA'
import { butterflyWhite, homephotoalbum, logoNoText } from '../../commonservice'
import { RouteConstants } from '../../Constants/routeConstants'
// import { useAppSelector } from '../../Hooks'
// import { setAddConactResponse } from '../../Redux/slices/userSlice'
// import { addConact } from '../../Redux/thunks'
// import { Regex } from '../../Regex'

const Home = () => {
  const navigate = useNavigate()
  // const dispatch = useDispatch()
  // const [email, setEmail] = useState('')
  // const [message, setMessage] = useState('')
  // const [showSucessMessage, setShowSucessMessage] = useState('')
  // const userState = useAppSelector(state => state.user)
  // const [error, setError] = useState<any>({
  //   emailError: {
  //     state: false,
  //   },
  //   messageError: {
  //     state: false,
  //   },
  // })

  const settings = {
    className: 'bannerSlider',
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
  }

  const d = new Date()
  const year = d.getFullYear()

  // Removed Contact Us form at bottom 12/07
  // const handleSubmit = () => {
  //   const payload = {
  //     message: message.trim(),
  //     email,
  //   }
  //   dispatch(addConact(payload))
  // }

  // Removed Contact Us form at bottom 12/07
  // useEffect(() => {
  //   if (userState?.addConactResponse?.success === true) {
  //     setShowSucessMessage(userState?.addConactResponse?.message)
  //     setEmail('')
  //     setMessage('')
  //     setTimeout(() => {
  //       setShowSucessMessage('')
  //     }, 3000)
  //     dispatch(setAddConactResponse({ success: null, message: '' }))
  //   }
  // }, [userState?.addConactResponse])

  return (
    <div id="HPLandingpage">
      <header className="Header">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="navigationContainer">
                <div className="logo">
                  <picture>
                    <source srcSet={butterflyWhite} media="(min-width: 768px)" />
                    <img src={logoNoText} alt="Butterfly" />
                  </picture>
                </div>
                <ul className="signCta">
                  <li>
                    <button
                      onClick={() => {
                        navigate(RouteConstants.LOGIN)
                        trackEvent('Site Interaction', 'Login Click', 'From Homepage')
                      }}
                      type="button"
                      className="signIn"
                    >
                      Sign In
                    </button>
                  </li>
                  <li>
                    <button
                      type="button"
                      onClick={() => {
                        navigate(RouteConstants.REGISTER)
                        trackEvent('Site Interaction', 'Register Click', 'From Homepage')
                      }}
                      className="signUp"
                    >
                      Register
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </header>
      <div id="Banner">
        <div className="banner-overlay">
          <div className="banner-content">
            <h1>Create an Obituary in Minutes</h1>
            <p>Add photos, videos, and stories to craft meaningful, beautiful obituaries.</p>
            <button
              onClick={() => {
                navigate(RouteConstants.REGISTER)
              }}
              type="button"
              className="getStarted"
            >
              Get Started
            </button>
          </div>
          <div className="bannerSliderWrapper">
            <Slider {...settings}>
              <div className="each-slider">
                <img
                  src="https://butterflyv2-production-assets.nyc3.digitaloceanspaces.com/image/dc23db1c-50b5-4fc7-ae5f-c68dd04ee7b7-01.jpg"
                  alt="Banner Slider"
                  onClick={() => {
                    navigate(RouteConstants.REGISTER)
                  }}
                />
              </div>
              <div className="each-slider">
                <img
                  src="https://butterflyv2-production-assets.nyc3.digitaloceanspaces.com/image/e6aac589-aec3-495a-aaa1-efe38c69df05-02.jpg"
                  alt="Banner Slider"
                  onClick={() => {
                    navigate(RouteConstants.REGISTER)
                  }}
                />
              </div>

              <div className="each-slider">
                <img
                  src="https://butterflyv2-production-assets.nyc3.digitaloceanspaces.com/image/6bd00b7d-eb78-4708-8771-43f86d1a1f9b-03.jpg"
                  alt="Banner Slider"
                  onClick={() => {
                    navigate(RouteConstants.REGISTER)
                  }}
                />
              </div>
              <div className="each-slider">
                <img
                  src="https://butterflyv2-production-assets.nyc3.digitaloceanspaces.com/image/6b1fe038-bd2d-4096-afe2-22417b85a514-04.jpg"
                  alt="Banner Slider"
                  onClick={() => {
                    navigate(RouteConstants.REGISTER)
                  }}
                />
              </div>
            </Slider>
          </div>
        </div>
      </div>
      <div id="GetStartedIntro">
        <div className="container">
          <div className="gSinner-holder">
            <div className="lhs-section">
              <h2>Butterfly is not social media</h2>
              <p>
                Social media is wonderful for many things. But it doesn&apos;t give those who are dealing with loss the
                ability to create a story that captures the essence and breadth of a life. And to invite others to join
                in the healing ritual of remembrance. That deeply personal experience is what inspired the creator of
                Butterfly to build this special place.
              </p>
              <button
                type="button"
                onClick={() => {
                  navigate(RouteConstants.REGISTER)
                }}
                className="getStarted"
              >
                Get Started
              </button>
            </div>
            <div className="rhs-section">
              <div className="inner-wrapper">
                <div className="each-card">
                  <div className="icon">
                    <i className="fa fa-linkiconfeeds" aria-hidden="true"></i>
                  </div>
                  <div className="content">
                    <h3>Your Feed is Special</h3>
                    <p>
                      It turns emotions into an uplifting narrative, brought to life with photos and videos, and the
                      stories that connect them.
                    </p>
                  </div>
                </div>
                <div className="each-card">
                  <div className="icon">
                    <i className="fa fa-questionsicon" aria-hidden="true"></i>
                  </div>
                  <div className="content">
                    <h3>Thoughtful Prompts</h3>
                    <p>
                      Butterfly knows the right questions to help you tell someone&apos;s story. We know that it can be
                      difficult to know where to start.
                    </p>
                  </div>
                </div>
                <div className="each-card">
                  <div className="icon">
                    <i className="fa fa-eventsicon" aria-hidden="true"></i>
                  </div>
                  <div className="content">
                    <h3>Events, Too</h3>
                    <p>
                      Butterfly is also where memorial services and celebrations of life can be shared with those in
                      your circle of remembrance.
                    </p>
                  </div>
                </div>
                <div className="each-card">
                  <div className="icon">
                    <i className="fa fa-galleryicon" aria-hidden="true"></i>
                  </div>
                  <div className="content">
                    <h3>Gallery</h3>
                    <p>
                      Turn your memories into a mesmerizing montage, bringing together photos and videos to form a
                      captivating visual narrative.
                    </p>
                  </div>
                </div>
                <div className="each-card">
                  <div className="icon">
                    <i className="fa fa-fundraiser" aria-hidden="true"></i>
                  </div>
                  <div className="content">
                    <h3>Fundraiser</h3>
                    <p>
                      Honor life&apos;s significant moments by rallying support and gathering resources for a cause that
                      touches your heart, making a positive impact in the process.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="pAlbum-holder">
            <div className="lhs-section">
              <img src={homephotoalbum} alt="Photo Album" />
            </div>
            <div className="rhs-section">
              <h3>Life is Lived In Moments</h3>
              <p>
                We hear from our Butterfly community that the pages they create are deeply meaningful in how they
                capture the vibrancy of an individual, and the tapestry of their lives.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div id="PreFooter">
        <div className="content">
          <h3>Create an Obituary in Minutes</h3>
          <button
            onClick={() => {
              navigate(RouteConstants.REGISTER)
            }}
            type="button"
            className="getStarted"
          >
            Get Started
          </button>
          {/* {showSucessMessage ? ( // REMOVED CONTACT US FORM
              <div className="contactus-message">{showSucessMessage}</div>
            ) : (
              <Form>
                <Form.Group className="mb-4" controlId="formBasicEmail">
                  <Form.Label>
                    Email Address<sup>*</sup>
                  </Form.Label>
                  <Form.Control
                    required
                    value={email}
                    isInvalid={error.emailError.state}
                    type="email"
                    onChange={e => {
                      setEmail(e.currentTarget.value)
                      if (!e.currentTarget.value) {
                        setError((prevState: any) => {
                          return {
                            ...prevState,
                            emailError: {
                              message: 'Email is required',
                              state: true,
                            },
                          }
                        })
                      } else if (!e.currentTarget.value.match(Regex.email)) {
                        setError((prevState: any) => {
                          return {
                            ...prevState,
                            emailError: {
                              message: 'Email is not valid',
                              state: true,
                            },
                          }
                        })
                      } else {
                        setError({
                          ...error,
                          emailError: {
                            state: false,
                          },
                        })
                      }
                    }}
                    onBlur={e => {
                      if (!e.currentTarget.value) {
                        setError((prevState: any) => {
                          return {
                            ...prevState,
                            emailError: {
                              message: 'Email is required',
                              state: true,
                            },
                          }
                        })
                      } else if (!e.currentTarget.value.match(Regex.email)) {
                        setError((prevState: any) => {
                          return {
                            ...prevState,
                            emailError: {
                              message: 'Email is not valid',
                              state: true,
                            },
                          }
                        })
                      }
                    }}
                  />
                  {error.emailError.state && (
                    <Form.Control.Feedback type="invalid">{error.emailError.message}</Form.Control.Feedback>
                  )}
                </Form.Group>
                <Form.Group className="mb-4" controlId="formBasicPassword">
                  <Form.Label>
                    Message<sup>*</sup>
                  </Form.Label>
                  <Form.Control
                    required
                    value={message}
                    isInvalid={error.messageError.state}
                    as="textarea"
                    onChange={e => {
                      setMessage(e.currentTarget.value)
                      if (!e.currentTarget.value) {
                        setError((prevState: any) => {
                          return {
                            ...prevState,
                            messageError: {
                              message: 'Message is required',
                              state: true,
                            },
                          }
                        })
                      } else {
                        setError({
                          ...error,
                          messageError: {
                            state: false,
                          },
                        })
                      }
                    }}
                    onBlur={e => {
                      if (!e.currentTarget.value) {
                        setError((prevState: any) => {
                          return {
                            ...prevState,
                            messageError: {
                              message: 'Message is required',
                              state: true,
                            },
                          }
                        })
                      }
                    }}
                  />
                  {error.messageError.state && (
                    <Form.Control.Feedback type="invalid">{error.messageError.message}</Form.Control.Feedback>
                  )}
                </Form.Group>
                <button
                  className="sendMessage"
                  disabled={!email || !message ? true : (true && error.emailError.state) || error.messageError.state}
                  type="button"
                  onClick={() => {
                    handleSubmit()
                  }}
                >
                  Send message
                </button>
              </Form>
            )} */}
        </div>
      </div>
      <footer className="Footer">
        <div className="container">
          <ul>
            <li>
              <a target="_blank" href={RouteConstants.TERMCONDITION} rel="noreferrer">
                Terms of Service
              </a>
            </li>
            <li>
              <a target="_blank" href={RouteConstants.PRIVACYPOLICY} rel="noreferrer">
                Privacy Policy
              </a>
            </li>
            <li>© {year} Butterfly.co</li>
          </ul>
          <p
            className="hidden-link"
            onClick={() => {
              navigate(RouteConstants.PUBLICPAGES)
            }}
          >
            Pages
          </p>
        </div>
      </footer>
    </div>
  )
}

export default Home
