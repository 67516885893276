// All Environment varible add in this file
export const environment = {
  organization: 'Hmmngbrd Client',
  siteKey: '6LfBud0hAAAAAOqdasYPulyaQRe8Kj_ohvrf3nua',
  secretKey: '6LfBud0hAAAAADo2YqVhK_Yzt_YlsUK4-mAOuDAT',
  imagePath: 'https://api.hmmngbrd.p2staging.us/',
  siteUrl: 'https://hmmngbrd.p2staging.us/',
  StorageURL: 'https://hmmngbrd.nyc3.cdn.digitaloceanspaces.com/',
  stripeKey:
    'pk_test_51Ln4KZLFJVD76OID4wo9FiWEK9jUk1nSqcXdWIC9TKI4TOOsYYEV5g2F9RvGbMVpUwjchZJjrciFAOvvLWf8EhzC00EqCPZSYD',
  proPlanAmount: 20,
  videoImg: 'https://api.hmmngbrd.p2staging.us/images/video-logo.jpg',
  promocode:'PANKAJBETA2023',
  SOCKETURL:"https://socket.butterfly.co",
  postCommentCount:10,
  googleAuthClientId: "1064079123246-p69hb3egf1j8c2m0vl6v7v1ldb92ebs0.apps.googleusercontent.com",
  GoogleReCaptcha :"6LcxfHglAAAAAGTUjUAIhhKFtMVyu9_Fnbd5kVoI",
  TextDecoder: "Swe4g7c?UBm5Nrd96vhsVDtkyJFbqKMTm!TMw5BDRLtaCFAXNvbq?s4rGKQSZnUP",
  apiEndPoint:"https://api.prod.butterfly.co/",
  gDriveClientId:"666241510265-sc9hrthkbsf8ig6rjbm2b57brilkdgd8.apps.googleusercontent.com",
  gDriveDeveloperKey:"AIzaSyCYsZ1YO8uidtO-bJSO2FnePtverYtwEvw",
  googleMapApiKey: "AIzaSyDRLDXKrSBhvFh-yXE42-In7nPwQcTKUA8",
  AdminApiEndPoint:"https://api.prod.butterfly.co/admin",
  frontEndPoint:"https://butterfly.co/",
  pledgeOrigin:"https://hello.pledge.to",
  RealmAppId:"application-0-seifu",
  pledgePartnerKey: '8fe366251a82f3415fb0506bef6390db',
  pledgeApiKey: '420e78cafc33b684c9f21f425c3a84cf',
  mongodbStream: "butterflyv2prod",
  twilioNumber: "+19254773033",
  dropBoxAppKey:"225cketkzbqqyn7"
}

// @ts-nocheck
export const environment_support = {
  organization: 'Pledge',
  apiEndPoint_staging: 'https://api.hmmngbrd.p2staging.us/api/get-organization',
  apiEndPoint_organization: 'https://api.hmmngbrd.p2staging.us/api/get-organization-page?page_id=',
  partnerKey_staging: '7d087db1f2f358fecd6258c31ab19363',
  api_key_staging: '57fd0acbac827bfb70e0240b903e5fa3',
  siteUrl: 'https://api-staging.pledge.to/',
  apiEndPoint: 'https://api.pledge.to/v1',
  partnerKey: '8fe366251a82f3415fb0506bef6390db',
  api_key: '420e78cafc33b684c9f21f425c3a84cf',
  ownerLimitForPledge: 4
}
