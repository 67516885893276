import './QrCode.scss'

import FormData from 'form-data'
import { useEffect, useState } from 'react'
import { Button } from 'react-bootstrap'
import { isIOS } from 'react-device-detect'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'

import { trackEvent } from '../../Analytics/GA'
import { socket } from '../../AppConfig/socket'
import { butterflyBlack } from '../../commonservice'
import { doUpload } from '../../Hooks/doUpload'
import { setAppError } from '../../Redux/slices/metaSlice'
import { setLoadItemsDone, setLoadPercentage } from '../../Redux/slices/userSlice'
import Loader from '../Loader/Loader'
// const socket = io("https://socket.butterfly.co", {
// 	transports: ["websocket"]
// });
const QrCode = () => {
  const { pageId, userId, type } = useParams()
  const dispatch = useDispatch()
  const [selectedFiles, setSelectedFiles] = useState<any>([])
  const removeSelectedFile = (pos: number) => {
    setSelectedFiles(
      selectedFiles?.filter((e: any, index: number) => {
        return pos !== index
      })
    )
  }

  const [showLoader, setShowLoader] = useState(false)

  useEffect(() => {
    socket.emit('qrStart', JSON.stringify({ page: pageId, loggedInUser: userId }), (response: any) => {})

    return () => {
      socket.off('qrStart')
      socket.off('qrEnd')
    }
  })

  const uploadDoMedia = async (files: any[], totalItems: number) => {
    const totalSize = files.reduce((accumulator, file) => {
      return accumulator + file.data.size
    }, 0)
    console.log('TotalSize ', totalSize)
    if (totalSize > 2000000000) {
      dispatch(
        setAppError({
          name: 'AuthError',
          status: true,
          state: 'ERROR',
          content: 'You can only upload 2GBs of files in total. Please remove few files and proceed again.',
        })
      )
    } else {
      // TODO: Refactor the logic. It should not use async.
      // eslint-disable-next-line @typescript-eslint/no-misused-promises, no-async-promise-executor
      return await new Promise(async (resolve, reject) => {
        const allResults: any[] = []
        for (let i = 0; i < files.length; i++) {
          const result = await doUpload(files[i].data, totalItems, i + 1)
          allResults.push(result)
        }
        const mergedResult: any[] = []
        allResults.forEach((m: any) => {
          mergedResult.push(m.data.data[0])
        })
        console.log('MERGED RESULTS ', mergedResult)
        resolve({ data: { data: mergedResult, success: true } })
        console.log('ALL UPLOAD RESULTS ', allResults)
      })
    }
  }
  const uploadSelectedMedia = () => {
    setShowLoader(true)
    const files = new FormData()
    selectedFiles?.forEach((e: any) => {
      files.append('media', e?.data)
    })

    dispatch(setLoadPercentage(0))
    dispatch(setLoadItemsDone(0))
    if (selectedFiles.length > 0) {
      uploadDoMedia(selectedFiles, selectedFiles.length)
        .then((response: any) => {
          socket.emit('qrEnd', JSON.stringify({ ...response.data, loggedInUser: userId }), (response: any) => {
            console.log(response) // "got it"
          })
          setSelectedFiles([])
          setShowLoader(false)
        })
        .catch(() => {})
    }
  }

  const systemFileSelection = (e: any) => {
    if (e?.currentTarget?.files?.length) {
      const image =
        type === 'image'
          ? ['jpg', 'jpeg', 'png', 'tiff', 'bmp', 'svg', 'ico', 'webp', 'tif', 'raw', 'cr2', 'nef', 'orf', 'sr2']
          : [
              'mp4',
              'mov',
              'webm',
              'ogg',
              'avi',
              'mpg',
              'mp2',
              'mpeg',
              'mpe',
              'mpv',
              'm4p',
              'm4v',
              'wmv',
              'qt',
              'flv',
              'sfw',
              'avchd',
              '3gp',
            ]

      const images = []
      for (let i = 0; i < e?.currentTarget?.files?.length; i++) {
        const extension = e?.currentTarget?.files[i].name
          .substring(e?.currentTarget?.files[i].name.lastIndexOf('.') + 1)
          .toLowerCase()
        if (image.includes(extension)) {
          const fileUrl = URL.createObjectURL(e?.currentTarget?.files[i])
          images.push({
            url: fileUrl,
            data: e?.currentTarget?.files[i],
            caption: '',
            thumbnail: type === 'image' ? fileUrl : type === 'video' && !isIOS ? fileUrl : fileUrl,
          })
        } else {
          return false
        }
      }
      setSelectedFiles([...selectedFiles, ...images])
    }
  }

  return (
    <>
      {showLoader && <Loader isActive={true} totalItems={selectedFiles.length} />}
      <div className="QrCode">
        <div className="upload">
          <div className="logo">
            <img src={butterflyBlack} alt="Butterfly Logo" />
            <h4>A Full Life Deserves a Full Tribute</h4>
          </div>
          <div className="ImgHolder">
            {selectedFiles?.map((element: any, key: number) => {
              return (
                <div key={key} className="EachImage">
                  <div
                    className="video-media"
                    style={{
                      backgroundImage: `url(${element.thumbnail ? element.thumbnail : ''})`,
                    }}
                  >
                    {type === 'video' ? <i className="fa fa-play"></i> : <></>}
                  </div>
                  <div
                    className="delete"
                    onClick={() => {
                      removeSelectedFile(key)
                    }}
                  >
                    <i className="fa fa-butterflydelete"></i>
                  </div>
                </div>
              )
            })}
          </div>
          <div className="textfield">
            <label className="upload-area">
              <input
                type="file"
                className="d-none"
                id="FileUploadFromSystem_input"
                accept={`${type === 'image' ? 'image/*' : 'video/mp4,video/x-m4v,video/*'}`}
                multiple
                onChange={systemFileSelection}
              />
              <span>
                <i className="fa fa-additem"></i>
                Add Media
              </span>
            </label>
          </div>
          <Button
            className="uploadBtn"
            onClick={() => {
              uploadSelectedMedia()
              trackEvent('Content Upload', 'Upload From Phone via QR', `${type}`)
            }}
          >
            Upload
          </Button>
        </div>
      </div>
    </>
  )
}

export default QrCode
