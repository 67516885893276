import './PageMemberOnboarding.scss'

import { useRef } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { isMobile } from 'react-device-detect'
import { useDispatch } from 'react-redux'

import { trackEvent } from '../../../Analytics/GA'
import LegacyLogo from '../../../Assets/Images/ButterflyPartneredWLegacyLG.png'
import { useAppSelector } from '../../../Hooks'
import { type pageDetail, type pageDetailData } from '../../../Redux/slices/pageDetailSlice'
import { subScribePage } from '../../../Redux/thunks/pageDetailThunk'
import { updateUserInfo } from '../../../Redux/thunks/settingsThunk'
import CommonShareContent from '../SharePage/CommonShareContent'

function PageMemberOnboardingModal({ open, setOpen }: { open: boolean; setOpen: (param: boolean) => unknown }) {
  const pageDetailState: pageDetail = useAppSelector((state: any) => state.pagedetail)

  const userData: any = useAppSelector((state: any) => state?.user)
  const dispatch = useDispatch()
  const pageData: pageDetailData = pageDetailState?.data

  // const [openStatus, setOpenStatus] = useState<boolean>(open);
  const textEl: any = useRef(null)

  const handleJoin = async () => {
    if (userData?.value?.data?._id) {
      setOpen(false)
      if (pageData?.subscribtion_status === '') {
        await dispatch(
          subScribePage({
            pageId: pageData?._id,
            userId: userData?.value?.data?._id,
          })
        )
        trackEvent('Page Interaction', 'Page Join', `${userData?.value?.data?._id}`)
        // update user object so they don't get shown the member onboarding again
        dispatch(updateUserInfo({ _id: userData?.value?.data?._id, show_member_intro: false }))
      }
      localStorage.setItem('PageMemberOnboarding_popup', 'close')
      const list = document.querySelectorAll(
        'li.DesktopQuestion, li.EventsModule, li.DesktopGallery, button.shareButton, div.CreatePostBtn'
      )
      for (let i = 0; i < list.length; ++i) {
        list[i].classList.remove('PageMemberOnboarding-highlight')
      }
    }
  }

  const handleCloseModal = () => {
    console.log('inside handle close')
    localStorage.setItem('PageMemberOnboarding_popup', 'close')
    setOpen(false)
    const list = document.querySelectorAll(
      'li.DesktopQuestion, li.EventsModule, li.DesktopGallery, button.shareButton, div.CreatePostBtn'
    )
    for (let i = 0; i < list.length; ++i) {
      list[i].classList.remove('PageMemberOnboarding-highlight')
    }
  }

  return (
    <Modal
      show={open}
      className="PageMemberOnboarding-modal"
      backdrop="static"
      onEscapeKeyDown={(e: KeyboardEvent) => {
        e.preventDefault()
      }}
      onHide={handleCloseModal}
    >
      <Modal.Body>
        <span ref={textEl} style={{ fontSize: '0px' }}>
          {window.location.origin + '/' + pageDetailState?.data?.unique_url}
        </span>
        <img className="logo" src={isMobile ? LegacyLogo : LegacyLogo} alt="Logo" />
        <div className="header">
          Welcome to {pageDetailState?.data?.first_name} {pageDetailState?.data?.last_name}&apos;s page
        </div>
        <div className="body">
          Butterfly is an opportunity to celebrate a life with the richness it deserves. Add as much as detail as you
          want – photos, videos, more text. Help build a page that honors a life.
          <br />
          <br />
        </div>
        <div className="body">
          1. Create a post to share your favorite memories of {pageDetailState?.data?.first_name}.
        </div>
        <div className="body">
          2. Share this page, as a way to communicate with others and give them the ability to add to it.
        </div>
        <CommonShareContent hideText={true} />
        <div className="body">3. Answer questions that have been curated by the page owner.</div>
        <div className="body">4. View events related to {pageDetailState?.data?.first_name}.</div>
        <div className="body">4. Check out all the content that has been added to this page in the gallery.</div>
        <div className="joinContainer">
          {/* TODO: Refactor the logic. It should not use async. */}
          {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
          <Button className="joinButton" variant="primary" onClick={handleJoin}>
            Join Now
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default PageMemberOnboardingModal
