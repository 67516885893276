import './Question.scss'

import React, { useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import Slider from 'react-slick'

import { trackEvent } from '../../Analytics/GA'
import { useAppSelector } from '../../Hooks'
import { addPageAction, type pageDetailData, setActiveSideSlug } from '../../Redux/slices/pageDetailSlice'
import { createPost } from '../../Redux/thunks/postThunk'
import Loader from '../Loader/Loader'
import AddDeleteQuestion from '../Modals/AddDeleteQuestion/AddDeleteQuestion'
import CreatePost from '../Modals/CreatePost/CreatePost'

const Question = () => {
  const dispatch = useDispatch()
  const [openModal, setOpenModal] = useState(false)
  const sliderRef = useRef<Slider>(null)
  const [activeIndex, setActiveIndex] = useState(1)
  const [questionNo, setQuestionNo] = useState(0)
  const [from, setFrom] = useState('')
  const pageDetailState: pageDetailData = useAppSelector((state: any) => state.pagedetail.data)
  const userData: any = useAppSelector((state: any) => state?.user)
  const postData: any = useAppSelector((state: any) => state?.post)
  const [questionItem, setQuestionItem] = useState<any>()
  const [totalQuestion, setTotalQuestion] = useState<any>()
  const [answer, setAnswer] = useState('')
  const onCloseModal = () => {
    setOpenModal(false)
  }
  const [show, setShow] = useState(false)
  const [additionalMessage, setAdditionalMessage] = useState<any>(false)
  const [ansPosted, setAnsPosted] = useState<any>(false)

  useEffect(() => {
    setQuestionItem(pageDetailState.questions)
    setTotalQuestion(pageDetailState.questions?.length)
    /* dispatch(
      setShowStepStatus({
        ...userData?.value,
        data: { ...userData?.value?.data, step: "1", showStep:true,legacyStep:true },
      }),
    );  */
  }, [pageDetailState])

  const afterChange = (index: number) => {
    setActiveIndex(index + 1)
    setQuestionNo(index)
  }

  const beforeChange = (oldindex: number, newindex: number) => {
    setAnswer('')
  }

  useEffect(() => {
    if (postData?.postCreateResponse?.success === true) {
      setAnswer('')
      if (userData?.value?.data?.showStep) {
        if (
          userData?.questionAddDeletePopupFlag === null &&
          userData?.value?.data?.step === 4 &&
          userData?.value?.data?.showStep === true
        ) {
          document.getElementById('AddDeleteQCta')?.click()
        } else {
          dispatch(setActiveSideSlug(''))
          dispatch(addPageAction('show-event-steps'))
        }
      } else {
        if (totalQuestion > 1 && !additionalMessage) {
          sliderRef?.current?.slickNext()
        } else if (additionalMessage) {
          setAnsPosted(true)
        }
      }
    }
  }, [postData?.postCreateResponse])

  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    afterChange,
    beforeChange,
  }

  const handleShow = (param: string) => {
    setFrom(param)
    setShow(true)
    if (activeIndex === totalQuestion) {
      setAdditionalMessage(true)
    }
  }

  const answerText = (event: any) => {
    const answerDesc = event.target.value
    setAnswer(answerDesc)
  }

  const canSend = () => {
    return answer.trim() !== ''
  }

  const createQuestion = () => {
    const payload = {
      title: pageDetailState.questions?.length > questionNo && pageDetailState.questions[questionNo].question,
      description: answer.trim(),
      page: pageDetailState?._id,
      medias: [],
      createdBy: userData?.value?.data?._id,
      reactions: [],
      questionId: pageDetailState.questions[questionNo]._id,
    }

    if (activeIndex === totalQuestion) {
      setAdditionalMessage(true)
    }
    console.log('here')
    dispatch(createPost(payload))
    trackEvent('Page Interaction', 'Prompted Post', `${userData?.value?.data?._id}`)
  }

  return (
    <div className="QuestionSlider">
      {userData.loading && <Loader />}

      {ansPosted && additionalMessage ? (
        <div className="question_hint">
          <div>
            <h4>We&apos;ve come full circle!</h4>
            <p>Keep sharing..your stories keep the celebration going!</p>
          </div>
          <button className="Cancel" onClick={() => dispatch(setActiveSideSlug(''))}>
            Cancel
          </button>
          <button
            className="Yes"
            onClick={() => {
              setAdditionalMessage(false)
              setAnsPosted(false)
              setActiveIndex(1)
            }}
          >
            Yes
          </button>
        </div>
      ) : (
        <Slider ref={sliderRef} {...settings} swipe={false}>
          {questionItem?.length > 0 &&
            questionItem.map((q: any, index: number) => {
              return (
                <div key={index}>
                  <h3>{q.question}</h3>
                  <div className="textfield">
                    <textarea
                      rows={3}
                      placeholder="Add your text message"
                      value={answer}
                      onChange={answerText}
                    ></textarea>
                  </div>
                  <div className="IconHolder">
                    <div className="IconsList">
                      <i
                        className="fa fa-butterflymedia"
                        onClick={() => {
                          handleShow('media')
                        }}
                      ></i>
                      <i
                        className="fa fa-videopost"
                        onClick={() => {
                          handleShow('video')
                        }}
                      ></i>
                      <i
                        className="fa fa-butterflymic mr-0"
                        onClick={() => {
                          handleShow('micaudio')
                        }}
                      ></i>
                    </div>
                  </div>
                  <div className="ButtonWrapper">
                    <button
                      onClick={() => {
                        createQuestion()
                      }}
                      disabled={!canSend()}
                    >
                      Post
                    </button>
                  </div>
                </div>
              )
            })}
        </Slider>
      )}
      <div className="controls">
        <button className="button" onClick={() => sliderRef?.current?.slickPrev()}>
          <i className="fa fa-leftarrow"></i>
        </button>
        <span>
          {activeIndex}/{questionItem?.length}
        </span>
        <button className="button" onClick={() => sliderRef?.current?.slickNext()}>
          <i className="fa fa-rightarrow"></i>
        </button>
      </div>
      <div className="AddDeleteCtaWrapper">
        <button
          className="AddDeleteCta"
          id="AddDeleteQCta"
          onClick={() => {
            setOpenModal(true)
            trackEvent('Page Interaction', 'Open Edit Questions', `${userData?.value?.data?._id}`)
          }}
        >
          <i className="fa fa-edit"></i>
          Edit questions
        </button>
      </div>

      {openModal && <AddDeleteQuestion show={openModal} handleClose={onCloseModal} />}

      {show && (
        <CreatePost
          title={pageDetailState.questions?.length > questionNo && pageDetailState.questions[questionNo].question}
          description={answer}
          questionId={pageDetailState?.questions[questionNo]?._id}
          show={show}
          handleClose={() => {
            setShow(false)
            setAdditionalMessage(false)
          }}
          from={from}
          type={'question'}
        />
      )}
    </div>
  )
}

export default Question
