import { S3, S3Client } from '@aws-sdk/client-s3'
import { Upload } from '@aws-sdk/lib-storage'
import { v4 } from 'uuid'

import interceptor from '../Api/Interceptor/index'
import { environment } from '../AppConfig/environment'
import { setLoadItemsDone, setLoadPercentage } from '../Redux/slices/userSlice'
import { store } from '../Redux/store'

const DO_SPACES_KEY = 'DO00RQBN4LHPXBLU8RUN'
const DO_SPACES_SECRET = 'jLldsCQ+q2MHYjccwtEj+yFY0t1qsbVqtzkk3RoQuWk'
const DO_SPACES_ENDPOINT = 'https://nyc3.digitaloceanspaces.com'
const DO_SPACES_REGION = 'nyc3'
const DO_SPACES_BUCKET = 'butterfly-staging-assets'

export const doUpload = async (Body: any, totalItems: number, itemNumber?: number | 0) => {
  console.log('Body Received ', Body)
  const dispatch = store.dispatch
  const mediaId = v4()
  const nameWithoutSpaces = Body.name ? Body.name.split(' ').join('-') : v4() + '-custom.' + Body.type.split('/')[1]
  const noSpecialChars = nameWithoutSpaces.replace(/[^a-zA-Z0-9. ]/g, '-')
  const originalname = noSpecialChars
  const type = Body.type.split('/')[0]
  const newName = `${mediaId}-${originalname}`
  const target = {
    Bucket: DO_SPACES_BUCKET,
    Key: `${type}/${newName}`,
    Body,
    ContentType: Body.type,
    ACL: 'public-read',
  }
  return await new Promise((resolve, reject) => {
    try {
      const parallelUploads3 = new Upload({
        client:
          new S3({
            forcePathStyle: false,
            endpoint: DO_SPACES_ENDPOINT, // "https://sgp1.digitaloceanspaces.com",
            region: DO_SPACES_REGION,
            credentials: {
              accessKeyId: DO_SPACES_KEY as string,
              secretAccessKey: DO_SPACES_SECRET as string,
            },
          }) ||
          new S3Client({
            forcePathStyle: false,
            endpoint: DO_SPACES_ENDPOINT, // "https://sgp1.digitaloceanspaces.com",
            region: DO_SPACES_REGION,
            credentials: {
              accessKeyId: DO_SPACES_KEY as string,
              secretAccessKey: DO_SPACES_SECRET as string,
            },
          }),
        // tags: [...], // optional tags
        queueSize: 8, // optional concurrency configuration
        // partSize: 5MB, // optional size of each part
        // leavePartsOnError: false, // optional manually handle dropped parts
        params: target,
      })

      parallelUploads3.on('httpUploadProgress', (progress: any) => {
        if (totalItems === 1) {
          // console.log("S3 Progress ", progress);
          const { loaded, total } = progress
          const percent = Math.floor((loaded * 100) / total)
          if (percent < 100) {
            console.log(`${loaded} bytes of ${total} bytes. ${percent}%`)
            // let itemsDone = Math.floor((percent * totalItems) / 100);
            // console.log("DONE ", itemsDone);
            // store.dispatch(setLoadItemsDone(itemsDone));
            store.dispatch(setLoadPercentage(percent))
          } else {
            dispatch(setLoadPercentage(100))
          }
        }
        // else {
        //     console.log("S3 Progress ", progress);
        //     const { loaded, total } = progress;
        //     let percent = Math.floor(((loaded * 100) / total!) / totalItems);
        //     if (percent < 100) {
        //         console.log(`${loaded} bytes of ${total} bytes. ${percent}%`);

        //         store.dispatch(setLoadPercentage(percent));
        //     } else {
        //         dispatch(setLoadPercentage(100));
        //     }
        // }
      })

      parallelUploads3
        .done()
        .then(async (r: any) => {
          store.dispatch(setLoadItemsDone(itemNumber))
          if (totalItems > 1) {
            const grain = 100 / totalItems
            const loaderInfo = store.getState()
            store.dispatch(setLoadPercentage(loaderInfo.user.loadPercentage + grain))
          }

          const mimetype = Body.type
          console.log('DO UPLOAD SUCCESS ', r)
          //  call api for cloud convert

          const data = {
            results: [
              {
                filename: `${mediaId}-${originalname}`,
                thumbnail: '',
                url: !r.Location.includes('https') ? `https://${r.Location}` : `${r.Location}`,
                originalname,
                mediaId,
                mimetype,
                type: mimetype.split('/')[0] || Body.type,
              },
            ],
          }
          const pageId = store.getState().pagedetail.data._id
          const token = store.getState().user?.value?.token
          const config = {
            method: 'put',
            maxBodyLength: Infinity,
            url: pageId
              ? `${environment.apiEndPoint}media/convert/cloud/${pageId}`
              : `${environment.apiEndPoint}media/convert/cloud`,
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`,
            },
            data,
          }
          const convertResult = await interceptor.put(config.url, data)
          console.log('CLOUD CONV API ', convertResult)
          resolve(convertResult.data)
        })
        .catch(e => {
          reject(e)
        })
    } catch (e) {
      /* console.log(e); */
    }
  })
}
