import './UserGuide.scss'

const butterflyGreenLogo =
  'https://butterflyv2-production-assets.nyc3.digitaloceanspaces.com/image/0be4f6c7-33c1-4f6e-a3ec-7e29adab04be-butterfly-green.svg'

const UserGuidecreatePost = () => {
  return (
    <div>
      <p>This is where you share your favorite stories, best memories, and anything else.</p>

      <span>
        <i className="fa fa-tip"></i>
        <strong>TIP: </strong>&nbsp;Add photos, videos, or audio to your posts to make them stand out.
      </span>
    </div>
  )
}
const UserGuidePinPost = () => {
  return (
    <div>
      <p>You can pin your posts by clicking or tapping on this pin icon.</p>
      <span>
        <i className="fa fa-tip"></i>
        <strong>TIP: </strong>&nbsp;Can&apos;t decide? No worries, you can pin as many posts as you want.
      </span>
    </div>
  )
}
const UserGuideQuestion = () => {
  return (
    <div>
      <p>
        Take a beat, answer them, and then publish when you&apos;re ready. By sharing with your friends and family you
        will inspire them to post meaningful experiences, reflections, and dreams.
      </p>

      <span>
        <i className="fa fa-tip"></i>
        <strong>TIP: </strong>&nbsp;Personalized questions elicit the best responses.
      </span>
    </div>
  )
}
const UserGuideEvents = () => {
  return (
    <div>
      <p>
        Butterfly is about the past, present and future. This is the place to share upcoming events, and invite family
        and friends..
      </p>

      <span>
        <i className="fa fa-tip"></i>
        <strong>TIP: </strong>&nbsp;You can create as many events as you need.
      </span>
    </div>
  )
}
const UserGuideSubscribe = () => {
  return (
    <div>
      <p>Please join to contribute on this page.</p>

      <span>
        <i className="fa fa-tip"></i>
        <strong>TIP: </strong>&nbsp;You can leave and rejoin anytime
      </span>
    </div>
  )
}
const UserGuideSubscribePending = () => {
  return (
    <div>
      <p>Please wait for approval from the page owner.</p>
    </div>
  )
}
const UserGuidePublishPost = () => {
  return (
    <div>
      <p>When your page is ready, click or tap on this icon to make it official.</p>

      <span>
        <i className="fa fa-tip"></i>
        <strong>TIP: </strong>&nbsp;Publish your page so that others can join and contribute.
      </span>
    </div>
  )
}
const UserGuideSharePost = () => {
  return (
    <div className="text-center">
      <img src={butterflyGreenLogo} width={49} height={45} alt="" />
      <h4>You’ve Been Launched on Butterfly!</h4>
      <p>Your page has been published. Share it with friends and family by clicking share.</p>
    </div>
  )
}

const UserGuideEditPost = () => {
  return (
    <div>
      <p>Edit your post by clicking on the three dots icon and selecting Edit from the drop down menu.</p>

      <span>
        <i className="fa fa-tip"></i>
        <strong>TIP: </strong>&nbsp;TIP: You can also add multiple media types at a time.
      </span>
    </div>
  )
}

const UserGuideAddQuestion = () => {
  return (
    <div>
      <p>Create your own custom questions that you would like your audience to answer.</p>

      <span>
        <i className="fa fa-tip"></i>
        <strong>TIP: </strong>&nbsp;TIP: You can add or delete questions.
      </span>
    </div>
  )
}

export {
  UserGuideAddQuestion,
  UserGuidecreatePost,
  UserGuideEditPost,
  UserGuideEvents,
  UserGuidePinPost,
  UserGuidePublishPost,
  UserGuideQuestion,
  UserGuideSharePost,
  UserGuideSubscribe,
  UserGuideSubscribePending,
}
