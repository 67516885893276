import 'intro.js/introjs.css'
import './LayoutContainer.scss'

import cn from 'classnames'
import { useEffect, useState } from 'react'
import { Container } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { logoNoText } from '../../commonservice'
import { useAppSelector } from '../../Hooks'
import { setAppError, setPopup, setSidePanel } from '../../Redux/slices/metaSlice'
import { pageReturnAction, setActiveSideSlug } from '../../Redux/slices/pageDetailSlice'
import AddDeleteQuestion from '../Modals/AddDeleteQuestion/AddDeleteQuestion'
import AlertPopup from '../Modals/AlertPopup/AlertPopup'
import DeletePopup from '../Modals/DeletePopup'
import { NavComponent } from '../Nav'
import QuestionsPopup from '../QuestionsPopup'
import SidePanel from '../SidePanel/SidePanel'
import UserGuideStepper from '../UserGuide/UserGuideStepper'

export function LayoutContainer(props: any) {
  const navigate = useNavigate()
  const [, setIsActive] = useState(false)
  const [deleteshow, setDeleteshow] = useState(false)
  const handleDeletesPopupClose = () => {
    setDeleteshow(false)
  }
  const dispatch = useDispatch()
  const [deleteType, setDeleteType] = useState('page')

  const appMeta = useAppSelector(state => state.metadata)
  const pageDetailState = useAppSelector((state: any) => state.pagedetail)
  const pageDetailReturnAction = pageDetailState?.returnAction
  const pageData = pageDetailState.data
  const [cantScroll, setCantScroll] = useState(false)

  const handleClose = (e?: any) => {
    setIsActive(current => !current)
    dispatch(
      setSidePanel({
        panelBody: null,
        status: false,
      })
    )
    setTimeout(() => {
      dispatch(setActiveSideSlug(''))
    }, 400)
  }

  useEffect(() => {
    if (pageDetailReturnAction === 'back-to-mypage') {
      dispatch(pageReturnAction(''))
      navigate('/mypages')
    }
  }, [pageDetailReturnAction])

  return (
    <>
      {appMeta.error?.status && (
        <AlertPopup
          buttonText={false}
          show={!!appMeta.error.status}
          content={appMeta.error.content}
          state={appMeta.error.state}
          onHide={() => {
            dispatch(setAppError(null))
          }}
        />
      )}
      <section
        id="Layout"
        className={cn('', {
          'scroll-not-allowd': cantScroll,
        })}
      >
        <Container>
          <div className="layoutwrapper">
            <aside>
              <div className="logo">
                <img
                  onClick={() => {
                    navigate('/mypages')
                    handleClose()
                  }}
                  src={logoNoText}
                  alt=""
                />
              </div>
              <NavComponent handleClose={() => {}} />
            </aside>
            <main>
              <div className="MainSection">{props.children}</div>
            </main>

            {(() => {
              switch (appMeta?.popupType) {
                case 'question-popup':
                  return <QuestionsPopup />
                case 'question-add-popup':
                  return <AddDeleteQuestion show={true} handleClose={() => dispatch(setPopup('question-popup'))} />
                default:
                  return null
              }
            })()}
          </div>
        </Container>
        <SidePanel
          Active={appMeta.sidepanel.status}
          handleClose={() => {
            handleClose()
          }}
          setDeleteshow={setDeleteshow}
          setDeleteType={setDeleteType}
        />
      </section>

      <UserGuideStepper
        setCantScroll={(data: boolean) => {
          setCantScroll(data)
        }}
      />

      {deleteshow ? (
        <DeletePopup
          deleteshow={deleteshow}
          pageid={pageData?._id}
          handleclose={() => {
            handleDeletesPopupClose()
          }}
          deleteType={deleteType}
          mediadata={pageData}
          albumdata={{}}
        />
      ) : (
        <></>
      )}
    </>
  )
}
