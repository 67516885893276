import './MessageBoard.scss'

import axios from 'axios'
import cn from 'classnames'
import Picker, { Emoji } from 'emoji-picker-react'
import { createRef, useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import TextareaAutosize from 'react-textarea-autosize'
import * as Realm from 'realm-web'

import { trackEvent } from '../../Analytics/GA'
import { environment } from '../../AppConfig/environment'
import { socket } from '../../AppConfig/socket'
import { createdDateAndByMonthWithoutNth, formatAMPM, isset, subcommenttimeSince } from '../../commonservice'
import { useAppSelector } from '../../Hooks'
import {
  appendNewMessage,
  appendNewReaction,
  appendNewReply,
  appendNewReplyReaction,
  loadMsgBoardMessages,
} from '../../Redux/slices/messageBoardSlice'
import { addPageAction } from '../../Redux/slices/pageDetailSlice'
import { store } from '../../Redux/store'
import { loadMessagesSilentlyThunk, loadMessagesThunk } from '../../Redux/thunks/MessageBoardThunk'
import { pageGetPersonalMessageThunk } from '../../Redux/thunks/pageDetailThunk'
import ReactionListPopup from '../Modals/ReactionListPopup/ReactionListPopup'
import AudioWaves from '../Shared/AudioWaves'

const RealApp = new Realm.App({ id: `${environment.RealmAppId}` })

function MessageBoard(props: any) {
  const dispatch = useDispatch()
  const messagesEndRef: any = useRef(null)
  const messageBoardState: any = useAppSelector(state => state.messageboard)
  const userState: any = useAppSelector(state => state.user)
  const pageData: any = useAppSelector((state: any) => state?.pagedetail)
  const pageDetailState = pageData?.data
  const pagePersonalMessageState = pageData?.personalMessage
  const inputRef = createRef<any>()
  const [activeReact, setActiveReact] = useState<any>(null)
  const generateString = (length: number) => {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'
    let result = ' '
    const charactersLength = characters.length
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength))
    }
    return result
  }
  const [LoggedInUserInfo, setLoggedInUserInfo] = useState({
    id: Math.floor(Math.random() * 1000),
    username: generateString(7),
    image: null,
  })

  const [reactionModalShow, setReactionModalShow] = useState(false)

  const [activeReaction, setActiveReaction] = useState<any>([])
  const [totalMessageCount, setTotalMessageCount] = useState(0)
  const [newMessage, setNewMessage] = useState('')
  const [showEmojiPicker, setShowEmojiPicker] = useState(false)

  const [toggle, setToggle] = useState(false)
  const [activeReply, setActiveReply] = useState<any>({})
  const [reactionType, setReactionType] = useState('')
  const [activeTab, setActiveTab] = useState(props.type)
  const [personalMessageList, setPersonalMessageList] = useState([])

  const skeleton = messageBoardState.skeleton

  const showPrivateMessages = false

  useEffect(() => {
    if (activeTab === 'personal') {
      // dispatch(pageGetPersonalMessageThunk(pageDetailState?._id));
    }
    dispatch(pageGetPersonalMessageThunk(pageDetailState?._id))
  }, [])

  useEffect(() => {
    setPersonalMessageList(pagePersonalMessageState)
  }, [pagePersonalMessageState])

  const isBlur = () => {
    return (
      pageDetailState.privacy_status === 'PRIVATE' &&
      pageDetailState?.belongsTo?._id !== userState?.value?.data?._id &&
      pageDetailState?.subscribtion_status !== 'APPROVED'
    )
  }

  const sendNewMsg = () => {
    if (!toggle) {
      const message = newMessage
      socket.emit('messageSend', {
        messageBoard: messageBoardState?.data?._id,
        message,
        belongsTo: LoggedInUserInfo.id,
      })
      trackEvent('Page Interaction', 'Message Board Post', `${userState?.value?.data?._id}`)
      setNewMessage('')
    } else {
      const message = newMessage
      const token = store.getState().user?.value?.token
      socket.emit('replySend', {
        messageBoard: messageBoardState?.data?._id,
        message: activeReply._id,
        createdBy: LoggedInUserInfo.id,
        reply: message,
        token,
      })
      trackEvent('Page Interaction', 'Message Board Post Reply', `${userState?.value?.data?._id}`)
      setNewMessage('')
    }
  }

  const onEmojiClick = (event: any, emojiObject: any) => {
    const reactionExists = activeReact?.reactions?.find(
      (react: any) => react?.createdBy?._id === userState?.value?.data?._id
    )

    if (reactionType === 'message') {
      setNewMessage(newMessage + ' ' + event.emoji)
    } else if (reactionType === 'messagereact') {
      // whether user can add reaction
      // check for reaction exists

      if (reactionExists) {
        // edit reaction
        const data = JSON.stringify({
          name: event.unified,
        })

        const config = {
          method: 'put',
          maxBodyLength: Infinity,
          url: `${environment.apiEndPoint}page/post/comment/react/${reactionExists._id}`,
          headers: {
            'Content-Type': 'application/json',
          },
          data,
        }

        axios
          .request(config)
          .then(response => {
            // this is bad...should change the store rather than loading messages again
            socket.emit('messageBoardRefresh', {
              messageBoard: messageBoardState?.data?._id,
            })
          })
          .catch(() => {})
      } else {
        const activeElement = activeReact
        const data = JSON.stringify({
          message: activeElement?._id,
          createdBy: LoggedInUserInfo.id,
          name: event.unified,
        })
        const token = store.getState().user?.value?.token
        const config = {
          method: 'post',
          maxBodyLength: Infinity,
          url: `${environment.apiEndPoint}page/post/comment/react`,
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          data,
        }

        axios(config)
          .then((response: any) => {
            socket.emit('messageReactionSend', {
              messageBoard: messageBoardState?.data?._id,
              reaction: response.data.data,
            })
            trackEvent('Page Interaction', 'Message Board Post Reaction', `${userState?.value?.data?._id}`)
          })
          .catch(() => {})
      }
    } else if (reactionType === 'replyreact') {
      if (reactionExists) {
        // edit reaction
        console.log('Reaction exists ', reactionExists)
        const data = JSON.stringify({
          name: event.unified,
        })

        const config = {
          method: 'put',
          maxBodyLength: Infinity,
          url: `${environment.apiEndPoint}page/post/comment/react/${reactionExists._id}`,
          headers: {
            'Content-Type': 'application/json',
          },
          data,
        }

        axios
          .request(config)
          .then(response => {
            // this is bad...should change the store rather than loading messages again
            socket.emit('messageBoardRefresh', {
              messageBoard: messageBoardState?.data?._id,
            })
          })
          .catch(() => {})
      } else {
        const activeElement = activeReact
        const data = JSON.stringify({
          messageReply: activeElement?._id,
          createdBy: LoggedInUserInfo.id,
          name: event.unified,
        })
        const token = store.getState().user?.value?.token
        const config = {
          method: 'post',
          maxBodyLength: Infinity,
          url: `${environment.apiEndPoint}page/post/comment/react`,
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          data,
        }

        axios(config)
          .then((response: any) => {
            socket.emit('reaplyReactionSend', {
              messageBoard: messageBoardState?.data?._id,
              reaction: response.data.data,
            })
            trackEvent('Page Interaction', 'Message Board Reply Reaction', `${userState?.value?.data?._id}`)
          })
          .catch(() => {})
      }
    }
    setReactionType('')
    setShowEmojiPicker(false)
  }

  const scrollToBottom = () => {
    const scrollHeight: any = messagesEndRef?.current?.scrollHeight
    const height = messagesEndRef?.current?.clientHeight
    if (scrollHeight && height) {
      const maxScrollTop = scrollHeight - height
      messagesEndRef.current.scrollTop = maxScrollTop > 0 ? maxScrollTop : 0
    }
  }

  const groupBy = (key: string) => {
    return function group(array: any) {
      return array?.reduce((acc: any, obj: any) => {
        const property = obj[key]
        acc[property] = acc[property] || []
        acc[property].push(obj)
        return acc
      }, {})
    }
  }

  const groupByReactions = groupBy('name')

  const getReactionsArray = (Reactions: any) => {
    return groupByReactions(Reactions)
  }

  const canAction = () => {
    if (
      pageData?.data?.belongsTo?._id === userState?.value?.data?._id ||
      pageData?.data?.subscribtion_status === 'APPROVED'
    ) {
      return true
    } else {
      return false
    }
  }

  useEffect(() => {
    const messageReceiveCallback = (value: any) => {
      dispatch(appendNewMessage({ ...value, reactions: [] }))
      setNewMessage('')
      setTimeout(() => {
        scrollToBottom()
      }, 150)
    }

    const replyReceiveCallback = (value: any) => {
      dispatch(appendNewReply(value))
      setNewMessage('')
      setToggle(false)
    }

    const replyReactionReceiveCallback = (value: any) => {
      dispatch(appendNewReplyReaction(value.reaction))
    }

    const messageReactionReceiveCallback = (value: any) => {
      dispatch(appendNewReaction(value.reaction))
    }

    const allMessagesReceiveCallback = (value: any) => {
      dispatch(loadMessagesSilentlyThunk({ pageId: pageData?.data?._id }))
    }

    socket.on('messageReceive' + messageBoardState?.data?._id, messageReceiveCallback)
    socket.on('replyReceive' + messageBoardState?.data?._id, replyReceiveCallback)
    socket.on('replyReactionReceive' + messageBoardState?.data?._id, replyReactionReceiveCallback)
    socket.on('messageReactionReceive' + messageBoardState?.data?._id, messageReactionReceiveCallback)
    socket.on('messageBoardRefreshReceive' + messageBoardState?.data?._id, allMessagesReceiveCallback)
    return () => {
      socket.on('messageBoardRefreshReceive' + messageBoardState?.data?._id, allMessagesReceiveCallback)
      socket.on('replyReactionReceive' + messageBoardState?.data?._id, replyReactionReceiveCallback).off()
      socket.on('messageReactionReceive' + messageBoardState?.data?._id, messageReactionReceiveCallback).off()
      socket.off('messageReceive' + messageBoardState?.data?._id, messageReceiveCallback).off()
      socket.off('replyReceive' + messageBoardState?.data?._id, replyReceiveCallback).off()
    }
  })

  useEffect(() => {
    dispatch(
      loadMsgBoardMessages({
        data: { totalCount: 0 },
        totalCount: 0,
      })
    )
    const pageId = pageData?.data?._id

    if (!isBlur()) {
      dispatch(loadMessagesThunk({ pageId }))
    }

    if (userState?.value?.data) {
      setLoggedInUserInfo({
        id: userState?.value?.data?._id,
        username: userState?.value?.data?.name,
        image: userState?.value?.data?.image,
      })
    }
    setTimeout(() => {
      scrollToBottom()
    }, 150)
  }, [pageData?.data?._id])

  useEffect(() => {
    scrollToBottom()
  }, [])

  useEffect(() => {
    if (messageBoardState?.data) {
      setTotalMessageCount(messageBoardState?.data?.messages?.length)
    }
  }, [messageBoardState?.data])

  const [screenSize, getDimension] = useState({
    dynamicHeight: window.innerHeight,
    dynamicOHeight: window.outerHeight,
    dynamicWidth: window.innerWidth,
  })

  const setDimension = () => {
    getDimension({
      ...screenSize,
      dynamicHeight: window.innerHeight,
    })
  }
  useEffect(() => {
    window.addEventListener('resize', setDimension)

    return () => {
      window.removeEventListener('resize', setDimension)
    }
  }, [])

  // Realtime private messages
  useEffect(() => {
    const personalMessageStreamlogin = async () => {
      // Authenticate anonymously
      const mongodb = RealApp.currentUser?.mongoClient('mongodb-atlas')
      if (!mongodb) return
      const collection = mongodb.db(`${environment.mongodbStream}`).collection('personalmessages')

      for await (const change of collection.watch({
        page: pageDetailState?._id,
      })) {
        // @ts-expect-error change.fullDocument
        if (change.fullDocument.page.toString() === pageDetailState?._id) {
          console.log('private message change ', change)
          dispatch(pageGetPersonalMessageThunk(pageDetailState?._id))
        }
      }
    }
    personalMessageStreamlogin()
  }, [pageDetailState])

  return (
    <>
      <section
        id="MessageBoard"
        className={pageDetailState?.counts?.fundraiser ? 'withFundraiser' : ''}
        style={{
          height: `${screenSize.dynamicHeight - 95}px`,
        }}
      >
        <div id="MessageBoard-header">
          <div
            className={cn('', {
              active:
                pageData?.data?.belongsTo?._id === userState?.value?.data?._id &&
                activeTab === 'message' &&
                showPrivateMessages,
              default:
                pageData?.data?.belongsTo?._id !== userState?.value?.data?._id ||
                (pageData?.data?.belongsTo?._id === userState?.value?.data?._id && !showPrivateMessages),
            })}
            onClick={() => {
              setActiveTab('message')
            }}
          >
            Message Board <span>({totalMessageCount})</span>
          </div>
          {pageData?.data?.belongsTo?._id === userState?.value?.data?._id && showPrivateMessages && (
            <>
              <div
                className={cn('', {
                  active: activeTab === 'personal',
                })}
                onClick={() => {
                  setActiveTab('personal')
                }}
              >
                Private Messages <span>({personalMessageList?.length})</span>
              </div>
            </>
          )}
        </div>
        {activeTab === 'message' ? (
          <>
            <div className="chat-wrapper" ref={messagesEndRef}>
              {messageBoardState?.data?.messages?.length > 0 ? (
                messageBoardState?.data?.messages?.map((element: any, key: any) => {
                  return element?.replylist?.length > 0 ? (
                    <div className="message-wrapper" key={'main-message-' + key}>
                      <div className="d-flex w-100">
                        {isset(element?.belongsTo.image) ? (
                          <img className="message-pp" src={element?.belongsTo.image} alt="profile-pic" />
                        ) : (
                          <div className="ownerAlpha">
                            <span>{element?.belongsTo.name?.charAt(0)}</span>
                          </div>
                        )}
                        <div className="message-box-wrapper">
                          <div className="title-box">
                            <span className="name">
                              {element?.belongsTo.name === 'Team Butterfly' && element?.message.indexOf('- ') > 0
                                ? element?.message.substring(
                                    element?.message.lastIndexOf('- ') + 2,
                                    element?.message.length
                                  )
                                : element?.belongsTo.name}
                            </span>
                            <span className="time">{subcommenttimeSince(element?.createdAt)} ago</span>
                          </div>
                          <div className="message-box">
                            {element?.belongsTo.name === 'Team Butterfly' && element?.message.indexOf('- ') > 0
                              ? element?.message.substring(0, element?.message.lastIndexOf('- '))
                              : element?.message}
                          </div>
                          <div className="footer">
                            <div className="d-flex align-items-center">
                              <span
                                onClick={() => {
                                  if (canAction()) {
                                    setActiveReply(element)
                                    setToggle(!toggle)
                                    inputRef.current.focus()
                                  } else {
                                    dispatch(addPageAction('show-subsciption-step'))
                                  }
                                }}
                                className="reply"
                              >
                                Reply
                              </span>
                              <span
                                className="addreaction"
                                onClick={() => {
                                  if (canAction()) {
                                    setShowEmojiPicker(!showEmojiPicker)
                                    setReactionType('messagereact')
                                    setActiveReact(element)
                                  } else {
                                    dispatch(addPageAction('show-subsciption-step'))
                                  }
                                }}
                              >
                                <i className="fa fa-butterflyaddreaction"></i>
                              </span>
                            </div>
                            {element?.reactions?.length ? (
                              <div
                                onClick={() => {
                                  setReactionModalShow(true)
                                  setActiveReaction({
                                    allReaction: element?.reactions,
                                    reactionGroup: getReactionsArray(element?.reactions),
                                  })
                                }}
                                className="ctas"
                              >
                                <span>
                                  {Object.keys(getReactionsArray(element?.reactions)).map(
                                    (reactionKey: any, index: any) => {
                                      if (index < 4) {
                                        return <Emoji key={index} unified={reactionKey} size={18} />
                                      }
                                      return null
                                    }
                                  )}
                                  {element?.reactions?.length}
                                </span>
                              </div>
                            ) : (
                              ''
                            )}
                          </div>
                        </div>
                      </div>
                      {element?.replylist.map((reply: any, rkey: any) => {
                        return (
                          <div key={'reply-message-' + rkey} className="message-wrapper-reply">
                            {isset(reply?.createdBy?.image) ? (
                              <img className="message-pp" src={reply?.createdBy?.image} alt="profile-pic" />
                            ) : (
                              <div className="ownerAlpha">
                                <span>{reply?.createdBy?.name.charAt(0)}</span>
                              </div>
                            )}
                            <div className="message-box-wrapper">
                              <div className="title-box">
                                <span className="name">{reply.createdBy?.name}</span>
                                <span className="time">{subcommenttimeSince(reply?.createdAt)} ago</span>
                              </div>
                              <div className="message-box">{reply.reply}</div>
                              <div className="footer">
                                <div className="d-flex align-items-center">
                                  {/* ADD REACTION FOR REPLY LOGIC HERE  */}
                                  <span
                                    className="addreaction"
                                    onClick={() => {
                                      if (canAction()) {
                                        setShowEmojiPicker(!showEmojiPicker)
                                        setReactionType('replyreact')
                                        setActiveReact(reply)
                                      } else {
                                        dispatch(addPageAction('show-subsciption-step'))
                                      }
                                    }}
                                  >
                                    <i className="fa fa-butterflyaddreaction"></i>
                                  </span>
                                </div>
                                {reply?.reactions?.length ? (
                                  <div
                                    onClick={() => {
                                      setReactionModalShow(true)
                                      setActiveReaction({
                                        allReaction: reply?.reactions,
                                        reactionGroup: getReactionsArray(reply?.reactions),
                                      })
                                    }}
                                    className="ctas"
                                  >
                                    <span>
                                      {Object.keys(getReactionsArray(reply?.reactions)).map(
                                        (reactionKey: any, index: any) => {
                                          if (index < 4) {
                                            return <Emoji key={index} unified={reactionKey} size={18} />
                                          }
                                          return null
                                        }
                                      )}
                                      {reply?.reactions?.length}
                                    </span>
                                  </div>
                                ) : (
                                  ''
                                )}
                              </div>
                            </div>
                          </div>
                        )
                      })}
                    </div>
                  ) : (
                    <div key={key} className="message-wrapper">
                      {isset(element?.belongsTo.image) ? (
                        <img className="message-pp" src={element?.belongsTo.image} alt="profile-pic" />
                      ) : (
                        <div className="ownerAlpha">
                          <span>{element?.belongsTo.name.charAt(0)}</span>
                        </div>
                      )}
                      <div className="message-box-wrapper">
                        {element?.belongsTo._id !== LoggedInUserInfo.id ? (
                          <div className="title-box">
                            <span className="name">
                              {element?.belongsTo.name === 'Team Butterfly' && element?.message.indexOf('- ') > 0
                                ? element?.message.substring(
                                    element?.message.lastIndexOf('- ') + 2,
                                    element?.message.length
                                  )
                                : element?.belongsTo.name}
                            </span>
                            <span className="time">{subcommenttimeSince(element?.createdAt)} ago</span>
                          </div>
                        ) : (
                          <div className="title-box">
                            <span className="name">
                              {element?.belongsTo.name === 'Team Butterfly' && element?.message.indexOf('- ') > 0
                                ? element?.message.substring(
                                    element?.message.lastIndexOf('- ') + 2,
                                    element?.message.length
                                  )
                                : element?.belongsTo.name}
                            </span>
                            <span className="time">{subcommenttimeSince(element?.createdAt)} ago</span>
                          </div>
                        )}
                        <div className="message-box">
                          {element?.belongsTo.name === 'Team Butterfly' && element?.message.indexOf('- ') > 0
                            ? element?.message.substring(0, element?.message.lastIndexOf('- '))
                            : element?.message}
                        </div>
                        <div className="footer">
                          <div className="d-flex align-items-center">
                            <span
                              onClick={() => {
                                if (canAction()) {
                                  setActiveReply(element)
                                  setToggle(!toggle)
                                  inputRef.current.focus()
                                } else {
                                  dispatch(addPageAction('show-subsciption-step'))
                                }
                              }}
                              className="reply"
                            >
                              Reply
                            </span>
                            <span
                              className="addreaction"
                              onClick={() => {
                                if (canAction()) {
                                  setShowEmojiPicker(!showEmojiPicker)
                                  setReactionType('messagereact')
                                  setActiveReact(element)
                                } else {
                                  dispatch(addPageAction('show-subsciption-step'))
                                }
                              }}
                            >
                              <i className="fa fa-butterflyaddreaction"></i>
                            </span>
                          </div>
                          {element?.reactions?.length ? (
                            <div
                              onClick={() => {
                                setReactionModalShow(true)
                                setActiveReaction({
                                  allReaction: element?.reactions,
                                  reactionGroup: getReactionsArray(element?.reactions),
                                })
                              }}
                              className="ctas"
                            >
                              <span>
                                {Object.keys(getReactionsArray(element?.reactions)).map(
                                  (reactionKey: any, index: any) => {
                                    if (index < 4) {
                                      return <Emoji key={index} unified={reactionKey} size={18} />
                                    }
                                    return null
                                  }
                                )}
                                {element?.reactions?.length}
                              </span>
                            </div>
                          ) : (
                            <></>
                          )}
                        </div>
                      </div>
                    </div>
                  )
                })
              ) : isBlur() ? (
                <div className="empty-message-board">
                  <i className="fa fa-butterflyeyehidden"></i>
                  <span>Sorry, but this page is private.</span>To view it you need to join and get approved.
                </div>
              ) : (
                <div className="empty-message-board">
                  {!skeleton && (
                    <>
                      <span>Get people talking!</span>
                      Share your thoughts with everyone who is a member of your page. You can also reply to others and
                      join the conversation.
                    </>
                  )}
                  {skeleton && <div className="skeleton"></div>}
                </div>
              )}
            </div>
            {toggle && (
              <div className="reply-box">
                <div className="reply-wrapper">
                  <div className="header">
                    <div className="title">Replying to {activeReply.belongsTo.name}</div>
                    <span
                      className="close-btn"
                      onClick={() => {
                        setActiveReply({})
                        setToggle(!toggle)
                      }}
                    >
                      <i className="fa fa-butterflynoapproval"></i>
                    </span>
                  </div>
                  <div className="message">{activeReply.message}</div>
                </div>
              </div>
            )}

            <div className="chat-input-wrapper">
              <div className="input-wrapper">
                <TextareaAutosize
                  id="message-input"
                  className="chat-input"
                  placeholder="Add message"
                  value={newMessage}
                  rows={1}
                  ref={inputRef}
                  onKeyDown={event => {
                    if (event.key === 'Enter' && !event.shiftKey) {
                      event.preventDefault()
                      if (canAction()) {
                        sendNewMsg()
                      }
                      return false
                    }
                  }}
                  onChange={(e: any) => {
                    setNewMessage(e?.target.value)
                  }}
                />
                <button
                  className="emoji-btn"
                  type="button"
                  onClick={() => {
                    if (canAction()) {
                      setShowEmojiPicker(!showEmojiPicker)
                      setReactionType('message')
                    }
                  }}
                >
                  <i className="fa fa-butterflysmiley"></i>
                </button>
                <button
                  className="chat-send-btn"
                  type="button"
                  disabled={newMessage === ''}
                  onClick={() => {
                    if (canAction()) {
                      sendNewMsg()
                    }
                  }}
                >
                  <i className="fa fa-butterflysend"></i>
                </button>
                {!canAction() && (
                  <div
                    onClick={() => {
                      dispatch(addPageAction('show-subsciption-step'))
                    }}
                    className="message-cant-send"
                  ></div>
                )}
              </div>
              {showEmojiPicker ? (
                <div>
                  <p
                    onClick={() => {
                      setShowEmojiPicker(false)
                    }}
                  >
                    &#x2715;
                  </p>
                  <Picker autoFocusSearch={screenSize.dynamicWidth > 800} onEmojiClick={onEmojiClick} />
                </div>
              ) : (
                ''
              )}
            </div>
          </>
        ) : (
          <>
            <div className="chat-wrapper phoneMessage" ref={messagesEndRef}>
              {personalMessageList?.length ? (
                <>
                  {personalMessageList?.map((pmessage: any, index: number) => {
                    return (
                      <div key={index} className="message-wrapper phoneMessagewraapper">
                        <div className="d-flex w-100">
                          <div className="message-box-wrapper">
                            <div className="title-box">
                              <span className="name">
                                {createdDateAndByMonthWithoutNth(pmessage?.createdAt)} |{' '}
                                {formatAMPM(pmessage?.createdAt)}
                              </span>
                            </div>
                            <div className="message-box">{pmessage?.body}</div>
                            {pmessage?.type === 'image' ? (
                              <div className="ImgWrapper">
                                <img src={pmessage?.media} />
                              </div>
                            ) : pmessage?.type === 'audio' ? (
                              <div className="audioMessageWrapper">
                                <AudioWaves audioURL={pmessage?.media} stopAllMedia={() => {}} mediaPlay={false} />
                              </div>
                            ) : (
                              <></>
                            )}
                          </div>
                        </div>
                      </div>
                    )
                  })}
                </>
              ) : (
                <></>
              )}
              {!personalMessageList?.length && (
                <div className="empty-message-board">
                  Share your messages and voicemails from your phone by texting them to{' '}
                  <span>&quot;{environment.twilioNumber}&quot;. </span>
                  (Start your message with &quot;<strong>{pageDetailState?.unique_url}</strong>:&quot;)
                </div>
              )}
            </div>
            {personalMessageList?.length ? (
              <div className="empty-message-board outside">
                Share your messages and voicemails from your phone by texting them to{' '}
                <span>&quot;{environment.twilioNumber}&quot;. </span>
                (Start your message with &quot;<strong>{pageDetailState?.unique_url}</strong>:&quot;)
              </div>
            ) : (
              <></>
            )}
          </>
        )}
      </section>
      {reactionModalShow && (
        <ReactionListPopup
          show={reactionModalShow}
          handleClose={() => {
            setReactionModalShow(false)
          }}
          activeReaction={activeReaction}
          type={'message'}
          messageBoard={messageBoardState?.data?._id}
        />
      )}
    </>
  )
}

export default MessageBoard
