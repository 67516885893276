import '../CreatePost/CreatePost.scss'

import axios, { type AxiosRequestConfig } from 'axios'
import cn from 'classnames'
import Picker from 'emoji-picker-react'
import Lottie from 'lottie-react'
import React, { useEffect, useRef, useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import { isMobile, isSafari } from 'react-device-detect'
import DropboxChooser from 'react-dropbox-chooser'
import useDrivePicker from 'react-google-drive-picker'
import { ReactMediaRecorder } from 'react-media-recorder'
import { QRCode } from 'react-qrcode-logo'
import { useDispatch } from 'react-redux'

import { trackEvent } from '../../../Analytics/GA'
import { environment } from '../../../AppConfig/environment'
import { socket } from '../../../AppConfig/socket'
import * as animationData from '../../../Assets/Lottie/audio-lottie.json'
import { dropbox, folder, GDrive, isset, phone } from '../../../commonservice'
import { useAppSelector } from '../../../Hooks'
import { doUpload } from '../../../Hooks/doUpload'
import { setAppError } from '../../../Redux/slices/metaSlice'
import { addPageAction } from '../../../Redux/slices/pageDetailSlice'
import { loading, setLoadItemsDone, setLoadPercentage, setShowStepStatus } from '../../../Redux/slices/userSlice'
import { store } from '../../../Redux/store'
import { createPost, updatePost } from '../../../Redux/thunks/postThunk'
import { updateUserInfo } from '../../../Redux/thunks/settingsThunk'
import Loader from '../../Loader/Loader'
import AudioWaves from '../../Shared/AudioWaves'
import PhoneInputWithCode from '../../Shared/PhoneInput'

const VideoPreview = (data: any) => {
  const stream: any = data?.stream
  const videoRef: any = useRef(null)

  useEffect(() => {
    if (videoRef?.current && stream) {
      videoRef.current.srcObject = stream
    }
  }, [stream])
  if (!stream) {
    return null
  }
  return <video ref={videoRef} height={300} width={470} autoPlay />
}

const CreatePost = (props: any) => {
  const dispatch = useDispatch()
  const stopVideo: any = useRef(null)
  const { isMeidaPost } = props

  const userData: any = useAppSelector((state: any) => state?.user)
  const pageData: any = useAppSelector((state: any) => state?.pagedetail)
  const postData: any = useAppSelector((state: any) => state?.post)

  const [openPicker, authResponse] = useDrivePicker()
  const [ph, setPh] = useState('')
  const [from, setFrom] = useState('description')
  const [isMediaPhone, setIsMediaPhone] = useState(false)
  const [description, setDescription] = useState('')
  const [selectedFiles, setSelectedFiles] = useState<any>([])
  const [showEmojiPicker, setShowEmojiPicker] = useState(false)
  const [isCaptionOpen, setIsCaptionOpen] = useState(false)
  const [showShareLink, setShowShareLink] = useState(false)
  const [mediaRecorderStatus, setMediaRecorderStatus] = useState('')
  const [successMsg, setSuccessMsg] = useState('')
  const [socketUploadStart, setSocketUploadStart] = useState(false)
  const [title, setTitle] = useState('')
  const [isMediaUpload, setIsMediaUpload] = useState(false)
  const [cpTitle, setCpTitle] = useState('')
  const [cpQuestionId, setCpQuestionId] = useState('')
  const [handelTimeRender, setHandelTimeRender] = useState(false)
  const [isLoaderExternal, setIsLoaderExternal] = useState(false)
  const [totalItemsExternal, setTotalItemsExternal] = useState(0)
  const [loaderStatus, setLoaderStatus] = useState<string | null>(null)

  const uploadDoMedia = async (files: any[], totalItems: number) => {
    const totalSize = files.reduce((accumulator, file) => {
      return accumulator + file.data.size
    }, 0)
    console.log('TotalSize ', totalSize)
    if (totalSize > 2000000000) {
      dispatch(
        setAppError({
          name: 'AuthError',
          status: true,
          state: 'ERROR',
          content: 'You can only upload 2GBs of files in total. Please remove few files and proceed again.',
        })
      )
    } else {
      // TODO: Refactor the logic. It should not use async.
      // eslint-disable-next-line @typescript-eslint/no-misused-promises, no-async-promise-executor
      return await new Promise(async resolve => {
        const allResults = []

        for (let i = 0; i < files.length; i++) {
          const result = await doUpload(files[i].data, totalItems, i + 1)
          allResults.push(result)
        }
        const mergedResult: any[] = []
        allResults.forEach((m: any) => {
          mergedResult.push(m.data.data[0])
        })
        console.log('MERGED RESULTS ', mergedResult)
        resolve({ data: { data: mergedResult, success: true } })
        console.log('ALL UPLOAD RESULTS ', allResults)
      })
    }
  }

  const sendSms = async (smsdata: any) => {
    const data = JSON.stringify({
      to: smsdata.to,
      body: smsdata.body,
    })
    const token = store.getState().user?.value?.token
    const config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: `${environment.apiEndPoint}page/sms/send`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      data,
    }

    axios(config)
      .then(() => {
        showMessage('SMS sent successfully')
        setShowShareLink(false)
        setIsMediaPhone(true)
      })
      .catch(() => {})
  }

  const showMessage = (msg: string) => {
    setSuccessMsg(msg)
    setTimeout(() => {
      setSuccessMsg('')
    }, 5000)
  }

  const sendEmail = async (type: string) => {
    const data = JSON.stringify({
      to: userData.value?.data.email,
      subject: 'Upload link',
      html: `${environment.frontEndPoint}upload/${pageData.data._id}/${userData.value.data._id}/${type}`,
      pageId: pageData.data._id,
      type: 'upload',
    })
    const token = store.getState().user?.value?.token
    const config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: `${environment.apiEndPoint}page/email/send`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      data,
    }

    axios(config)
      .then(() => {
        showMessage('Email sent successfully')
        setShowShareLink(false)
        setIsMediaPhone(true)
      })
      .catch(() => {})
  }

  useEffect(() => {
    function qrStartSocket(value: any) {
      value = JSON.parse(value)
      // look for members
      // let isMember = pageData.data.members.find((m) => {
      // 	return value.loggedInUser === m.user._id;
      // });
      if (value && value.loggedInUser === userData.value.data._id && value.page === pageData.data._id) {
        setSocketUploadStart(true)
      }
    }
    socket.on('qrStart', qrStartSocket)

    function qrEnd(value: any) {
      if (value) {
        value = JSON.parse(value)
        // check for members also
        // let isMember = pageData.data.members.find((m) => {
        // 	return value.loggedInUser === m.user._id;
        // });
        if (value?.data && value.loggedInUser === userData.value.data._id) {
          const newSelectedFiles = value?.data.map((e: any) => {
            return { from: 'qrcode', fromQRCode: true, ...e }
          })
          setSelectedFiles([...selectedFiles, ...newSelectedFiles])
          setSocketUploadStart(false)
          setIsMediaPhone(false)
        }
      } else {
        setIsMediaPhone(false)
      }
    }
    socket.on('qrEnd', qrEnd)
  })

  useEffect(() => {
    if (props?.type === 'question') {
      setDescription(props?.description)
      setCpTitle(props?.title)
      setCpQuestionId(props?.questionId)
    } else {
      setCpQuestionId('')
    }
  }, [props?.type])

  useEffect(() => {
    setFrom(props?.from)
  }, [props?.from])

  useEffect(() => {
    if (authResponse?.access_token && authResponse?.access_token?.trim() !== '') {
      localStorage.setItem('authResponse_access_token', btoa(authResponse?.access_token))
    }
  }, [authResponse])

  const handleOpenPicker = () => {
    let mediaType: any = ''
    if (from === 'video') {
      mediaType = 'DOCS_VIDEOS'
    } else if (from === 'micaudio') {
      mediaType = 'DOCS'
    } else if (from === 'media') {
      mediaType = 'DOCS_IMAGES'
    }

    openPicker({
      clientId: environment.gDriveClientId,
      developerKey: environment.gDriveDeveloperKey,
      viewId: mediaType,
      showUploadView: true,
      showUploadFolders: true,
      supportDrives: true,
      multiselect: true,
      token: authResponse?.access_token,
      callbackFunction: async (data: any) => {
        if (data?.docs?.length && localStorage.getItem('authResponse_access_token') !== null) {
          dispatch(setLoadPercentage(0))
          dispatch(setLoadItemsDone(0))
          // TODO: Refactor the logic. It should not use async.
          // eslint-disable-next-line @typescript-eslint/no-misused-promises, no-async-promise-executor
          await new Promise(async () => {
            dispatch(loading(true))
            const Arr: any = []
            setIsLoaderExternal(true)
            if (data.docs.length > 0) {
              setTotalItemsExternal(data.docs.length)
            } else {
              setTotalItemsExternal(0)
            }
            let allGdriveDowns: any[] = []
            setLoaderStatus('Fetching')
            for (let i = 0; i < data?.docs.length; i++) {
              const token = atob(localStorage.getItem('authResponse_access_token') ?? '')
              const config: AxiosRequestConfig<any> = {
                method: 'GET',
                responseType: 'blob',
                maxBodyLength: Infinity,
                url: `https://www.googleapis.com/drive/v3/files/${data?.docs[i]?.id}?alt=media`,
                onDownloadProgress: (progress: any) => {
                  const { loaded, total } = progress
                  let percent
                  if (data.docs.length === 1) {
                    percent = Math.floor((loaded * 100) / total)
                  } else {
                    const state = store.getState()
                    percent = Math.floor((loaded * 100) / total / data.docs.length + state.user.loadPercentage)
                  }
                  if (percent < 100) {
                    console.log(`${loaded} bytes of ${total} bytes. ${percent}%`)
                    dispatch(setLoadPercentage(percent))
                  } else {
                    dispatch(setLoadPercentage(100))
                  }
                },
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              }
              setIsMediaUpload(true)
              await axios(config).then((r: any) => {
                const resp = r.data
                resp.originalname = data?.docs[i]?.name
                resp.filename = data?.docs[i]?.name
                resp.mimetype = data?.docs[i]?.mimeType
                resp.name = data?.docs[i]?.name
                allGdriveDowns.push(resp)
              })
            }

            if (allGdriveDowns.length > 0) {
              dispatch(setLoadPercentage(0))
              dispatch(setLoadItemsDone(0))
              setLoaderStatus(null)
              allGdriveDowns = allGdriveDowns.map(blob => {
                return { data: blob }
              })
              await uploadDoMedia(allGdriveDowns, data.docs.length).then((data1: any) => {
                if (data1?.data?.success === true) {
                  data1?.data?.data.forEach((ele: any) => {
                    const blob = ''

                    Arr.push({
                      data: blob,
                      url: ele.url,
                      thumbnail: ele.thumbnail,
                      caption: '',
                      from: 'google_drive',
                      type: ele?.type,
                      authResponse: authResponse ?? null,
                    })
                  })
                }
              })
            }
            setSelectedFiles([...selectedFiles, ...Arr])
            dispatch(loading(false))
          })
        }
      },
    })
  }

  const openDropBox = () => {
    const dropboxbtn = document.getElementById('dropbox-btn')
    if (dropboxbtn) {
      dropboxbtn?.click()
    }
  }

  const onSuccess = async (data: any) => {
    const dataArr: any = []
    // TODO: Refactor the logic. It should not use async.
    // eslint-disable-next-line @typescript-eslint/no-misused-promises, no-async-promise-executor
    await new Promise(async () => {
      dispatch(loading(true))
      dispatch(setLoadPercentage(0))
      dispatch(setLoadItemsDone(0))
      setIsLoaderExternal(true)

      if (data.length > 0) {
        setTotalItemsExternal(data.length)
      } else {
        setTotalItemsExternal(0)
      }
      const allDbDowns: any[] = []
      setLoaderStatus('Fetching')
      for (let i = 0; i < data?.length; i++) {
        let url = data[i]?.link
        console.log('DB D ', data[i])

        let extension: any = data[i]?.name?.split('.')[1]
        extension = extension?.toString()?.toLowerCase()

        console.log('FETCHING ', url)
        url = url.replace('https://www.dropbox.com', 'https://dl.dropboxusercontent.com')
        console.log('FETCHING Replaced', url)
        setIsMediaUpload(true)
        // let oauth = await axios({
        // 	url: `https://www.dropbox.com/oauth2/authorize?client_id=${environment.dropBoxAppKey}&response_type=code`,
        // 	method: "GET",

        // })
        // console.log("DBOAUTH ",oauth);
        await axios({
          url,
          method: 'GET',
          responseType: 'blob',
          onDownloadProgress: progress => {
            const { loaded, total } = progress
            let percent
            if (data.length === 1) {
              percent = Math.floor((loaded * 100) / (total as number))
            } else {
              const state = store.getState()
              percent = Math.floor((loaded * 100) / (total as number) / data.length + state.user.loadPercentage)
            }
            if (percent < 100) {
              console.log(`${loaded} bytes of ${total} bytes. ${percent}%`)
              dispatch(setLoadPercentage(percent))
            } else {
              dispatch(setLoadPercentage(100))
            }
          },
        }).then(async (r: any) => {
          const resp = r.data
          resp.originalname = data[i].name
          resp.filename = data[i].name
          resp.mimetype = data[i].mimeType
          resp.name = data[i].name
          allDbDowns.push({ data: resp })
        })
      }
      if (allDbDowns.length > 0) {
        dispatch(setLoadPercentage(0))
        dispatch(setLoadItemsDone(0))
        setLoaderStatus(null)
        await uploadDoMedia(allDbDowns, data.length).then(async (data1: any) => {
          if (data1?.data?.success === true) {
            await data1?.data?.data.map(async (ele: any) => {
              dataArr.push({
                data: '',
                url: ele.url,
                caption: '',
                from: 'dropbox',
                type: '',
                provider: 'external',
                thumbnail: ele?.thumbnail,
              })
            })
          }
        })
      }

      dispatch(loading(false))
      setSelectedFiles([...selectedFiles, ...dataArr])
    })
  }

  const onCancel = () => {}

  const onEmojiClick = (event: any) => {
    const textAreaElement: any = document.getElementById('postDescription')
    setDescription(
      description.substr(0, textAreaElement.selectionStart) +
        event.emoji +
        description.substr(textAreaElement.selectionEnd)
    )
    setShowEmojiPicker(false)
  }

  const openFileSelection = () => {
    const sId = document.getElementById('FileUploadFromSystem_input')
    if (sId) {
      sId?.click()
    }
  }

  const createNewPost = () => {
    const mediaArr: any[] = []
    let postType = 'Feed Post'
    if (cpTitle) {
      postType = 'Prompted Post'
    } else if (isMeidaPost) {
      postType = 'Gallery Post'
    }
    if (from === 'description') {
      const payload: any = {
        title: cpTitle,
        description,
        page: pageData?.data?._id,
        medias: mediaArr,
        createdBy: userData?.value?.data?._id,
        reactions: [],
        questionId: cpQuestionId,
      }
      if (props?.editPost) {
        payload._id = props?.editPost?._id
        dispatch(updatePost(payload))
      } else {
        trackEvent('Page Interaction', postType, 'Text Only')
        dispatch(createPost(payload))
      }
    } else if (from !== 'description' && selectedFiles?.length === 0) {
      const payload: any = {
        title: cpTitle,
        description,
        page: pageData?.data?._id,
        medias: mediaArr,
        createdBy: userData?.value?.data?._id,
        reactions: [],
        questionId: cpQuestionId,
      }
      if (props?.editPost) {
        payload._id = props?.editPost?._id
        dispatch(updatePost(payload))
      } else {
        trackEvent('Page Interaction', postType, `${from}`)
        dispatch(createPost(payload))
      }
    } else {
      uploadSelectedMedia()
    }
  }

  const uploadSelectedMedia = () => {
    const files = new FormData()
    const fileData = selectedFiles?.filter((e: any) => {
      return e?.from && e?.from !== '' ? false : e
    })
    if (from === 'media') {
      fileData?.forEach((e: any) => {
        files.append('media', e?.data)
      })
    } else if (from === 'video') {
      fileData?.forEach((e: any) => {
        files.append('media', e?.data, e?.data?.filename)
      })
    } else if (from === 'micaudio') {
      fileData?.forEach((e: any) => {
        files.append('media', e?.data)
      })
    }
    let postType = 'Feed Post'
    if (cpTitle) {
      postType = 'Prompted Post'
    } else if (isMeidaPost) {
      postType = 'Gallery Post'
    }
    dispatch(loading(true))
    if (fileData.length) {
      setIsMediaUpload(true)
      dispatch(setLoadPercentage(0))
      dispatch(setLoadItemsDone(0))

      uploadDoMedia(fileData, fileData.length).then((response: any) => {
        if (response?.data?.data?.length) {
          let mediaArr: any[] = []
          response?.data?.data?.forEach((data: any, key: number): any => {
            mediaArr.push({
              page: pageData?.data?._id,
              post: props?.editPost?._id,
              is_deleted: false,
              comments: [],
              likes: [],
              counts: {
                comments: 0,
                likes: 0,
              },
              url: data?.url,
              thumbnail: data?.thumbnail,
              caption: fileData[key]?.caption,
              provider: fileData[key]?.provider,
              type: data?.type,
              _id: data?._id,
              width: data?.width,
              height: data?.height,
            })
          })

          const driveData: any[] = []
          selectedFiles?.forEach((e: any) => {
            if (e?.from && e?.from !== '') {
              if (e?.provide && e?.provider === 'external') {
                driveData.push({
                  ...e,
                  url: e?.url,
                  caption: e?.caption,
                  provider: 'external',
                  authResponse: e?.from === 'google_drive' ? authResponse : undefined,
                  type: e?.type,
                  _id: e?._id,
                })
              } else {
                driveData.push({
                  ...e,
                  url: e?.url,
                  caption: e?.caption,
                  type: e?.type,
                  _id: e?._id,
                })
              }
            }
          })

          mediaArr = [...mediaArr, ...driveData]
          const payload: any = {
            title: cpTitle,
            description,
            page: pageData?.data?._id,
            medias: mediaArr,
            createdBy: userData?.value?.data?._id,
            reactions: [],
            questionId: cpQuestionId,
          }
          if (props?.editPost) {
            payload._id = props?.editPost?._id
            dispatch(updatePost(payload))
          } else {
            trackEvent('Page Interaction', postType, `Media - ${from}`)
            dispatch(createPost(payload))
          }
        }
      })
    } else {
      const files: any[] = []
      selectedFiles?.forEach((e: any) => {
        if (e?.from && e?.from !== '') {
          if (e?.provider && e?.provider === 'external') {
            files.push({
              ...e,
              url: e?.url,
              caption: e?.caption,
              provider: 'external',
              authResponse: e?.from === 'google_drive' ? authResponse : undefined,
              type: e?.type,
              _id: e._id,
            })
          } else {
            files.push({
              ...e,
              url: e?.url,
              caption: e?.caption,
              type: e?.type,
              _id: e._id,
            })
          }
        }
      })
      const payload: any = {
        title: cpTitle,
        description,
        page: pageData?.data?._id,
        medias: files,
        createdBy: userData?.value?.data?._id,
        reactions: [],
        questionId: cpQuestionId,
      }
      if (props?.editPost) {
        payload._id = props?.editPost?._id
        dispatch(updatePost(payload))
      } else {
        trackEvent('Page Interaction', postType, `Media - ${from}`)
        dispatch(createPost(payload))
      }
    }
  }

  const removeSelectedFile = (pos: number) => {
    setSelectedFiles(
      selectedFiles?.filter((e: any, index: number) => {
        return pos !== index
      })
    )
  }

  const updateCaption = (pos: number, caption: string) => {
    setSelectedFiles(
      selectedFiles?.filter((e: any, index: number) => {
        if (pos === index) {
          e.caption = caption
        }
        return e
      })
    )
  }

  const systemFileSelection = (e: any) => {
    if (e?.currentTarget?.files?.length) {
      let image = ['jpg', 'png', 'jpeg', 'gif', 'bmp', 'tiff', 'tif', 'svg', 'webp']
      if (from === 'media') {
        image = ['jpg', 'png', 'jpeg', 'gif', 'bmp', 'tiff', 'tif', 'svg', 'webp']
      } else if (from === 'video') {
        image = ['mp4', 'mov', 'webm', 'ogg', 'avi']
      } else if (from === 'micaudio') {
        image = ['mp3', 'wav', 'ogg', 'aac', 'flac']
      }
      const images = []
      for (let i = 0; i < e?.currentTarget?.files?.length; i++) {
        let extension: any = e?.currentTarget?.files[i].name.substring(
          e?.currentTarget?.files[i].name.lastIndexOf('.') + 1
        )

        extension = extension.toLowerCase()
        if (image.includes(extension)) {
          const fileUrl = URL.createObjectURL(e?.currentTarget?.files[i])
          images.push({
            url: fileUrl,
            data: e?.currentTarget?.files[i],
            caption: '',
            thumbnail: from === 'media' ? fileUrl : from === 'video' && !isSafari ? fileUrl : fileUrl,
          })
        } else {
          showMessage('only ' + image?.join(', ') + ' extension is allowed.')
        }
      }
      if (selectedFiles) {
        setSelectedFiles([...selectedFiles, ...images])
      } else {
        setSelectedFiles([...images])
      }
    }
  }

  const OnStop = (blobUrl: string, blob: any) => {
    const timestamp = Date.now()
    blob.originalname = `post-${timestamp}.mp4`
    blob.filename = `post-${timestamp}.mp4`
    blob.mimetype = blob.type
    blob.provider = 'manual'
    setSelectedFiles([
      {
        provider: 'manual',
        data: blob,
        url: blobUrl,
        caption: '',
        type: from === 'video' ? 'video' : 'audio',
      },
    ])
  }

  const IconHolder = () => {
    return (
      <div
        className={cn('IconHolder', {
          mediaIconsOnly: isMeidaPost,
        })}
      >
        <div className="lhs">
          {from === 'description' ? (
            <i
              className="fa fa-butterflysmiley"
              onClick={() => {
                setShowEmojiPicker(true)
              }}
            ></i>
          ) : (
            <></>
          )}
        </div>
        <div className="IconsList">
          {!isMeidaPost && (
            <i
              className={cn('fa fa-text', {
                active: from === 'description',
              })}
              onClick={() => {
                setFrom('description')
              }}
            ></i>
          )}
          <i
            className={cn('fa fa-butterflymedia', {
              active: from === 'media',
            })}
            onClick={() => {
              setFrom('media')
            }}
          ></i>
          <i
            className={cn('fa fa-videopost', {
              active: from === 'video',
            })}
            onClick={() => {
              setFrom('video')
            }}
          ></i>
          <i
            className={cn('fa fa-butterflymic mr-0', {
              active: from === 'micaudio',
            })}
            onClick={() => {
              setFrom('micaudio')
            }}
          ></i>
        </div>
      </div>
    )
  }

  const textDescription = () => {
    return (
      <>
        <textarea
          name="description"
          placeholder={
            from === 'description'
              ? 'Write a post and share it with the members of your page'
              : `Add description for your media`
          }
          id="postDescription"
          value={description}
          onChange={(e: any) => {
            setDescription(e?.target?.value)
          }}
        ></textarea>
        {from !== 'description' && (
          <i
            className="fa fa-butterflysmiley"
            onClick={() => {
              setShowEmojiPicker(true)
            }}
          ></i>
        )}
        {showEmojiPicker ? (
          <div className="Emojis">
            <p
              onClick={() => {
                setShowEmojiPicker(false)
              }}
            >
              &#x2715;
            </p>
            <Picker autoFocusSearch={false} onEmojiClick={onEmojiClick} />
          </div>
        ) : (
          ''
        )}
      </>
    )
  }

  useEffect(() => {
    if (postData?.postCreateResponse?.success === true) {
      dispatch(loading(false))
      setDescription('')
      setIsCaptionOpen(false)
      setShowEmojiPicker(false)
      setSelectedFiles([])
      // Need to open pin post
      if (userData?.value?.data?.showStep && userData?.value?.data?.step === '3') {
        setTimeout(() => {
          if (!pageData?.data?.from_legacy) {
            dispatch(addPageAction('show-pin-steps'))
            dispatch(
              setShowStepStatus({
                ...userData?.value,
                data: { ...userData?.value?.data, step: '3' },
              })
            )
            const payload = {
              _id: userData?.value?.data?._id,
              step: '3',
            }
            dispatch(updateUserInfo(payload, false))
          } else {
            dispatch(addPageAction('show-question-steps'))
          }
        }, 1500)
      } else {
        addPageAction('')
      }
      props?.handleClose()
    }
  }, [postData?.postCreateResponse])

  useEffect(() => {
    if (props?.editPost?.description || props?.editPost?.medias.length) {
      setDescription(props?.editPost?.description)
      const editFileList: any[] = []
      props?.editPost?.medias?.forEach((e: any) => {
        editFileList.push({
          ...e,
          caption: e.caption,
          url: e.url,
          from: 'editpost',
          type: e?.type,
          _id: e?._id,
        })
      })

      const videoCount = editFileList.filter((media: any) => media.type === 'video')
      const audioCount = editFileList.filter((media: any) => media.type === 'audio')
      const imageCount = editFileList.filter((media: any) => media.type === 'image')
      if (videoCount.length) {
        setFrom('video')
      } else if (audioCount.length) {
        setFrom('micaudio')
      } else if (imageCount.length) {
        setFrom('media')
      } else {
        setFrom('description')
      }
      setSelectedFiles(editFileList)
      setTitle('Edit Post')
      setCpTitle(props?.editPost?.title)
      setCpQuestionId(props?.editPost?.questionId)
    }
  }, [props?.editPost])

  useEffect(() => {
    if (postData?.updatePostResponse?.success === true) {
      setDescription('')
      setIsCaptionOpen(false)
      setShowEmojiPicker(false)
      setSelectedFiles([])
      dispatch(loading(false))
      dispatch(addPageAction(''))
      props?.handleClose()
    }
  }, [postData?.updatePostResponse])

  useEffect(() => {
    setTitle(`Add content to ${pageData?.data?.first_name}'s Gallery`)
  }, [isMeidaPost])

  useEffect(() => {
    if (props?.title) {
      props?.editPost?.title ? setTitle(props?.editPost?.title) : setTitle(props?.title)
    }
  }, [props?.title])

  const disableSubmit = (status: string) => {
    if (status === 'recording') {
      if (document.getElementById('submit-post-mobile')) {
        ;(document.getElementById('submit-post-mobile') as HTMLInputElement).disabled = true
      }
      if (document.getElementById('submit-post')) {
        ;(document.getElementById('submit-post') as HTMLInputElement).disabled = true
      }
    } else {
      if (description !== '' || selectedFiles.length > 0) {
        if (document.getElementById('submit-post-mobile')) {
          ;(document.getElementById('submit-post-mobile') as HTMLInputElement).disabled = false
        }
        if (document.getElementById('submit-post')) {
          ;(document.getElementById('submit-post') as HTMLInputElement).disabled = false
        }
      }
    }
  }

  const showTimer = (status: string) => {
    let timer: any
    let flag = 0
    if (!handelTimeRender) {
      flag = 1
    }
    const timerSelector: any = document.getElementById('recorder-timing')
    if (timerSelector && status === 'recording') {
      if (flag === 1) {
        setHandelTimeRender(true)
        let hou = 0
        let sec = 1
        timer = setInterval(function () {
          timerSelector.innerHTML = ('0' + hou).slice(-2) + ' : ' + ('0' + sec).slice(-2)
          sec++
          if (sec === 60) {
            hou++
            sec = 0
          }
        }, 1000)
      }
    } else {
      setHandelTimeRender(false)
      clearInterval(timer)
    }
  }

  useEffect(() => {
    return () => {
      localStorage.removeItem('authResponse_access_token')
    }
  }, [])

  return (
    <>
      <Modal
        {...props}
        show={props.show}
        onHide={() => {
          if (stopVideo.current) {
            stopVideo?.current?.click()
          }
          if (userData?.value?.data?.showStep) {
            dispatch(addPageAction('show-question-steps'))
          } else {
            addPageAction('')
          }
          props.handleClose()
        }}
        title=""
        centered
        className="CreatePost"
      >
        {userData.loading && (
          <Loader
            status={loaderStatus}
            isActive={isMediaUpload}
            totalItems={selectedFiles.length > 0 ? selectedFiles.length : isLoaderExternal ? totalItemsExternal : 0}
            isExternal={isLoaderExternal}
          />
        )}
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter" className="d-flex">
            <div className="owner">
              <div className="ownerPic">
                {isset(userData?.value?.data?.image) ? (
                  <img src={userData?.value?.data?.image} alt="" />
                ) : (
                  <div className="ownerAlpha">
                    <span>{userData?.value?.data?.name?.charAt(0)}</span>
                  </div>
                )}
              </div>
            </div>
            {isMeidaPost ? `Add content to ${pageData?.data?.first_name}'s Gallery` : title}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {successMsg !== '' ? (
            <div className="text text-center text-success">
              <span>{successMsg}</span>
            </div>
          ) : (
            <></>
          )}
          {from === 'description' ? (
            <form className="Message">
              {/* ---------Create Message Starts------------ */}
              <div className="MessagePost">
                <React.Fragment>{textDescription()}</React.Fragment>
                <React.Fragment>{IconHolder()}</React.Fragment>
              </div>

              {/* ---------Create Mesasge Ends------------ */}
            </form>
          ) : from === 'media' ? (
            <>
              {!isMediaPhone && !showShareLink ? (
                <form className="Image">
                  {/* ---------Create Image Starts------------ */}
                  <div className="ImagePost">
                    {!isMeidaPost && <React.Fragment>{textDescription()}</React.Fragment>}
                    <div className={selectedFiles?.length === 0 ? 'UploadSec' : 'UploadSec d-none'}>
                      <div className="IconHolder">
                        <div className="EachIconHolder" onClick={openFileSelection}>
                          <img src={folder} />
                          <span>{isMobile ? 'Folder' : 'Computer'}</span>
                          <input
                            type="file"
                            className="d-none"
                            id="FileUploadFromSystem_input"
                            accept="image/*"
                            multiple
                            onChange={systemFileSelection}
                          />
                        </div>

                        <div className="EachIconHolder" onClick={handleOpenPicker}>
                          <img src={GDrive} className="gDrive" />
                          <span>
                            Google Drive<sup>TM</sup>
                          </span>
                        </div>
                        <div
                          className="EachIconHolder"
                          onClick={() => {
                            openDropBox()
                          }}
                        >
                          <img src={dropbox} className="dropbox" />
                          <span>Dropbox</span>
                        </div>
                        <div
                          className="EachIconHolder"
                          onClick={() => {
                            setIsMediaPhone(true)
                          }}
                        >
                          <img src={phone} className="phone" />
                          <span>Phone</span>
                        </div>
                      </div>
                    </div>
                    {selectedFiles?.length ? (
                      <div>
                        {!isCaptionOpen ? (
                          <>
                            <span
                              className="addCapt"
                              onClick={() => {
                                setIsCaptionOpen(true)
                              }}
                            >
                              <i className="fa fa-plus"></i> Add captions to photos
                            </span>
                            <div className="SelectedImages">
                              {selectedFiles?.map((element: any, key: number) => {
                                return (
                                  <div className="EachImageHolder" key={key}>
                                    <img src={element?.url} alt="" />
                                    <span
                                      onClick={() => {
                                        removeSelectedFile(key)
                                      }}
                                    >
                                      <i className="fa fa-butterflydelete"></i>
                                    </span>
                                  </div>
                                )
                              })}
                            </div>
                            <span className="addCapt">
                              Add more photos:{' '}
                              <span className="folder" onClick={openFileSelection}>
                                <img src={folder} />
                              </span>
                              <span className="g-drive" onClick={handleOpenPicker}>
                                <img src={GDrive} className="gDrive" />
                              </span>
                              <span
                                className="dropbox"
                                onClick={() => {
                                  openDropBox()
                                }}
                              >
                                <img src={dropbox} />
                              </span>
                              {!isMobile ? (
                                <span
                                  className="phone"
                                  onClick={() => {
                                    setIsMediaPhone(true)
                                  }}
                                >
                                  <img src={phone} className="phone" />
                                </span>
                              ) : (
                                ''
                              )}
                            </span>
                          </>
                        ) : (
                          <>
                            {/* --------------------------Caption for img upload UI Ends-------------------------- */}
                            <span
                              className="addCapt"
                              onClick={() => {
                                setIsCaptionOpen(false)
                              }}
                            >
                              <i className="fa fa-chevron-small-left"></i> Back to photos
                            </span>
                            <div className="Caption">
                              {selectedFiles?.map((element: any, key: number) => {
                                return (
                                  <div className="Eachcaption" key={key}>
                                    <div className="Images">
                                      <img src={element?.url} alt="" />
                                    </div>
                                    <div className="TextField">
                                      <textarea
                                        placeholder="Captions are a great way to tell more of the story in a personal way."
                                        value={element.caption}
                                        id={'post_caption_' + key}
                                        onInput={(e: any) => {
                                          if (e?.target?.value?.length > 100) {
                                            const captionData: any = document.getElementById('post_caption_' + key)
                                            captionData.value = e?.target?.value
                                              ?.replace(/(\r\n|\n|\r)/g, '')
                                              .slice(0, 100)
                                          }
                                        }}
                                        onChange={(e: any) => {
                                          updateCaption(key, e?.target?.value)
                                        }}
                                      ></textarea>
                                      <span className="captCount">
                                        {(document.getElementById('post_caption_' + key) as HTMLInputElement)?.value
                                          ?.length
                                          ? (
                                              document.getElementById('post_caption_' + key) as HTMLInputElement
                                            )?.value?.replace(/(\r\n|\n|\r)/g, '').length + '/100'
                                          : '0/100'}
                                      </span>
                                    </div>
                                  </div>
                                )
                              })}
                            </div>
                          </>
                        )}
                      </div>
                    ) : (
                      ''
                    )}
                  </div>
                  {selectedFiles.length === 0 && <React.Fragment>{IconHolder()}</React.Fragment>}
                </form>
              ) : (
                ''
              )}
              {isMediaPhone ? (
                <div className="QrCodeWrapper">
                  {socketUploadStart && (
                    <div
                      id={'socketUploadStart'}
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        color: '#ffffff',
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        height: '100%',
                        width: '100%',
                        backgroundColor: 'rgba(0,0,0,0.7)',
                        zIndex: 1,
                      }}
                    >
                      Uploading from your phone
                    </div>
                  )}
                  <div className="Qrcode">
                    <QRCode
                      ecLevel="H"
                      eyeRadius={[
                        {
                          // top/left eye
                          outer: [10, 10, 0, 10],
                          inner: [0, 0, 0, 0],
                        }, // top/left eye
                        {
                          // top/left eye
                          outer: [10, 10, 10, 0],
                          inner: [0, 0, 0, 0],
                        }, // top/right eye
                        {
                          // top/left eye
                          outer: [10, 0, 10, 10],
                          inner: [0, 0, 0, 0],
                        }, // bottom/left
                      ]}
                      qrStyle={'dots'}
                      logoWidth={60}
                      logoImage="https://butterflyv2-production-assets.nyc3.digitaloceanspaces.com/image/837dc86a-e8bb-46e9-b44f-7535d118a874-butterfly-notext-%284%29.svg"
                      value={`${environment.frontEndPoint}upload/${pageData.data._id}/${userData.value.data._id}/image`}
                    />
                    <span>Scan the QR code with your phone</span>
                    <i
                      className="fa fa-close closebtn"
                      onClick={() => {
                        setIsMediaPhone(false)
                      }}
                    ></i>
                  </div>
                  <div className="Cta">
                    <span
                      onClick={() => {
                        navigator.clipboard
                          .writeText(
                            `${environment.frontEndPoint}upload/${pageData.data._id}/${userData.value.data._id}/image`
                          )
                          .then(() => {
                            showMessage('Link copied')
                          })
                      }}
                    >
                      <i className="fa fa-butterflycopylink"></i>
                      Copy link
                    </span>
                    <span
                      onClick={() => {
                        sendEmail('image')
                      }}
                    >
                      <i className="fa fa-butterflyemail"></i>
                      Email link
                    </span>

                    <span
                      onClick={() => {
                        setShowShareLink(true)
                        setIsMediaPhone(false)
                      }}
                    >
                      <i className="fa fa-sms"></i>
                      SMS link
                    </span>
                  </div>
                  {/* ---------Create Image Ends------------ */}
                </div>
              ) : (
                ''
              )}

              {showShareLink ? (
                <div className="ShareLink">
                  {/* ---------Share Upload Link Starts------------ */}
                  <p>
                    Upload your images from your phone to your page{' '}
                    <span>
                      {window.location.origin}/{pageData?.data?.unique_url}
                    </span>
                  </p>
                  <div className="InputHolder">
                    <PhoneInputWithCode
                      updatePhoneNumber={(e: any) => {
                        setPh(e.value)
                      }}
                      placeHolder="Phone Number"
                    />
                    <button
                      type="button"
                      className="phoneBtn"
                      onClick={() => {
                        sendSms({
                          to: ph,
                          body: `Click this link to upload your content to ${pageData.data.first_name}'s page: ${environment.frontEndPoint}upload/${pageData.data._id}/${userData.value.data._id}/image`,
                        })
                      }}
                    >
                      Send
                    </button>
                    <button
                      className="phoneBtn cancelCta"
                      onClick={() => {
                        setShowShareLink(false)
                        setIsMediaPhone(true)
                      }}
                    >
                      Cancel
                    </button>
                  </div>
                  {/* ---------Share Upload Link Starts------------ */}
                </div>
              ) : (
                ''
              )}
            </>
          ) : from === 'video' ? (
            <>
              {!isMediaPhone && !showShareLink ? (
                <form className="Video Image">
                  <ReactMediaRecorder
                    video
                    blobPropertyBag={{
                      type: 'video/mp4',
                    }}
                    onStop={OnStop}
                    render={({ previewStream, status, startRecording, stopRecording, mediaBlobUrl, clearBlobUrl }) => {
                      showTimer(status)
                      disableSubmit(status)
                      setMediaRecorderStatus(status)
                      return status === 'idle' || status === 'acquiring_media' ? (
                        <>
                          <div className="VideoPost">
                            {!isMeidaPost && <React.Fragment>{textDescription()}</React.Fragment>}
                            <div className={selectedFiles?.length ? 'd-none UploadSec' : 'UploadSec'}>
                              <div className="IconHolder">
                                <div onClick={openFileSelection} className="EachIconHolder">
                                  <img src={folder} />
                                  <span>{isMobile ? 'Folder' : 'Computer'}</span>
                                  <input
                                    type="file"
                                    className="d-none"
                                    id="FileUploadFromSystem_input"
                                    accept="video/mp4,video/x-m4v,video/*"
                                    onChange={systemFileSelection}
                                  />
                                </div>

                                <div className="EachIconHolder" onClick={handleOpenPicker}>
                                  <img src={GDrive} className="gDrive" />
                                  <span>
                                    Google Drive<sup>TM</sup>
                                  </span>
                                </div>
                                <div
                                  className="EachIconHolder"
                                  onClick={() => {
                                    openDropBox()
                                  }}
                                >
                                  <img src={dropbox} />
                                  <span>Dropbox</span>
                                </div>
                                <div
                                  className="EachIconHolder"
                                  onClick={() => {
                                    setIsMediaPhone(true)
                                  }}
                                >
                                  <img src={phone} className="phone" />
                                  <span>Phone</span>
                                </div>
                              </div>
                              {status === 'idle' ? (
                                <div className="RecSec videoSec" onClick={startRecording}>
                                  <span className="starticon"></span> Record Video
                                </div>
                              ) : (
                                ''
                              )}
                            </div>
                          </div>
                          {selectedFiles.length === 0 && <React.Fragment>{IconHolder()}</React.Fragment>}
                        </>
                      ) : status === 'recording' ? (
                        <div className="VideoPost">
                          <div className="UploadSec recstarted">
                            <div>
                              <VideoPreview stream={previewStream} />
                            </div>
                            <div className="RecSec" ref={stopVideo} onClick={stopRecording}>
                              <span className="stopicon"></span> Stop Recording
                            </div>
                            <p id="recorder-timing"></p>
                          </div>
                        </div>
                      ) : (
                        <div className="VideoPost">
                          {/* -- Recorded Video section -- */}
                          <textarea
                            name="description"
                            placeholder="Add description for your video"
                            id="postDescription"
                            value={description}
                            onChange={(e: any) => {
                              setDescription(e?.target?.value)
                            }}
                          ></textarea>
                          <i
                            className="fa fa-butterflysmiley"
                            onClick={() => {
                              setShowEmojiPicker(true)
                            }}
                          ></i>
                          {showEmojiPicker ? (
                            <div className="Emojis">
                              <p
                                onClick={() => {
                                  setShowEmojiPicker(false)
                                }}
                              >
                                &#x2715;
                              </p>
                              <Picker autoFocusSearch={false} onEmojiClick={onEmojiClick} />
                            </div>
                          ) : (
                            ''
                          )}
                          <div className="UploadSec">
                            <video src={mediaBlobUrl} controls />
                            <div className="row">
                              <div className="delete text-center">
                                <i
                                  className="fa fa-butterflydelete"
                                  onClick={() => {
                                    setSelectedFiles([])
                                    clearBlobUrl()
                                  }}
                                ></i>
                              </div>
                            </div>
                          </div>
                        </div>
                      )
                    }}
                  />

                  {/* ---------Create Video Ends------------  */}
                  {selectedFiles?.length && mediaRecorderStatus !== 'stopped' ? (
                    <div className="VideoPost">
                      {!isCaptionOpen ? (
                        <>
                          <span
                            className="addCapt"
                            onClick={() => {
                              setIsCaptionOpen(true)
                            }}
                          >
                            <i className="fa fa-plus"></i> Add captions to video
                          </span>
                          <div className="SelectedImages">
                            {selectedFiles?.map((element: any, key: number) => {
                              return (
                                <div className="EachImageHolder" key={key}>
                                  {element ? (
                                    <>
                                      <video className="video-media video-qrmedia" src={`${element.url}#t=1.5`}></video>
                                    </>
                                  ) : (
                                    <div
                                      className="video-media"
                                      style={{
                                        backgroundImage: `url(${element.thumbnail ? element.thumbnail : element.url})`,
                                      }}
                                    >
                                      <i className="fa fa-play"></i>
                                    </div>
                                  )}

                                  <span
                                    onClick={() => {
                                      removeSelectedFile(key)
                                    }}
                                  >
                                    <i className="fa fa-butterflydelete"></i>
                                  </span>
                                </div>
                              )
                            })}
                          </div>
                          <span className="addCapt">
                            Add more video:{' '}
                            <span className="folder" onClick={openFileSelection}>
                              <img src={folder} />
                              {/* From folder */}
                            </span>
                            <span className="g-drive" onClick={handleOpenPicker}>
                              <img src={GDrive} className="gDrive" />
                            </span>
                            <span
                              className="dropbox"
                              onClick={() => {
                                openDropBox()
                              }}
                            >
                              <img src={dropbox} />
                            </span>
                            {!isMobile ? (
                              <span
                                className="phone"
                                onClick={() => {
                                  setIsMediaPhone(true)
                                }}
                              >
                                <img src={phone} className="phone" />
                                {/* From phone */}
                              </span>
                            ) : (
                              ''
                            )}
                          </span>
                        </>
                      ) : (
                        <>
                          {/* --------------------------Caption for img upload UI Ends-------------------------- */}
                          <span
                            className="addCapt"
                            onClick={() => {
                              setIsCaptionOpen(false)
                            }}
                          >
                            <i className="fa fa-chevron-small-left"></i> Back to videos
                          </span>
                          <div className="Caption">
                            {selectedFiles?.map((element: any, key: number) => {
                              return (
                                <div className="Eachcaption" key={key}>
                                  <div className="Images">
                                    <div
                                      className="video-media"
                                      style={{
                                        backgroundImage: `url(${element.thumbnail ? element.thumbnail : element.url})`,
                                      }}
                                    >
                                      <i className="fa fa-play"></i>
                                    </div>
                                  </div>
                                  <div className="TextField">
                                    <textarea
                                      placeholder="Captions are a great way to tell more of the story in a personal way."
                                      id={'post_caption_' + key}
                                      onInput={(e: any) => {
                                        if (e?.target?.value?.length > 100) {
                                          const captionData: any = document.getElementById('post_caption_' + key)
                                          captionData.value = e?.target?.value
                                            ?.replace(/(\r\n|\n|\r)/g, '')
                                            .slice(0, 100)
                                        }
                                      }}
                                      onChange={(e: any) => {
                                        updateCaption(key, e?.target?.value)
                                      }}
                                    ></textarea>
                                    <span className="captCount">
                                      {(document.getElementById('post_caption_' + key) as HTMLInputElement)?.value
                                        ?.length
                                        ? (
                                            document.getElementById('post_caption_' + key) as HTMLInputElement
                                          )?.value?.replace(/(\r\n|\n|\r)/g, '').length + '/100'
                                        : '0/100'}
                                    </span>
                                  </div>
                                </div>
                              )
                            })}
                          </div>
                        </>
                      )}
                    </div>
                  ) : (
                    ''
                  )}
                </form>
              ) : (
                ''
              )}

              {isMediaPhone ? (
                <div className="QrCodeWrapper">
                  {socketUploadStart && (
                    <div
                      id={'socketUploadStart'}
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        color: '#ffffff',
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        height: '100%',
                        width: '100%',
                        backgroundColor: 'rgba(0,0,0,0.7)',
                        zIndex: 1,
                      }}
                    >
                      Uploading from your phone
                    </div>
                  )}
                  <div className="Qrcode">
                    <QRCode
                      ecLevel="H"
                      eyeRadius={[
                        {
                          // top/left eye
                          outer: [10, 10, 0, 10],
                          inner: [0, 0, 0, 0],
                        }, // top/left eye
                        {
                          // top/left eye
                          outer: [10, 10, 10, 0],
                          inner: [0, 0, 0, 0],
                        }, // top/right eye
                        {
                          // top/left eye
                          outer: [10, 0, 10, 10],
                          inner: [0, 0, 0, 0],
                        }, // bottom/left
                      ]}
                      qrStyle={'dots'}
                      logoWidth={60}
                      logoImage="https://butterflyv2-production-assets.nyc3.digitaloceanspaces.com/image/837dc86a-e8bb-46e9-b44f-7535d118a874-butterfly-notext-%284%29.svg"
                      value={`${environment.frontEndPoint}upload/${pageData.data._id}/${userData.value.data._id}/video`}
                    />

                    <span>Scan the QR code with your phone</span>
                    <i
                      className="fa fa-close closebtn"
                      onClick={() => {
                        setIsMediaPhone(false)
                      }}
                    ></i>
                  </div>
                  <div className="Cta">
                    <span
                      onClick={() => {
                        navigator.clipboard
                          .writeText(
                            `${environment.frontEndPoint}upload/${pageData.data._id}/${userData.value.data._id}/video`
                          )
                          .then(() => {
                            showMessage('Link copied')
                          })
                      }}
                    >
                      <i className="fa fa-butterflycopylink"></i>
                      Copy link
                    </span>
                    <span
                      onClick={() => {
                        sendEmail('video')
                      }}
                    >
                      <i className="fa fa-butterflyemail"></i>
                      Email link
                    </span>
                    <span
                      onClick={() => {
                        setShowShareLink(true)
                        setIsMediaPhone(false)
                      }}
                    >
                      <i className="fa fa-sms"></i>
                      SMS link
                    </span>
                  </div>
                  {/* ---------Create Image Ends------------ */}
                </div>
              ) : (
                ''
              )}

              {showShareLink ? (
                <div className="ShareLink">
                  {/* ---------Share Upload Link Starts------------ */}
                  <p>
                    Upload your images from your phone to your page{' '}
                    <span>
                      {window.location.origin}/{pageData?.data?.unique_url}
                    </span>
                  </p>
                  <div className="InputHolder">
                    <PhoneInputWithCode
                      updatePhoneNumber={(e: any) => {
                        setPh(e.value)
                      }}
                      placeHolder="Phone Number"
                    />
                    <button
                      type="button"
                      className="phoneBtn"
                      onClick={() => {
                        sendSms({
                          to: ph,
                          body: `Click this link to upload your content to ${pageData.data.first_name}'s page: ${environment.frontEndPoint}upload/${pageData.data._id}/${userData.value.data._id}/video`,
                        })
                      }}
                    >
                      Send
                    </button>
                    <button
                      className="phoneBtn cancelCta"
                      onClick={() => {
                        setShowShareLink(false)
                        setIsMediaPhone(true)
                      }}
                    >
                      Cancel
                    </button>
                  </div>
                  {/* ---------Share Upload Link Starts------------ */}
                </div>
              ) : (
                ''
              )}
            </>
          ) : from === 'micaudio' ? (
            <>
              {!isMediaPhone && !showShareLink ? (
                <form className="Audio">
                  {/* ---------Create Audio Starts------------ */}
                  <ReactMediaRecorder
                    audio
                    blobPropertyBag={{
                      type: 'audio/wav',
                    }}
                    onStop={OnStop}
                    render={({ status, startRecording, stopRecording, mediaBlobUrl, clearBlobUrl }) => {
                      showTimer(status)
                      disableSubmit(status)
                      setMediaRecorderStatus(status)
                      return (
                        <div className="AudioPost">
                          {status !== 'stopped' ? (
                            <>
                              <div className="AudioPost">
                                {!isMeidaPost && <React.Fragment>{textDescription()}</React.Fragment>}
                                {status === 'idle' ? (
                                  <div className={selectedFiles?.length ? 'd-none UploadSec' : 'UploadSec'}>
                                    <div className="IconHolder">
                                      <div className="EachIconHolder" onClick={openFileSelection}>
                                        <img src={folder} />
                                        <span>{isMobile ? 'Folder' : 'Computer'}</span>
                                        <input
                                          type="file"
                                          className="d-none"
                                          id="FileUploadFromSystem_input"
                                          accept=".wav,.mp3,audio/*"
                                          multiple
                                          onChange={systemFileSelection}
                                        />
                                      </div>
                                      <div className="EachIconHolder" onClick={handleOpenPicker}>
                                        <img src={GDrive} className="gDrive" />
                                        <span>
                                          Google Drive<sup>TM</sup>
                                        </span>
                                      </div>
                                      <div
                                        className="EachIconHolder"
                                        onClick={() => {
                                          openDropBox()
                                        }}
                                      >
                                        <img src={dropbox} />
                                        <span>Dropbox</span>
                                      </div>
                                      <div
                                        className="EachIconHolder d-none"
                                        onClick={() => {
                                          setIsMediaPhone(true)
                                        }}
                                      >
                                        <img src={phone} className="phone" />
                                        <span>Phone</span>
                                      </div>
                                    </div>
                                    <div className="RecSec">
                                      <span onClick={startRecording} className="audioSec">
                                        <i className="fa fa-butterflymic"></i> Record audio
                                      </span>
                                    </div>
                                  </div>
                                ) : (
                                  <div className="UploadSec">
                                    <div className="Ripple">
                                      <Lottie animationData={animationData} />
                                    </div>
                                    <div className="RecSec">
                                      <span ref={stopVideo} onClick={stopRecording} className="videoSec">
                                        <i className="fa fa-stoprecording"></i>
                                        Stop Recording
                                      </span>
                                      <p id="recorder-timing"></p>
                                    </div>
                                  </div>
                                )}
                              </div>
                              {selectedFiles.length === 0 && <React.Fragment>{IconHolder()}</React.Fragment>}
                            </>
                          ) : (
                            <div className="AudioPost">
                              <textarea
                                name="description"
                                placeholder="Add description for your audio"
                                id="postDescription"
                                value={description}
                                onChange={(e: any) => {
                                  setDescription(e?.target?.value)
                                }}
                              ></textarea>
                              <i
                                className="fa fa-butterflysmiley"
                                onClick={() => {
                                  setShowEmojiPicker(true)
                                }}
                              ></i>
                              {showEmojiPicker ? (
                                <div className="Emojis">
                                  <p
                                    onClick={() => {
                                      setShowEmojiPicker(false)
                                    }}
                                  >
                                    &#x2715;
                                  </p>
                                  <Picker autoFocusSearch={false} onEmojiClick={onEmojiClick} />
                                </div>
                              ) : (
                                ''
                              )}
                              <div className="UploadSec">
                                <AudioWaves
                                  audioURL={mediaBlobUrl}
                                  controls
                                  stopAllMedia={() => {}}
                                  mediaPlay={false}
                                />
                                <div className="row">
                                  <div className="delete text-center">
                                    <i
                                      className="fa fa-butterflydelete"
                                      onClick={() => {
                                        setSelectedFiles([])
                                        clearBlobUrl()
                                      }}
                                    ></i>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      )
                    }}
                  />
                  {selectedFiles?.length && mediaRecorderStatus !== 'stopped' ? (
                    <div className="AudioPost">
                      {!isCaptionOpen ? (
                        <>
                          <span
                            className="addCapt"
                            onClick={() => {
                              setIsCaptionOpen(true)
                            }}
                          >
                            <i className="fa fa-plus"></i> Add captions to audios
                          </span>
                          <div className="SelectedImages">
                            {selectedFiles?.map((element: any, key: number) => {
                              return (
                                <div className="EachImageHolder" key={key}>
                                  <div className="audio-media">
                                    <i className="fa fa-butterflyheadphone"></i>
                                  </div>
                                  <span
                                    onClick={() => {
                                      removeSelectedFile(key)
                                    }}
                                  >
                                    <i className="fa fa-butterflydelete"></i>
                                  </span>
                                </div>
                              )
                            })}
                          </div>
                          <span className="addCapt">
                            Add more audios:{' '}
                            <span className="folder" onClick={openFileSelection}>
                              <img src={folder} />
                              {/* From folder */}
                            </span>
                            <span className="g-drive" onClick={handleOpenPicker}>
                              <img src={GDrive} className="gDrive" />
                            </span>
                            <span
                              className="dropbox"
                              onClick={() => {
                                openDropBox()
                              }}
                            >
                              <img src={dropbox} />
                            </span>
                            <span
                              className="phone d-none"
                              onClick={() => {
                                setIsMediaPhone(true)
                              }}
                            >
                              <img src={phone} className="phone" />
                              {/* From phone */}
                            </span>
                          </span>
                        </>
                      ) : (
                        <>
                          {/* --------------------------Caption for img upload UI Ends-------------------------- */}
                          <span
                            className="addCapt"
                            onClick={() => {
                              setIsCaptionOpen(false)
                            }}
                          >
                            <i className="fa fa-chevron-small-left"></i> Back to audios
                          </span>
                          <div className="Caption">
                            {selectedFiles?.map((element: any, key: number) => {
                              return (
                                <div className="Eachcaption" key={key}>
                                  <div className="Images">
                                    <div className="audio-media">
                                      <i className="fa fa-butterflyheadphone"></i>
                                    </div>
                                  </div>
                                  <div className="TextField">
                                    <textarea
                                      placeholder="Captions are a great way to tell more of the story in a personal way."
                                      id={'post_caption_' + key}
                                      onInput={(e: any) => {
                                        if (e?.target?.value?.length > 100) {
                                          const captionData: any = document.getElementById('post_caption_' + key)
                                          captionData.value = e?.target?.value
                                            ?.replace(/(\r\n|\n|\r)/g, '')
                                            .slice(0, 100)
                                        }
                                      }}
                                      onChange={(e: any) => {
                                        updateCaption(key, e?.target?.value)
                                      }}
                                    ></textarea>
                                    <span className="captCount">
                                      {(document.getElementById('post_caption_' + key) as HTMLInputElement)?.value
                                        ?.length
                                        ? (
                                            document.getElementById('post_caption_' + key) as HTMLInputElement
                                          )?.value?.replace(/(\r\n|\n|\r)/g, '').length + '/100'
                                        : '0/100'}
                                    </span>
                                  </div>
                                </div>
                              )
                            })}
                          </div>
                        </>
                      )}
                    </div>
                  ) : (
                    ''
                  )}
                  {/* ---------Create Audio Ends------------ */}
                </form>
              ) : (
                ''
              )}

              {isMediaPhone ? (
                <div className="QrCodeWrapper">
                  {socketUploadStart && (
                    <div
                      id={'socketUploadStart'}
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        color: '#ffffff',
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        height: '100%',
                        width: '100%',
                        backgroundColor: 'rgba(0,0,0,0.7)',
                        zIndex: 1,
                      }}
                    >
                      Uploading from your phone
                    </div>
                  )}
                  <div className="Qrcode">
                    <QRCode
                      qrStyle={'dots'}
                      ecLevel="H"
                      eyeRadius={[
                        {
                          // top/left eye
                          outer: [10, 10, 0, 10],
                          inner: [0, 0, 0, 0],
                        }, // top/left eye
                        {
                          // top/left eye
                          outer: [10, 10, 10, 0],
                          inner: [0, 0, 0, 0],
                        }, // top/right eye
                        {
                          // top/left eye
                          outer: [10, 0, 10, 10],
                          inner: [0, 0, 0, 0],
                        }, // bottom/left
                      ]}
                      logoWidth={60}
                      logoImage="https://butterflyv2-production-assets.nyc3.digitaloceanspaces.com/image/837dc86a-e8bb-46e9-b44f-7535d118a874-butterfly-notext-%284%29.svg"
                      value={`${environment.frontEndPoint}upload/${pageData.data._id}/${userData.value.data._id}`}
                    />
                    <span>Scan the QR code with your phone</span>
                    <i
                      className="fa fa-close closebtn"
                      onClick={() => {
                        setIsMediaPhone(false)
                      }}
                    ></i>
                  </div>
                  <div className="Cta">
                    <span
                      onClick={() => {
                        navigator.clipboard
                          .writeText(
                            `${environment.frontEndPoint}upload/${pageData.data._id}/${userData.value.data._id}`
                          )
                          .then(() => {
                            showMessage('Link copied')
                          })
                      }}
                    >
                      <i className="fa fa-butterflycopylink"></i>
                      Copy link
                    </span>
                    <span
                      onClick={() => {
                        sendEmail('audio')
                      }}
                    >
                      <i className="fa fa-butterflyemail"></i>
                      Email link
                    </span>
                    <span
                      onClick={() => {
                        setShowShareLink(true)
                        setIsMediaPhone(false)
                      }}
                    >
                      <i className="fa fa-sms"></i>
                      SMS link
                    </span>
                  </div>
                  {/* ---------Create Image Ends------------ */}
                </div>
              ) : (
                ''
              )}

              {showShareLink ? (
                <div className="ShareLink">
                  {/* ---------Share Upload Link Starts------------ */}
                  <p>
                    Upload your images from your phone to your page{' '}
                    <span>
                      {window.location.origin}/{pageData?.data?.unique_url}
                    </span>
                  </p>
                  <div className="InputHolder">
                    <PhoneInputWithCode
                      updatePhoneNumber={(e: any) => {
                        setPh(e.value)
                      }}
                      placeHolder="Phone Number"
                    />
                    <button
                      type="button"
                      className="phoneBtn"
                      onClick={() => {
                        sendSms({
                          to: ph,
                          body: `Click this link to upload your content to ${pageData.data.first_name}'s page: ${environment.frontEndPoint}upload/${pageData.data._id}/${userData.value.data._id}`,
                        })
                      }}
                    >
                      Send
                    </button>
                    <button
                      className="phoneBtn cancelCta"
                      onClick={() => {
                        setShowShareLink(false)
                        setIsMediaPhone(true)
                      }}
                    >
                      Cancel
                    </button>
                  </div>
                  {/* ---------Share Upload Link Starts------------ */}
                </div>
              ) : (
                ''
              )}
            </>
          ) : (
            <></>
          )}

          <div className="mobileCTA">
            {!isMediaPhone && !showShareLink ? (
              <button
                type="button"
                id="submit-post-mobile"
                disabled={(description?.trim() === '' && selectedFiles?.length === 0) || handelTimeRender}
                onClick={createNewPost}
              >
                {isMeidaPost ? 'Upload' : 'Post'}
              </button>
            ) : (
              <></>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          {!isMediaPhone && !showShareLink ? (
            <button
              type="button"
              id="submit-post"
              disabled={(description?.trim() === '' && selectedFiles?.length === 0) || handelTimeRender}
              onClick={createNewPost}
            >
              {isMeidaPost ? 'Upload' : props?.editPost ? 'Update' : 'Post'}
            </button>
          ) : (
            <></>
          )}
        </Modal.Footer>
      </Modal>
      <DropboxChooser
        appKey={environment.dropBoxAppKey}
        success={(files: any) => {
          onSuccess(files)
        }}
        cancel={() => {
          onCancel()
        }}
        multiselect={true}
        extensions={
          from === 'media'
            ? ['.jpg', '.png', '.jpeg', '.gif', '.bmp', '.tiff', '.tif', '.svg', '.webp']
            : from === 'video'
            ? ['.mp4', '.mov', '.webm', '.avi', '.heic', '.heif']
            : from === 'micaudio'
            ? ['.mp3', '.wav', '.ogg', '.aac', '.flac']
            : []
        }
      >
        <button type="button" className="dropbox-button d-none" id="dropbox-btn">
          Click me!
        </button>
      </DropboxChooser>
    </>
  )
}

export default CreatePost
