import './Footer.scss'

import React from 'react'
import { useNavigate } from 'react-router-dom'

function Footer(props: any) {
  const navigate = useNavigate()

  const d = new Date()
  const year = d.getFullYear()
  return (
    <div id="Footer" className={props?.isdetailed === true ? 'footer' : ''}>
      <a
        onClick={() => {
          navigate('/terms-condition')
        }}
      >
        Terms of Service
      </a>{' '}
      &nbsp;&nbsp;{' '}
      <a
        onClick={() => {
          navigate('/privacy-policy')
        }}
      >
        Privacy Policy
      </a>{' '}
      <br />© {year} Butterfly.co
    </div>
  )
}

export default Footer
