import './CreatePage.scss'

import { useEffect, useState } from 'react'
import { Container } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { trackEvent } from '../../Analytics/GA'
import AxiosClass from '../../Api/Interceptor'
import PageApi from '../../Api/PageApi'
import { RouteConstants } from '../../Constants/routeConstants'
import { useAppSelector } from '../../Hooks'
import { useAuth } from '../../Hooks/useAuth'
import { ErrorStates, setAppError } from '../../Redux/slices/metaSlice'
import { loading, login } from '../../Redux/slices/userSlice'
import AlertPopup from '../Modals/AlertPopup/AlertPopup'
import StepOne from './StepOne'
import StepThree from './StepThree'
import StepTwo from './StepTwo'

function CreatePage() {
  const [index, setIndex] = useState(1)
  const navigate = useNavigate()
  const auth = useAuth()
  const dispatch = useDispatch()
  const [, setSubmitted] = useState(false)
  const userState: any = useAppSelector(state => state.user)
  const appState = useAppSelector(state => state.metadata)
  const [form, setForm] = useState({
    belongsTo: userState.value.data._id,
    first_name: '',
    last_name: '',
    reason: '',
    privacy_status: 'PUBLIC',
    approval_required: 0,
    unique_url: '',
  })

  useEffect(() => {
    document.title = `Butterfly | A Full Life Deserves a Full Tribute`
    if (window.location.pathname === '/create' && userState?.value?.data?.is_initiated === true) {
      navigate(1)
    }
  }, [])

  const handleFormSubmit = () => {
    if (form.unique_url) {
      dispatch(loading(true))
      new PageApi(AxiosClass)
        .createPost({
          ...form,
        })
        .then((r: any) => {
          dispatch(loading(false))
          if (r.success) {
            trackEvent('Content Creation', 'Page Creation', `${userState?.value?.data?._id}`)
            if (r?.data?.unique_url) {
              if (window.location.pathname === '/create') {
                dispatch(login({ ...userState.value, data: { ...userState.value?.data, is_initiated: true } }))
              }
              navigate(RouteConstants.PAGEDETAIL?.replace(':pageslug', r?.data?.unique_url))
            }
          } else {
            throw r
          }
        })
        .catch((e: any) => {
          dispatch(loading(false))
          dispatch(
            setAppError({
              name: 'AuthError',
              status: true,
              state: ErrorStates.error,
              content: 'page creation failed ' + e.message,
            })
          )
        })
    }
  }
  const prevButton = () => {
    if (index > 1) {
      setIndex(prevIndex => prevIndex - 1)
    }
  }

  const nextButton = () => {
    if (index - 3) {
      setIndex(prevIndex => prevIndex + 1)
    } else {
      setSubmitted(true)
    }
  }

  // TODO: this effect should be centrally controlled
  useEffect(() => {
    if (!auth) {
      navigate(RouteConstants.LOGIN)
    }
  }, [userState])

  if (window.location.pathname === '/create' && userState?.value?.data?.is_initiated === true) {
    return <></>
  }

  return (
    <>
      {appState.error?.status && (
        <AlertPopup
          buttonText={false}
          show={!!appState.error.status}
          content={appState.error.content}
          state={appState.error.state}
          onHide={() => {
            dispatch(setAppError(null))
          }}
        />
      )}
      <section id="CreatePage">
        <Container>
          {index === 1 ? (
            <div className="d-flex">
              <StepOne
                handleForm={(formData: any) => {
                  setForm({ ...form, ...formData })
                }}
                propsData={[nextButton, prevButton, index, form, userState.loading]}
              />
            </div>
          ) : (
            ''
          )}
          <div className={index === 2 ? 'd-flex' : 'd-none'}>
            <StepTwo
              pageName={form.unique_url}
              handleForm={(formData: any) => {
                setForm({ ...form, ...formData })
              }}
              propsData={[nextButton, prevButton, index, form.privacy_status, userState.loading]}
            />
          </div>
          <div className={index === 3 ? 'd-flex' : 'd-none'}>
            <StepThree
              pageName={form.unique_url}
              submitForm={() => {
                handleFormSubmit()
              }}
              handleForm={(formData: any) => {
                setForm({ ...form, ...formData })
              }}
              propsData={[nextButton, prevButton, index, form.approval_required, userState.loading]}
            />
          </div>
        </Container>
      </section>
    </>
  )
}

export default CreatePage
