import Lottie from 'lottie-react'
import React from 'react'

import * as animationData from '../Assets/Lottie/butterfly-animation.json'

export default class LottieControl extends React.Component {
  render() {
    return (
      <div className="lottie-loader">
        <Lottie animationData={animationData} />
      </div>
    )
  }
}
