import { useCallback, useEffect, useState } from 'react'
import { Form, InputGroup } from 'react-bootstrap'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import { useNavigate } from 'react-router-dom'

import { trackEvent } from '../../Analytics/GA'
import { environment } from '../../AppConfig/environment'
import { useAppDispatch, useAppSelector } from '../../Hooks'
import { registerThunk, validateEmailThunk } from '../../Redux/thunks'
import { Regex } from '../../Regex'
import GsignIn from './GsignIn'

function SignUp(props: any) {
  const navigate = useNavigate()
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [name, setName] = useState('')
  const moduleAlert = useAppSelector(state => state.user.alert)
  const [recaptchaToken, setRecaptchaToken] = useState<null | string>(null)
  const dispatch = useAppDispatch()
  const userState = useAppSelector(state => state.user)
  const { executeRecaptcha } = useGoogleReCaptcha()
  const [error, setError] = useState<any>({
    emailError: {
      state: false,
    },
    nameError: {
      state: false,
    },
    passwordError: {
      state: false,
    },
    confirmPasswordError: {
      state: false,
    },
  })
  useEffect(() => {
    if (moduleAlert.status && moduleAlert.content !== 'Internal server error') {
      setError({
        ...error,
        emailError: {
          state: true,
          message: moduleAlert.content,
        },
      })
    } else {
      setError({
        ...error,
        emailError: {
          state: false,
        },
      })
    }
  }, [moduleAlert])
  const handleReCaptchaVerify: any = useCallback(async () => {
    if (!executeRecaptcha) {
      return
    }
    const token = await executeRecaptcha('signup')
    if (token) {
      setRecaptchaToken(prevState => {
        return token
      })

      return token
    } else {
      setRecaptchaToken(null)
      return false
    }
  }, [executeRecaptcha])

  useEffect(() => {
    handleReCaptchaVerify()
  }, [handleReCaptchaVerify])

  const handleRegister = async () => {
    const verify = await handleReCaptchaVerify()
    if (verify) {
      if (email && password && name) {
        dispatch(
          registerThunk(
            {
              name,
              email,
              password,
              recaptcha_token: recaptchaToken && recaptchaToken,
            },
            navigate
          )
        )
        trackEvent('Conversion Event', 'Register', `${email}`)
      }
    }
  }
  const [passwordShown, setPasswordShown] = useState(false)
  const [ConfPasswordShown, setConfPasswordShown] = useState(false)
  const togglePasswordVisiblity = () => {
    setPasswordShown(!passwordShown)
  }
  const toggleConfPasswordVisiblity = () => {
    setConfPasswordShown(!ConfPasswordShown)
  }
  useEffect(() => {
    if (email.match(Regex.email) && !error.emailError.state) {
      validateEmailThunk(
        {
          email,
        },
        navigate
      )
    }
  }, [email])
  return (
    <>
      <form
        onSubmit={e => {
          e.preventDefault()
          return false
        }}
      >
        <div className="inputWithIcon">
          <InputGroup hasValidation>
            <Form.Control
              type="text"
              required
              value={name}
              placeholder="Full Name"
              isInvalid={error.nameError.state}
              disabled={userState.loading}
              onChange={e => {
                setName(e.currentTarget.value)
                if (!e.currentTarget.value) {
                  setError((prevState: any) => {
                    return {
                      ...prevState,
                      nameError: {
                        message: 'Name is required',
                        state: true,
                      },
                    }
                  })
                } else {
                  setError({
                    ...error,
                    nameError: {
                      state: false,
                    },
                  })
                }
              }}
              onBlur={e => {
                if (!e.currentTarget.value) {
                  setError({
                    ...error,
                    nameError: {
                      state: false,
                    },
                  })
                }
              }}
            />
            <i className="fa fa-butterflymyaccount"></i>
            {error.nameError.state && (
              <Form.Control.Feedback type="invalid">{error.nameError.message}</Form.Control.Feedback>
            )}
          </InputGroup>
        </div>
        <div className="inputWithIcon">
          <InputGroup hasValidation>
            <Form.Control
              type="email"
              value={email}
              placeholder="Email"
              isInvalid={error.emailError.state}
              onChange={e => {
                setEmail(e.currentTarget.value)
                setError({
                  ...error,
                  emailError: {
                    state: false,
                  },
                })
              }}
              onBlur={e => {
                if (!e.currentTarget.value) {
                  setError((prevState: any) => {
                    return {
                      ...prevState,
                      emailError: {
                        message: 'Email is required',
                        state: true,
                      },
                    }
                  })
                } else if (!e.currentTarget.value.match(Regex.email)) {
                  setError((prevState: any) => {
                    return {
                      ...prevState,
                      emailError: {
                        message: 'Email is not valid',
                        state: true,
                      },
                    }
                  })
                } else {
                  validateEmailThunk(
                    {
                      email,
                    },
                    navigate
                  )
                }
              }}
              required
              disabled={userState.loading}
            />
            <i className="fa fa-butterflyemail"></i>
            {error.emailError.state && (
              <Form.Control.Feedback type="invalid">{error.emailError.message}</Form.Control.Feedback>
            )}
          </InputGroup>
        </div>
        <div className="inputWithIcon">
          <InputGroup hasValidation>
            <Form.Control
              value={password}
              type={passwordShown ? 'text' : 'password'}
              isValid={password.length >= 8 && !!password.match(Regex.password)}
              isInvalid={error.passwordError.state}
              placeholder="Password"
              onChange={e => {
                setPassword(e.currentTarget.value)
                setError({
                  ...error,
                  passwordError: { state: false },
                })
                if (confirmPassword && e.target.value !== confirmPassword) {
                  setError((prevState: any) => {
                    return {
                      ...prevState,
                      confirmPasswordError: {
                        message: "Passwords don't match",
                        state: true,
                      },
                    }
                  })
                } else {
                  setError((prevState: any) => {
                    return {
                      ...prevState,
                      confirmPasswordError: {
                        state: false,
                      },
                    }
                  })
                }
              }}
              onBlur={e => {
                if (confirmPassword && e.currentTarget.value !== confirmPassword) {
                  setError((prevState: any) => {
                    return {
                      ...prevState,
                      confirmPasswordError: {
                        message: "Passwords don't match",
                        state: true,
                      },
                    }
                  })
                } else {
                  setError({
                    ...error,
                    confirmPasswordError: { state: false },
                  })
                }

                if (e.currentTarget.value.length < 8) {
                  setError((prevState: any) => {
                    return {
                      ...prevState,
                      passwordError: {
                        message: 'Password must be at least 8 characters long',
                        state: true,
                      },
                    }
                  })
                } else if (!e.currentTarget.value.match(Regex.password)) {
                  setError((prevState: any) => {
                    return {
                      ...prevState,
                      passwordError: {
                        message: 'Password is not valid',
                        state: true,
                      },
                    }
                  })
                } else {
                  setError((prevState: any) => {
                    return {
                      ...prevState,
                      passwordError: { state: false },
                    }
                  })
                }
              }}
              required
              disabled={userState.loading}
            />
            <i className="fa fa-butterflylock"></i>
            <i
              onClick={togglePasswordVisiblity}
              className={passwordShown ? 'fa fa-private icon' : 'fa fa-eye-btm icon'}
            ></i>
            {error.passwordError.state && (
              <Form.Control.Feedback type="invalid">{error.passwordError.message}</Form.Control.Feedback>
            )}
          </InputGroup>
        </div>
        <div className="inputWithIcon">
          <InputGroup hasValidation>
            <Form.Control
              value={confirmPassword}
              type={ConfPasswordShown ? 'text' : 'password'}
              placeholder="Confirm Password"
              isValid={confirmPassword !== '' && !error.confirmPasswordError.state && confirmPassword === password}
              onChange={e => {
                setConfirmPassword(e.currentTarget.value)
                setError({
                  ...error,
                  confirmPasswordError: { state: false },
                })
              }}
              onBlur={e => {
                if (e.currentTarget.value !== password) {
                  setError((prevState: any) => {
                    return {
                      ...prevState,
                      confirmPasswordError: {
                        message: "Passwords don't match",
                        state: true,
                      },
                    }
                  })
                } else {
                  setError({
                    ...error,
                    confirmPasswordError: { state: false },
                  })
                }
              }}
              required
              isInvalid={error.confirmPasswordError.state}
              disabled={userState.loading}
            />
            <i className="fa fa-butterflylock"></i>
            <i
              onClick={toggleConfPasswordVisiblity}
              className={ConfPasswordShown ? 'fa fa-private icon' : 'fa fa-eye-btm icon'}
            ></i>
            {error.confirmPasswordError.state && (
              <Form.Control.Feedback type="invalid">{error.confirmPasswordError.message}</Form.Control.Feedback>
            )}
          </InputGroup>
        </div>
        <span className={password.length >= 8 && password.match(Regex.password) ? 'note d-none' : 'note'}>
          Password must be
          <span
            className={
              password !== '' ? (password.length < 8 ? 'text text-danger' : 'text text-success') : 'text text-default'
            }
          >
            {' '}
            at least 8 characters long{' '}
          </span>
          and contain a
          <span
            className={
              password !== '' ? (password.match(Regex.oneLowercaseChar) ? 'text text-success' : 'text text-danger') : ''
            }
          >
            {' '}
            lowercase letter
          </span>
          , an
          <span
            className={
              password !== '' ? (password.match(Regex.oneUpperCaseChar) ? 'text text-success' : 'text text-danger') : ''
            }
          >
            {' '}
            uppercase letter
          </span>
          ,
          <span
            className={
              password !== '' ? (password.match(Regex.oneNumberChar) ? 'text text-success' : 'text text-danger') : ''
            }
          >
            {' '}
            a number
          </span>
          , and
          <span
            className={
              password !== '' ? (password.match(Regex.oneSpecialChar) ? 'text text-success' : 'text text-danger') : ''
            }
          >
            {' '}
            a special character
          </span>
          .
        </span>
        <span className="note">
          By clicking Register, you agree to butterfly&apos;s{' '}
          <a href={`${environment.frontEndPoint}terms-condition`}>Terms and Conditions </a>
          and <a href={`${environment.frontEndPoint}privacy-policy`}>Privacy Policy</a>
        </span>
      </form>

      <>
        <button
          disabled={
            !email || !password || !name || !confirmPassword
              ? true
              : true &&
                (error.emailError.state ||
                  error.nameError.state ||
                  error.passwordError.state ||
                  error.confirmPasswordError.state)
          }
          onClick={() => {
            handleRegister()
          }}
        >
          Register
        </button>
        <span className="or">Or</span>
        <GsignIn name={'Sign up '} />
      </>
    </>
  )
}

export default SignUp
