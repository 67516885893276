const CommonService = () => {}

const timeSince = date => {
  const currentDate = new Date()
  const addedDate = new Date(date)
  if (
    currentDate.getFullYear() === addedDate.getFullYear() &&
    currentDate.getDate() === addedDate.getDate() &&
    currentDate.getMonth() === addedDate.getMonth()
  ) {
    const seconds = Math.floor((currentDate - addedDate) / 1000)

    let interval = seconds / 3600
    if (interval > 1) {
      const no = Math.floor(interval)
      return no > 1 ? no + 'h' : no + 'h'
    }
    interval = seconds / 60
    if (interval > 1) {
      const no = Math.floor(interval)
      return no > 1 ? no + 'm' : no + 'm'
    }
    interval = seconds
    if (interval > 1) {
      const no = Math.floor(interval)
      return no === 1 ? '1s' : no + 's'
    }
    return '1s'
  } else {
    return (
      addedDate.toLocaleString('en-US', { month: 'short' }) + ' ' + addedDate.getDate() + ', ' + addedDate.getFullYear()
    )
  }
}

const subcommenttimeSince = date => {
  const currentDate = new Date()
  const addedDate = new Date(date)

  if (currentDate.getFullYear() === addedDate.getFullYear() && currentDate.getMonth() === addedDate.getMonth()) {
    const postDate = addedDate.getDate()
    const today = currentDate.getDate()
    const difference = today - postDate
    if (difference > 0) {
      return difference + 'd'
    }
  }

  const seconds = Math.floor((currentDate - addedDate) / 1000)
  let interval = seconds / 31536000
  if (interval > 1) {
    const no = Math.floor(interval)
    return no > 1 ? no + ' years' : no + ' year'
  }
  interval = seconds / 2592000
  if (interval > 1) {
    const no = Math.floor(interval)
    return no > 1 ? no + ' months' : no + ' month'
  }
  interval = seconds / 86400
  if (interval > 1) {
    const no = Math.floor(interval)
    return no > 1 ? no + 'd' : no + 'd'
  }

  interval = seconds / 3600
  if (interval > 1) {
    const no = Math.floor(interval)
    return no === 1 ? no + 'h' : no + 'h'
  }
  interval = seconds / 60
  if (interval > 1) {
    const no = Math.floor(interval)
    return no === 1 ? no + 'm' : no + 'm'
  }
  interval = seconds
  if (interval > 1) {
    const no = Math.floor(interval)
    return no === 1 ? '1s' : no + 's'
  }
  return '1s'
}

const createdBytimeSince = date => {
  const currentDate = new Date()
  const addedDate = new Date(date)

  if (currentDate.getFullYear() === addedDate.getFullYear() && currentDate.getMonth() === addedDate.getMonth()) {
    const postDate = addedDate.getDate()
    const today = currentDate.getDate()
    const difference = today - postDate
    if (difference > 0) {
      return difference > 1 ? difference + ' days ago' : difference + ' day ago'
    }
  }

  const seconds = Math.floor((currentDate - addedDate) / 1000)
  let interval = seconds / 31536000
  if (interval > 1) {
    const no = Math.floor(interval)
    return no > 1 ? no + ' years ago' : no + ' year ago'
  }
  interval = seconds / 2592000
  if (interval > 1) {
    const no = Math.floor(interval)
    return no > 1 ? no + ' months ago' : no + ' month ago'
  }
  interval = seconds / 86400
  if (interval > 1) {
    const no = Math.floor(interval)
    return no > 1 ? no + ' days ago' : no + ' day ago'
  }

  interval = seconds / 3600

  return ' Today'
}

const createdMonthDayYear = date => {
  const createdDate = new Date(date)

  const monthNamesShort = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
  return monthNamesShort[createdDate.getMonth()] + ' ' + createdDate.getDate() + ', ' + createdDate.getFullYear()
}

const createdByMonthYear = date => {
  const createdDate = new Date(date)
  const monthNamesShort = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
  return monthNamesShort[createdDate.getMonth()] + ' ' + createdDate.getFullYear()
}

const createdByMonthAndYear = date => {
  const createdDate = new Date(date)
  const monthNamesShort = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
  return monthNamesShort[createdDate.getMonth()] + ', ' + createdDate.getFullYear()
}

const createdDateAndByMonth = date => {
  const createdDate = new Date(date)
  const monthNamesShort = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
  return monthNamesShort[createdDate.getMonth()] + ' ' + createdDate.getDate() + nth(createdDate.getDate())
}

const createdDateMonthYear = date => {
  const createdDate = new Date(date)
  const monthNamesShort = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
  return (
    monthNamesShort[createdDate.getMonth()] +
    ' ' +
    createdDate.getDate() +
    nth(createdDate.getDate()) +
    ', ' +
    createdDate.getFullYear()
  )
}

const createdDateAndByMonthWithoutNth = date => {
  const createdDate = new Date(date)
  const monthNamesShort = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
  return monthNamesShort[createdDate.getMonth()] + ' ' + createdDate.getDate()
}

const nth = function (d) {
  if (d > 3 && d < 21) return 'th'
  switch (d % 10) {
    case 1:
      return 'st'
    case 2:
      return 'nd'
    case 3:
      return 'rd'
    default:
      return 'th'
  }
}

const createdByYear = date => {
  const createdDate = new Date(date)
  return createdDate.getFullYear()
}

const tConvert = time => {
  // Check correct time format and split into components
  time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time]

  if (time.length > 1) {
    // If time format correct
    time = time.slice(1) // Remove full string match value
    time[5] = +time[0] < 12 ? 'AM' : 'PM' // Set AM/PM
    time[0] = +time[0] % 12 || 12 // Adjust hours
  }
  return time.join('') // return adjusted time or original string
}

const formatAMPM = date => {
  const createdDate = new Date(date)
  let hours = createdDate.getHours()
  let minutes = createdDate.getMinutes()
  const ampm = hours >= 12 ? 'PM' : 'AM'
  hours = hours % 12
  hours = hours || 12 // the hour '0' should be '12'
  minutes = minutes < 10 ? '0' + minutes : minutes
  const strTime = hours + ':' + minutes + ' ' + ampm
  return strTime
}

const dateConvert = date => {
  const createdDate = new Date(date)
  return createdDate.getDate() + '-' + createdDate.getMonth() + '-' + createdDate.getFullYear()
}

const isset = value => {
  return value && value !== null && value !== undefined
}

const htmlContentWithLink = text => {
  const URL_REGEX =
    /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/gm

  const words = text.split(' ')

  const updateText = words.map(word => {
    return word.match(URL_REGEX) ? (
      <>
        <a target="_blank" href={word} rel="noreferrer">
          {word}
        </a>{' '}
      </>
    ) : (
      word + ' '
    )
  })

  return updateText
}

const parseURLParams = url => {
  const queryStart = url.indexOf('?') + 1
  const queryEnd = url.indexOf('#') + 1 || url.length + 1
  const query = url.slice(queryStart, queryEnd - 1)
  const pairs = query.replace(/\+/g, ' ').split('&')
  const parms = {}
  let i
  let n
  let v
  let nv

  if (query === url || query === '') return

  for (i = 0; i < pairs.length; i++) {
    nv = pairs[i].split('=', 2)
    n = decodeURIComponent(nv[0])
    v = decodeURIComponent(nv[1])

    if (!(n in parms)) {
      parms[n] = []
    }

    parms[n].push(nv.length === 2 ? v : null)
  }
  return parms
}

const InviteparseURLParams = url => {
  const queryStart = url.indexOf('?') + 1
  const queryEnd = url.indexOf('#') + 1 || url.length + 1
  const query = url.slice(queryStart, queryEnd - 1)
  const pairs = query.split('&')
  const parms = {}
  let i
  let n
  let v
  let nv

  if (query === url || query === '') return

  for (i = 0; i < pairs.length; i++) {
    nv = pairs[i].split('=', 2)
    n = decodeURIComponent(nv[0])
    v = decodeURIComponent(nv[1])

    if (!(n in parms)) {
      parms[n] = []
    }

    parms[n].push(nv.length === 2 ? v : null)
  }
  return parms
}

const numberFormateIntl = num => {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumSignificantDigits: 3,
  }).format(num)
}

const nFormatter = (num, digits) => {
  const lookup = [
    { value: 1, symbol: '' },
    { value: 1e3, symbol: 'k' },
    { value: 1e6, symbol: 'M' },
    { value: 1e9, symbol: 'G' },
    { value: 1e12, symbol: 'T' },
    { value: 1e15, symbol: 'P' },
    { value: 1e18, symbol: 'E' },
  ]
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/
  const item = lookup
    .slice()
    .reverse()
    .find(function (item) {
      return num >= item.value
    })
  return item ? (num / item.value).toFixed(digits).replace(rx, '$1') + item.symbol : '0'
}
const getPercent = (x, y) => {
  const percentVal = (x / y) * 100
  return percentVal.toFixed(2)
}
const logoNoText =
  'https://butterflyv2-production-assets.nyc3.digitaloceanspaces.com/image/8314fa7a-c977-43f8-811e-604b4f5a4f16-butterfly-notext.svg'
const butterflyBlack =
  'https://butterflyv2-production-assets.nyc3.digitaloceanspaces.com/image/ea34df64-641f-4b2b-ae6f-837cb29b247f-butterfly-logo.svg'
const butterflyGreen =
  'https://butterflyv2-production-assets.nyc3.digitaloceanspaces.com/image/0be4f6c7-33c1-4f6e-a3ec-7e29adab04be-butterfly-green.svg'
const butterflyPurple =
  'https://butterflyv2-production-assets.nyc3.digitaloceanspaces.com/image/69136eb9-d427-403a-9edf-d0314ce8c762-butterfly-purple.svg'
const butterflyWhite =
  'https://butterflyv2-production-assets.nyc3.digitaloceanspaces.com/image/43546a51-ae0a-4d80-ac66-858ad3736895-butterfly-white.svg'
const homephotoalbum =
  'https://butterflyv2-production-assets.nyc3.digitaloceanspaces.com/image/798c5d06-a50f-4641-af01-3c3fb2308d55-more-than-just-a-photo-album.png'
const GDrive =
  'https://butterflyv2-production-assets.nyc3.digitaloceanspaces.com/image/bc477c20-480f-4a77-ad02-8aecddf6dc4e-drive.png'
const dropbox =
  'https://butterflyv2-production-assets.nyc3.digitaloceanspaces.com/image/68fb41cd-1632-4076-bce7-668d45115c1e-dropbox.png'
const folder =
  'https://butterflyv2-production-assets.nyc3.digitaloceanspaces.com/image/4468718d-9ff9-4eb5-a2cd-84901f0a2a93-folder.png'
const phone =
  'https://butterflyv2-production-assets.nyc3.digitaloceanspaces.com/image/447ac047-0e62-4b4b-abdd-8b5ef5dbb319-phone.png'
export default CommonService
export {
  butterflyBlack,
  butterflyGreen,
  butterflyPurple,
  butterflyWhite,
  createdByMonthAndYear,
  createdByMonthYear,
  createdBytimeSince,
  createdByYear,
  createdDateAndByMonth,
  createdDateAndByMonthWithoutNth,
  createdDateMonthYear,
  createdMonthDayYear,
  dateConvert,
  dropbox,
  folder,
  formatAMPM,
  GDrive,
  getPercent,
  homephotoalbum,
  htmlContentWithLink,
  InviteparseURLParams,
  isset,
  logoNoText,
  nFormatter,
  nth,
  numberFormateIntl,
  parseURLParams,
  phone,
  subcommenttimeSince,
  tConvert,
  timeSince,
}
